import _ from 'lodash'
import Vue from 'vue'
import utils from '../../utils'

import {
  SAVE_RESULT,
  SAVE_RAW_RESULT,
  SET_USER_BIRTHDAY_RESULT,
  SET_ANONYMOUS_GENDER_RESULT,
  SAVE_RESULTS,
  REMOVE_RESULT,
  REMOVE_RAW_RESULT,
  UPDATE_RESULT,
  SAVE_RESULTS_CALCULATED,
  RESET_RESULTS,
  RESET_RAW_RESULTS,
  RESET_RESULTS_CALCULATED,
  RESET_USER_RESULTS,
  RESET_USER_RAW_RESULTS,
  RESET_USER_RESULTS_CALCULATED,

  SAVE_TEMP_RESULTS,
  UPDATE_TEMP_RESULTS,
  REMOVE_TEMP_RESULTS,

  SAVE_TEMP_RESULTS_ETAG,
  REMOVE_TEMP_RESULTS_ETAG,

  SET_INFO,
  RESET_INFO,
  ADD_TO_INFO,
  REMOVE_FROM_INFO,
  SET_ROOT,
  RESET_ROOT,
  ADD_TO_ROOT,
  REMOVE_FROM_ROOT,
  ADD_NEW_USER_RESULTS_OBJ,
  OBSERVE_EXISTING_USER_RESULTS_OBJ,
  ADD_NEW_USER_RAW_RESULTS_OBJ,
  OBSERVE_EXISTING_USER_RAW_RESULTS_OBJ,
  ADD_NEW_USER_RESULTS_CALCULATED_OBJ,
  OBSERVE_EXISTING_USER_RESULTS_CALCULATED_OBJ,
} from '../mutation-types'

import * as A from '../action-types'


export const namespaced = true

export const state = {
  results: { null: {}},
  raw_results: { null: {}},
  results_calculated: { null: {}},
  u_info: {},
  req_root: {},
  temp_results: {},
  temp_results_etag: '',

  // MEMO: if project name defined as latter object's prop, it will apply
  //       those merge policies while merging previously completed test results
  //       with newer ones, if tests with same route name and results field
  //       occur in single project test sequence. For the different types
  //       of test results we can define and use different merge policies.
  //   For example: combo test results, or eq-drivers test results
  merge_policy_subordinates: { // TODO: decouple results merge policies from raw_results merge policies...
    'test': { // MEMO: just for test
      array: 'concatenation',
      number: 'addition',
      object: 'concatenation',
    },
    'career-a-c': {
      object: 'concatenation',
    },
    ///
    'career-c-m': {
      object: 'concatenation',
    },
    'career-s-a': {
      object: 'concatenation',
    },
    'career-s-c': {
      object: 'concatenation',
    },
    'career-s-m': {
      object: 'concatenation',
    },
    ///
    'quiz-system-communication': { object: 'concatenation', },
    'quiz-eq-critical': { object: 'concatenation', },
    'quiz-eq-communication': { object: 'concatenation', },
    'quiz-eq-system': { object: 'concatenation', },
    'quiz-system-critical': { object: 'concatenation', },
    ///
    'ranh-critical-in-digital': {
      array: 'concatenation',
      number: 'addition',
    },
    'ranh-info-management': {
      array: 'concatenation',
      number: 'addition',
    },
    'ranh-digital-communication': {
      array: 'concatenation',
      number: 'addition',
    },
    'ranh-self-development': {
      array: 'concatenation',
      number: 'addition',
    },
    'ranh-creative': {
      array: 'concatenation',
      number: 'addition',
    },
  },
  results_merge_policies: {
    'object': {
      // TODO
      concatenation: (oldObj, newObj) => ({ ...oldObj, ...newObj }),
      // addition: (oldObj, newObj) => ({ })
    },
    'array': {
      concatenation: (oldArr, newArr) => oldArr.concat(newArr),
      addition: (oldArr, newArr) => {
        if (oldArr.length >= newArr.length) {
          return oldArr.map((oldEl, i) => {
            const newEl = newArr[i]
            if (utils.classOf(oldEl) === 'Number' &&
                utils.classOf(newEl) === 'Number') {
              return oldEl + newEl ? newEl : 0
            } else if ( utils.classOf(oldEl) === 'Array' &&
                        utils.classOf(newEl) === 'Array' ) {
              return oldEl.concat(newEl ? newEl : [])
            } else {
              console.log('Unknown merge element type! newEl discarded!')
              return oldEl
            }
          })
        } else {
          return newArr.map((newEl, i) => {
            const oldEl = oldArr[i]
            if (utils.classOf(oldEl) === 'Number' &&
                utils.classOf(newEl) === 'Number') {
              return newEl + oldEl ? oldEl : 0
            } else if ( utils.classOf(oldEl) === 'Array' &&
                        utils.classOf(newEl) === 'Array' ) {
              return newEl.concat(oldEl ? oldEl : [])
            } else {
              console.log('Unknown merge element type! newEl discarded!')
              return oldEl
            }
          })
        }
      },
      recursiveAddition: (oldArr, newArr) => {
        // TODO
        if (oldArr.length >= newArr.length) {
          return oldArr.map((oldEl, i) => {
            const newEl = newArr[i]
            if (utils.classOf(oldEl) === 'Number' &&
                utils.classOf(newEl) === 'Number') {
              return oldEl + newEl ? newEl : 0
            } else if ( utils.classOf(oldEl) === 'Array' &&
                        utils.classOf(newEl) === 'Array' ) {
              return _.zipWith(oldEl, newEl, (a, b) => a + b)
            } else {
              console.log('Unknown merge element type! newEl discarded!')
              return oldEl
            }
          })
        } else {
          return newArr.map((newEl, i) => {
            const oldEl = oldArr[i]
            if (utils.classOf(oldEl) === 'Number' &&
                utils.classOf(newEl) === 'Number') {
              return newEl + oldEl ? oldEl : 0
            } else if ( utils.classOf(oldEl) === 'Array' &&
                        utils.classOf(newEl) === 'Array' ) {
              return _.zipWith(newEl, oldEl, (a, b) => a + b)
            } else {
              console.log('Unknown merge element type! newEl discarded!')
              return oldEl
            }
          })
        }
      },
    },
    'number': {
      addition: (oldVal, newVal) => oldVal + newVal,
    },
    'string': {
      // TODO
    },
  }
}

export const getters = {
  user_results (state, getters, rootState) { // uses users module state
    return state.results[rootState.users.user_id]
  },
  user_raw_results (state, getters, rootState) {
    return state.raw_results[rootState.users.user_id]
  },
  user_results_calculated (state, getters, rootState) { // uses users module state
    return state.results_calculated[rootState.users.user_id]
  },
  user_info: state => state.u_info,
  user_results_extended: (state, getters, rootState) => { // uses users module state & tests module state (rootState.tests._append)
    if (rootState.tests._append && rootState.tests._append.skillfolio) {
      console.log('↺↺ rootState.tests._append.skillfolio found ↺↺')
      let extended_object = {
        skillfolio: {
          // ...getters.user_results,  // observer not working on getter, only on state
          ...state.results[rootState.users.user_id],
          ...rootState.tests._append.skillfolio,
          mode_sub: {
            age: rootState.users.user_age_group
          }
        }
      }
      console.log('↺↺ getters.user_results_extended:', extended_object)
      return extended_object
    } else if (rootState.tests._append) {
      console.warn('↺↺ Unexpected ._append value!!! ↺↺')
      return
      // return {
      //   ...rootState.tests._append,
      //   ...getters.user_results
      // }
    }
  },

  user_results_list (state, getters, rootState) { // uses user module state
    let results_list = Object.keys(state.results[rootState.users.user_id])
    console.log('user_results_list:', results_list)
    return results_list
  },
  user_results_extended_list (state, getters) {
    let results_list = Object.keys(getters.user_results_extended.skillfolio)
    console.log('user_results_extended_list:', results_list)
    return results_list
  },
  user_info_list (state, getters) {
    let info_list = Object.keys(getters.user_info)
    return info_list
  },
  applied_merge_policies (state, getters, rootState) {
    return state.merge_policy_subordinates[rootState.project_name]
  },
}

// hasn't got access to rootState, rootGetter or anything, should be the pure fn
// if such behaviour is needed — better to move it out to the corresponding action
export const mutations = {
  [SAVE_RESULT] (state, [ user_id, test, result ]) { // -- uses user module state
    console.log('µ Result is committing to vuex state.results.results[' + user_id + '][' + test + '] = ' + result)
    // state.results[user_id][test] = result  // this in not working, because of reactivity system can't track change
    // this.$set(vm.items, indexOfItem, newValue)  // we can use vm.$set for explicit change, but we havent access to root instance here
    state.results[user_id] = { ...state.results[user_id], [test]: result } // so this is workaround
  },
  [SAVE_RAW_RESULT] (state, [ user_id, test, raw_result ]) { // -- uses user module state
    state.raw_results[user_id] = { ...state.raw_results[user_id], [test]: { raw: raw_result }} // so this is workaround
  },
  [SAVE_RESULTS] (state, { user_id, test_results }) { // -- uses user module state
    console.log('µ Results is committing to vuex state.results.results[' + user_id + '] = ' + test_results)
    state.results[user_id] = { ...state.results[user_id], ...test_results }
  },
  [REMOVE_RESULT] (state, [ user_id, test_name ]) { // -- uses user module state
    console.log('µ Test result is deleting from vuex state.results[' + user_id + '][' + test_name + '] ==', state.results[user_id][test_name])
    // delete state.results[user_id][test_name]
    // fixing vue reactivity system restrictions:
    Vue.delete(state.results[user_id], test_name)
  },
  [REMOVE_RAW_RESULT] (state, [ user_id, test_name ]) { // -- uses user module state
    Vue.delete(state.raw_results[user_id], test_name)
  },
  [UPDATE_RESULT] (state, [ user_id, test, result ]) { // -- uses user module
    console.log('µ Result update is committing to vuex state.results.results[' + user_id + '][' + test + '] += ' + result)
    state.results[user_id][test] = { ...state.results[user_id][test], ...result } // TODO: check that this is working...
    // state.results[user_id] = {...state.results[user_id], [test]: result }  // so this is workaround
  },
  [SET_USER_BIRTHDAY_RESULT] (state, [ user_id, birthday_date_iso ]) { // -- uses user module state
    state.results[user_id] = { ...state.results[user_id], birthday: birthday_date_iso }
  },
  [SET_ANONYMOUS_GENDER_RESULT] (state, [ user_id, gender ]) { // -- uses user module state
    state.results[user_id].anonymous.gender = gender
  },
  [SAVE_RESULTS_CALCULATED] (state, [ user_id, results_calculated ]) { // -- uses user module state
    console.log('µ Result is committing to vuex state.results.results_calculated[' + user_id + '] = ' + results_calculated)
    // state.results_calculated[user_id] = results_calculated  // not working
    // state.results_calculated = {...state.results_calculated, [user_id]: results_calculated}  // working
    Vue.set(state.results_calculated, user_id, results_calculated) // working
  },
  [RESET_RESULTS] (state) {
    const user_keys = Object.keys(state.results) // makes String from all keys
    for (let i = 0; i < user_keys.length; i++) {
      const user_key = user_keys[i]
      Vue.$log.debug('µ:', i, 'clearing user results for', user_key)
      if (user_key === 'null') {
        Vue.$log.debug('µ: Emptify results for null user')
        Vue.set(state.results, null, {})
      } else {
        Vue.$log.debug('µ: Remove results for', user_key, 'user')
        Vue.delete(state.results, user_key)
      }
    }
    Vue.$log.debug('µ: === end')
  },
  [RESET_RAW_RESULTS] (state) {
    const user_keys = Object.keys(state.raw_results) // makes String from all keys
    for (let i = 0; i < user_keys.length; i++) {
      const user_key = user_keys[i]
      Vue.$log.debug('µ:', i, 'clearing user raw results for', user_key)
      if (user_key === 'null') {
        Vue.$log.debug('µ: Emptify raw results for null user')
        Vue.set(state.raw_results, null, {})
      } else {
        Vue.$log.debug('µ: Remove raw results for', user_key, 'user')
        Vue.delete(state.raw_results, user_key)
      }
    }
    Vue.$log.debug('µ: === end')
  },
  [RESET_RESULTS_CALCULATED] (state) {
    const user_keys = Object.keys(state.results_calculated) // makes String from all keys
    for (let i = 0; i < user_keys.length; i++) {
      const user_key = user_keys[i]
      Vue.$log.debug('µ:', i, 'clearing user results calculated for', user_key)
      if (user_key === 'null') {
        Vue.$log.debug('µ: Emptify results calculated for null user')
        Vue.set(state.results_calculated, null, {})
      } else {
        Vue.$log.debug('µ: Remove results calculated for', user_key, 'user')
        Vue.delete(state.results_calculated, user_key)
      }
    }
    Vue.$log.debug('µ: === end')
  },
  [RESET_USER_RESULTS] (state, user_id) { // -- uses user module state
    console.log('µ Committing reset of results in vuex store: state.results[' + user_id + '] = {}')
    Vue.set(state.results, user_id, {})
    // state.results[user_id] = {}  // so this is workaround
  },
  [RESET_USER_RAW_RESULTS] (state, user_id) { // -- uses user module state
    Vue.set(state.results, user_id, {})
  },
  [RESET_USER_RESULTS_CALCULATED] (state, user_id) { // uses user module state
    console.log('µ Committing reset of results_calculated in vuex store: state.results_calculated[' + user_id + '] = {}')
    Vue.set(state.results_calculated, user_id, {})
    // state.results_calculated[user_id] = {}
  },

  [SAVE_TEMP_RESULTS] (state, temp_results) {
    state.temp_results = temp_results // todo - or use hash / etag
  },
  [UPDATE_TEMP_RESULTS] (state, result) {
    state.temp_results = { ...state.temp_results, result } // todo - or use hash / etag
  },
  [REMOVE_TEMP_RESULTS] (state) {
    state.temp_results = {} // todo - or use hash / etag
  },

  [SAVE_TEMP_RESULTS_ETAG] (state, etag) {
    state.temp_results_etag = etag
  },
  [REMOVE_TEMP_RESULTS_ETAG] (state) {
    state.temp_results_etag = ''
  },

  [SET_INFO] (state, info) {
    state.u_info = info
  },
  [RESET_INFO] (state) {
    state.u_info = {}
  },
  [ADD_TO_INFO] (state, info) {
    state.u_info = {
      ...state.u_info,
      ...info
    }
  },
  [REMOVE_FROM_INFO] (state, field) {
    // delete state.u_info[field]
    Vue.delete(state.u_info, field)
  },
  [SET_ROOT] (state, data) {
    state.req_root = data
  },
  [RESET_ROOT] (state) {
    state.req_root = {}
  },
  [ADD_TO_ROOT] (state, data) {
    state.req_root = {
      ...state.req_root,
      ...data
    }
  },
  [REMOVE_FROM_ROOT] (state, field) {
    // delete state.req_root[field]
    Vue.delete(state.req_root, field)
  },
  [ADD_NEW_USER_RESULTS_OBJ] (state, user_id) {
    console.log('µ MUTATION: results empty object for user_id == ' + user_id + ' is creating in vuex state.results[' + user_id + ']')
    if (user_id) {
      // Next two lines are fixing vue reactivity system restrictions (they are working ALMOST(?) equally):
      // Vue.set(state.results, user_id, {})
      state.results = { ...state.results, [user_id]: {}}
    } else console.warn('µ NO user_id RECIEVED BY ADD_NEW_USER_RESULTS_OBJ ! :(')
  },
  [OBSERVE_EXISTING_USER_RESULTS_OBJ] (state, user_id) {
    if (user_id || user_id === null) {
      state.results = { ...state.results, [user_id]: state.results[user_id] }
    } else console.warn('µ NO user_id RECIEVED BY OBSERVE_EXISTING_USER_RESULTS_OBJ ! :(')
  },
  [ADD_NEW_USER_RAW_RESULTS_OBJ] (state, user_id) {
    console.log('µ MUTATION: raw results empty object for user_id == ' + user_id + ' is creating in vuex state.results[' + user_id + ']')
    if (user_id) {
      // Next two lines are fixing vue reactivity system restrictions (they are working ALMOST(?) equally):
      // state.results = { ...state.results, [user_id]: {}}
      Vue.set(state.raw_results, user_id, {})
    } else console.warn('µ NO user_id RECIEVED BY ADD_NEW_USER_RAW_RESULTS_OBJ ! :(')
  },
  [OBSERVE_EXISTING_USER_RAW_RESULTS_OBJ] (state, user_id) {
    if (user_id || user_id === null) {
      state.raw_results = { ...state.raw_results, [user_id]: state.raw_results[user_id] }
    } else console.warn('µ NO user_id RECIEVED BY OBSERVE_EXISTING_USER_RAW_RESULTS_OBJ ! :(')
  },
  [ADD_NEW_USER_RESULTS_CALCULATED_OBJ] (state, user_id) {
    console.log('µ MUTATION: results calculated empty object for user_id == ' + user_id + ' is creating in vuex state.results_calculated[' + user_id + ']')
    if (user_id) {
      // Next two lines are fixing vue reactivity system restrictions (they are working ALMOST(?) equally):
      // Vue.set(state.results_calculated, user_id, {})
      state.results_calculated = { ...state.results_calculated, [user_id]: {}}
    } else console.warn('µ NO user_id RECIEVED BY ADD_NEW_USER_RESULTS_CALCULATED_OBJ ! :(')
  },
  [OBSERVE_EXISTING_USER_RESULTS_CALCULATED_OBJ] (state, user_id) {
    if (user_id || user_id === null) {
      state.results_calculated = { ...state.results_calculated, [user_id]: state.results_calculated[user_id] }
    } else console.warn('µ NO user_id RECIEVED BY OBSERVE_EXISTING_USER_RESULTS_CALCULATED_OBJ ! :(')
  },
}

export const actions = {
  [A.SAVE_RESULT] ({ commit, state, getters, rootState, dispatch }, result_data) {
    const user_id = rootState.users.user_id
    // console.log('Save result_data:', result_data)
    const [ test, result, raw_result, options = {} ] = result_data
    // console.log('test:', test)
    // console.log('result:', result)
    // console.log('raw_result:', raw_result)
    // console.log('options:', options)

    // HACK: custom test saving behaviour
    switch (test) {
    case 'birthday':
      console.log('birthday options:', options)
      const age = options.age || ((new Date() - Date.parse(result)) / 1000 / 60 / 60 / 24 / 365)
      if (age) {
        const age_group = options.age_group || (age < 18 ? 'kid' : 'adult')
        dispatch('users/set_user_age', age, { root: true })
        dispatch('users/set_user_age_group', age_group, { root: true })
      } else {
        console.warn('Birthday result saving, but AGE & AGE_GROUP not found!')
      }
      break
    case 'anonymous':
      if (result.gender) dispatch('add_to_info', { gender: result.gender })
      break
    case 'info':
      if (result.email) dispatch('add_to_info', { email: result.email })
      if (result.gender) dispatch('add_to_info', { gender: result.gender })
      break
    default:
      break
    }

    // MEMO: merge results policies applying, if has previous result of same test
    let merge_policy = null
    let merged_result = null
    const prev_test_result = state.results[user_id][test]
    if (getters.applied_merge_policies && prev_test_result) {
      const result_type = utils.classOf(result).toLowerCase()
      const merge_policy_name = getters.applied_merge_policies[result_type]
      if (merge_policy_name) merge_policy = state.results_merge_policies[result_type][merge_policy_name]
      if (merge_policy) {
        console.log('Result is merging with previous one')
        console.log('Prev result was:', prev_test_result)
        console.log('New result is:', result)
        console.log('merge_policy is:', merge_policy)
        console.log(`Merging ${test} results with merge_policy: ${merge_policy}`)
        merged_result = merge_policy(prev_test_result, result)
        console.log('Merged result is:', merged_result)
      }
    }
    commit('SAVE_RESULT', [ rootState.users.user_id, test, merge_policy ? merged_result : result ])

    if (raw_result) {
      let raw_merge_policy = null
      let merged_raw_result = null
      const prev_test_raw_result = state.raw_results[user_id][test] ? state.raw_results[user_id][test].raw : null
      if (getters.applied_merge_policies && prev_test_raw_result) {
        const raw_result_type = utils.classOf(raw_result).toLowerCase()
        const raw_merge_policy_name = getters.applied_merge_policies[raw_result_type]
        if (raw_merge_policy_name) raw_merge_policy = state.results_merge_policies[raw_result_type][raw_merge_policy_name]
        if (raw_merge_policy_name) {
          console.log('Raw Result is merging with previous one')
          console.log('Prev raw_result was:', prev_test_raw_result)
          console.log('New raw_result is:', result)
          console.log('raw_merge_policy is:', raw_merge_policy)
          console.log(`Merging ${test} raw)results with merge_policy: ${merge_policy}`)
          merged_raw_result = raw_merge_policy(prev_test_raw_result, raw_result)
          console.log('Merged raw_result is:', merged_raw_result)
        }
      }

      commit('SAVE_RAW_RESULT', [ rootState.users.user_id, test, raw_merge_policy ? merged_raw_result : raw_result ])
    }
  },
  [A.SAVE_RESULTS] ({ commit, rootState }, test_results) {
    commit('SAVE_RESULTS', { user_id: rootState.users.user_id, ...test_results })
  },
  [A.REMOVE_RESULT] ({ commit, rootState, rootGetters, dispatch }, test_name) { // USE THIS INSTEAD OF MUTATION
    // HACK: custom test result removing behaviour
    switch (test_name) {
    case 'birthday':
      dispatch('users/reset_user_age', null, { root: true })
      // MEMO: clearing user_age_group if birthday result is
      // cleared, and no project_age_group defined
      if (!rootGetters['tests/project_age_group']) {
        dispatch('users/reset_user_age_group', null, { root: true })
      }
      break
    case 'anonymous':
      dispatch('remove_from_info', 'gender')
      break
    case 'info':
      dispatch('remove_from_info', 'email')
      dispatch('remove_from_info', 'gender')
      break
    default:
      break
    }
    commit('REMOVE_RESULT', [ rootState.users.user_id, test_name ])
    commit('REMOVE_RAW_RESULT', [ rootState.users.user_id, test_name ])
  },
  [A.UPDATE_RESULT] ({ commit, rootState }, results) {
    const [ test, result, raw_result ] = results
    commit('UPDATE_RESULT', [ rootState.users.user_id, test, result ])
    // if (raw_result) commit('UPDATE_RAW_RESULT', [ rootState.users.user_id, test, raw_result ])
  },
  [A.SET_USER_BIRTHDAY_RESULT] ({ commit, rootState }, birth_date_iso) {
    return new Promise((resolve) => { // making action async
      console.log('▲ ACTION (async): set_user_birthday_result promise callback called')
      commit('SET_USER_BIRTHDAY_RESULT', [ rootState.users.user_id, birth_date_iso ])
      resolve(birth_date_iso)
    })
  },
  [A.SET_ANONYMOUS_GENDER_RESULT] ({ commit, rootState }, gender) {
    return new Promise((resolve) => { // making action async
      console.log('▲ ACTION (async): set_anonymous_gender_result promise callback called')
      commit('SET_ANONYMOUS_GENDER_RESULT', [ rootState.users.user_id, gender ])
      resolve(gender)
    })
  },
  [A.SAVE_RESULTS_CALCULATED] ({ commit, rootState }, results_calculated) { // USE THIS INSTEAD OF MUTATION
    return new Promise((resolve) => {
      console.log('▲ ACTION (async): save_results_calculated promise callback called')
      commit('SAVE_RESULTS_CALCULATED', [ rootState.users.user_id, results_calculated ])
      resolve(results_calculated)
    })
  },

  [A.RESET_RESULTS] ({ commit }) {
    commit('RESET_RESULTS')
    commit('RESET_RAW_RESULTS')
    commit('RESET_RESULTS_CALCULATED')
    commit('RESET_INFO')
    commit('RESET_ROOT')

    return true
  },

  [A.RESET_USER_RESULTS] ({ commit, rootState }) {
    const user_id = rootState.users.user_id

    commit('RESET_USER_RESULTS', user_id)
    commit('RESET_USER_RAW_RESULTS', user_id)
    commit('RESET_USER_RESULTS_CALCULATED', user_id)
    commit('RESET_INFO')
    commit('RESET_ROOT')
  },

  // eslint-disable-next-line no-unused-vars
  [A.COUNT_RESULTS_CALCULATED] ({ dispatch, getters, rootGetters }, uid) { // uses users module getters

    // if (!uid) {  // good'n'old es5 XD
    //   console.log('No UID, creating temp UID!')
    //   uid = Date.now()  + '_' + utils.makeId(4)
    //   console.log('Created UID is:', uid)
    // }

    // cached values //
    // const mode = rootState.project_name
    const user_age_group = rootGetters['users/get_user_age_group']
    const is_kid = user_age_group == 'kid'
    const user_results = getters.user_results

    const eqtest = user_results.eqtest // can cache because of Object reference type
    const eqtest_groups = eqtest ? eqtest.groups : null // can cache because of Object reference type
    // const eqtest_subgroups = eqtest.subgroups  // can cache because of Object reference type

    // const skills = user_results.naviki  // can cache because of Object reference type
    // const meta = user_results.meta  // can cache because of Object reference type

    const results_calculated = {
      abilities: {
        primary: {
          memory: (user_results.gear != null) ? user_results.gear * 20 : null,
          attention: (user_results.landolt != null) ?
            (utils.classOf(user_results.landolt) == 'Object' ?
              Math.round((user_results.landolt[1] + user_results.landolt[2] + user_results.landolt[3]) / 24 * 100)
              :
              Math.round(user_results.landolt / 24 * 100)
            )
            :
            null,
          emotions: (eqtest && user_results.faces != null && utils.classOf(user_results.faces) == 'Number') ?
            Math.min(Math.round(eqtest.total / 38 * 50 + user_results.faces / 30 * 50), 100)
            :
            eqtest ?
              Math.min(Math.round(eqtest.total), 100)
              :
              user_results.faces != null && utils.classOf(user_results.faces) == 'Number' ?
                Math.min(Math.round(user_results.faces / 30 * 100), 100)
                :
                null,
        },
        // secondary: {
        //   {
        //     value: (eqtest_groups && eqtest_groups.conciousness) ? Math.round(eqtest_groups.conciousness / 16 * 100) : null,
        //     subgroups: ['body', 'emotions', 'behaviour', 'thoughts']
        //   },
        //   {
        //     value: (eqtest_groups && eqtest_groups.motivation) ? Math.round(eqtest_groups.motivation / 16 * 100) : null,
        //     subgroups: ['openmindness', 'goal', 'failure', 'actualization']
        //   },
        //   {
        //     value: (eqtest_groups && eqtest_groups.selfesteem) ? Math.round(eqtest_groups.selfesteem / 16 * 100) : null,
        //     subgroups: ['acceptance', 'assertiveness', 'positivity', 'determination']
        //   },
        //   {
        //     value: (eqtest_groups && eqtest_groups.adaptability) ? Math.round(eqtest_groups.adaptability / 16 * 100) : null,
        //     subgroups: ['empathy', 'stressresistance', 'communication', 'decisionmaking']
        //   },
        // }
      },
    }

    if (eqtest_groups && !utils.isEmptyObj(eqtest_groups)) {
      results_calculated.abilities.secondary = {}
      // cache
      let results_calculated_abilities_secondary = results_calculated.abilities.secondary
      if (eqtest_groups.adaptability != null) results_calculated_abilities_secondary.adaptability = Math.min(Math.round(eqtest_groups.adaptability / 24 * 100), 100)
      // MEMO: conciousness is a legacy mistype! But should have backwards compatibility because of lots of db entities with it
      if (eqtest_groups.conciousness != null) results_calculated_abilities_secondary.consciousness = Math.min(Math.round(eqtest_groups.conciousness / 24 * 100), 100)
      if (eqtest_groups.consciousness != null) results_calculated_abilities_secondary.consciousness = Math.min(Math.round(eqtest_groups.consciousness / 24 * 100), 100)
      if (eqtest_groups.motivation != null) results_calculated_abilities_secondary.motivation = Math.min(Math.round(eqtest_groups.motivation / 24 * 100), 100)
      if (eqtest_groups.selfesteem != null) results_calculated_abilities_secondary.selfesteem = Math.min(Math.round(eqtest_groups.selfesteem / 24 * 100), 100)
    }

    if (user_results.bennett != null ||
        user_results.critical != null ||
        user_results.digital != null ||
        user_results.design != null ||
        user_results.creative != null) {
      results_calculated.thinking = {}
      // cache
      let results_calculated_thinking = results_calculated.thinking
      if (user_results.bennett != null) results_calculated_thinking.system = (user_results.bennett * (is_kid ? 7 : 10))
      if (user_results.critical != null) results_calculated_thinking.critical = user_results.critical * 20
      if (user_results.digital != null) results_calculated_thinking.digital = Math.min(Math.round(user_results.digital / 7 * 100), 100)
      if (user_results.design != null) results_calculated_thinking.design = Math.min(Math.round(user_results.design / 7 * 100), 100)
      if (user_results.creative != null) results_calculated_thinking.creative = Math.min(Math.round(user_results.creative / 284 * 100 * 5), 100)
    }

    // TODO: remove this if not needed

    // if (skills && !utils.isEmptyObj(skills)) {
    //   results_calculated.role = {}
    //   // cache
    //   let results_calculated_role = results_calculated.role
    //   if (skills.decisions != null) results_calculated_role.analyst = skills.decisions
    //   if (skills.eq != null) results_calculated_role.leader = skills.eq
    //   if (skills.outofthebox != null) results_calculated_role.creator = skills.outofthebox
    //   if (skills.digital != null) results_calculated_role.digital = skills.digital
    //   if (skills.content != null) results_calculated_role.producer = skills.content
    //   if (skills.trans != null) results_calculated_role.visioner = skills.trans
    //   if (skills.infomanagement != null) results_calculated_role.coordinator = skills.infomanagement
    // }

    // if (skills && !utils.isEmptyObj(skills)) {
    //   results_calculated.skills = {}
    //   // cache
    //   let results_calculated_skills = results_calculated.skills
    //   if (skills.decisions != null) results_calculated_skills.critical_thinking = skills.decisions
    //   if (skills.eq != null) results_calculated_skills.emotional_intelligence = skills.eq
    //   if (skills.outofthebox != null) results_calculated_skills.creative_thinking = skills.outofthebox
    //   if (skills.digital != null) results_calculated_skills.digital = skills.digital
    //   if (skills.content != null) results_calculated_skills.communication = skills.content
    //   if (skills.trans != null) results_calculated_skills.system_thinking = skills.trans
    //   if (skills.infomanagement != null) results_calculated.skills.cooperation = skills.infomanagement
    // }

    // if (meta && !utils.isEmptyObj(meta)) {
    //   results_calculated.meta_skills = {}
    //   // cache
    //   let results_calculated_meta_skills = results_calculated.meta_skills
    //   if (meta.critical != null) results_calculated_meta_skills.critical_thinking = meta.critical
    //   if (meta.eq != null) results_calculated_meta_skills.emotional_intelligence = meta.eq
    //   if (meta.creative != null) results_calculated_meta_skills.creative_thinking = meta.creative
    //   if (meta.digital != null) results_calculated_meta_skills.digital = meta.digital
    //   if (meta.communication != null) results_calculated_meta_skills.communication = meta.communication
    //   if (meta.system != null) results_calculated_meta_skills.system_thinking = meta.system
    //   if (meta.cooperation != null) results_calculated_meta_skills.cooperation = meta.cooperation
    // }

    console.log('results_calculated:', results_calculated)
    return dispatch(A.SAVE_RESULTS_CALCULATED, results_calculated)
    // TODO: remove next line, if prefious works same way, as expected
    // return Promise.resolve(results_calculated)
  },
  [A.REMOVE_LAST_RESULT] ({ dispatch, rootGetters }) {
    const project_tests = rootGetters['tests/project_tests']
    console.log('rootGetters[\'tests/project_tests\']:', project_tests)
    console.log('rootGetters[\'tests/project_tests_completed\']:', rootGetters['tests/project_tests_completed'])
    if (rootGetters['tests/project_tests_completed']) { // when all tests are done
      console.log('all project test completed! removing last test result')
      const last_completed_test_name = trim_test_params(project_tests[project_tests.length - 1])
      dispatch(A.REMOVE_RESULT, last_completed_test_name)
    } else {
      const next_uncompleted_test = rootGetters['tests/next_uncompleted_test']
      console.log('rootGetters[\'tests/next_uncompleted_test\']:', next_uncompleted_test)
      const next_test_params_arr = Object.keys(next_uncompleted_test.params)

      // TODO: refactor next two cases in one somehow...
      const next_test_name = (next_test_params_arr.length)
        ? next_test_params_arr.reduce((acc, param) => acc + ':' + param, next_uncompleted_test.name)
        : next_uncompleted_test.name
      console.log('next_test_name:', next_test_name)
      const next_test_name_2 = (next_test_params_arr.length)
        ? next_test_params_arr.reduce((acc, param) => acc + ':' + param + '=' + next_uncompleted_test.params[param], next_uncompleted_test.name)
        : next_uncompleted_test.name
      console.log('next_test_name_2:', next_test_name_2)

      const this_test_index = project_tests.indexOf(next_test_name)
      console.log('this_test_index:', this_test_index)
      const this_test_index_2 = project_tests.indexOf(next_test_name_2)
      console.log('this_test_index_2:', this_test_index_2)

      if (this_test_index > 0) { // when there are passed tests before this one
        console.log('has previous test! removing it\'s result')
        dispatch(A.REMOVE_RESULT, trim_test_params( project_tests[this_test_index - 1]) )
      } else if (this_test_index_2 > 0) {
        console.log('has previous test! removing it\'s result')
        dispatch(A.REMOVE_RESULT, trim_test_params( project_tests[this_test_index_2 - 1]) )
      } else console.warn('There is no passed tests to reset!')
    }

    function trim_test_params (test_name) {
      return test_name.replace(/:(.+)/gi, '') // remove all symbols after ':', inclusively
    }
  },

  [A.SAVE_TEMP_RESULTS] ({ commit }, tempResults) {
    commit(SAVE_TEMP_RESULTS, tempResults)
  },
  [A.UPDATE_TEMP_RESULTS] ({ commit }, tempResultUpdate) {
    commit(UPDATE_TEMP_RESULTS, tempResultUpdate)
  },
  [A.REMOVE_TEMP_RESULTS] ({ commit }) {
    commit(REMOVE_TEMP_RESULTS)
  },

  [A.SAVE_TEMP_RESULTS_ETAG] ({ commit }, etag) {
    commit(SAVE_TEMP_RESULTS_ETAG, etag)
  },
  [A.REMOVE_TEMP_RESULTS_ETAG] ({ commit }) {
    commit(REMOVE_TEMP_RESULTS_ETAG)
  },

  [A.RESET_TEMP_RESULTS] ({ commit }) {
    commit(REMOVE_TEMP_RESULTS)
    commit(REMOVE_TEMP_RESULTS_ETAG)
    return true
  },

  [A.SET_INFO] ({ commit }, info) {
    if (info) commit('SET_INFO', info)
    else console.log('There is no info, commit declined :(')
  },
  [A.RESET_INFO] ({ commit }) {
    commit('RESET_INFO')
  },
  [A.ADD_TO_INFO] ({ commit }, info) {
    if (info) commit('ADD_TO_INFO', info)
    else console.log('There is no info, commit declined :(')
  },
  [A.REMOVE_FROM_INFO] ({ commit }, field) {
    if (field) commit('REMOVE_FROM_INFO', field)
    else console.log('There is no field to remove, commit declined :(')
  },
  [A.ADD_TO_ROOT] ({ commit }, data) {
    if (data) commit('ADD_TO_ROOT', data)
    else console.log('There is no data, commit declined :(')
  },
  [A.REMOVE_FROM_ROOT] ({ commit }, field) {
    if (field) commit('REMOVE_FROM_ROOT', field)
    else console.log('There is no field to remove, commit declined :(')
  },
  [A.RESET_ALL_RESULTS] ({ dispatch }) {
    return Promise.all([
      dispatch(A.RESET_RESULTS),
      dispatch(A.RESET_TEMP_RESULTS)
    ])
  },
  [A.RESET_ALL_USER_RESULTS] ({ dispatch }) {
    return Promise.all([
      dispatch(A.RESET_USER_RESULTS),
      dispatch(A.RESET_TEMP_RESULTS)
    ])
  },
  [A.RESET_TEST] ({ dispatch }, { confirmation = true } = { confirmation: true }) {
    if (confirmation) {
      let do_reset = confirm('Вы уверены что хотите сбросить результаты и пройти тест заново?')
      if (do_reset) {
        dispatch(A.RESET_RESULTS)
        dispatch(A.RESET_TEMP_RESULTS)
        document.location.reload()
      } else console.log('Reset of results was declined')
    } else {
      dispatch(A.RESET_RESULTS)
      dispatch(A.RESET_TEMP_RESULTS)
      document.location.reload()
    }
  },


  [A.ADD_NEW_USER_RESULTS_OBJ] ({ commit }, user_id) {
    console.log('▲ ACTION: dispatching add_new_user_results_obj with user_id:', user_id)
    commit('ADD_NEW_USER_RESULTS_OBJ', user_id)
  },
  [A.OBSERVE_EXISTING_USER_RESULTS_OBJ] ({ commit }, user_id) {
    console.log('▲ ACTION: dispatching observe_existing_user_results_obj with user_id:', user_id)
    commit('OBSERVE_EXISTING_USER_RESULTS_OBJ', user_id)
  },
  [A.ADD_NEW_USER_RAW_RESULTS_OBJ] ({ commit }, user_id) {
    commit('ADD_NEW_USER_RAW_RESULTS_OBJ', user_id)
  },
  [A.OBSERVE_EXISTING_USER_RAW_RESULTS_OBJ] ({ commit }, user_id) {
    commit('OBSERVE_EXISTING_USER_RAW_RESULTS_OBJ', user_id)
  },
  [A.ADD_NEW_USER_RESULTS_CALCULATED_OBJ] ({ commit }, user_id) {
    console.log('▲ ACTION: dispatching add_new_user_results_calculated_obj with user_id:', user_id)
    commit('ADD_NEW_USER_RESULTS_CALCULATED_OBJ', user_id)
  },
  [A.OBSERVE_EXISTING_USER_RESULTS_CALCULATED_OBJ] ({ commit }, user_id) {
    console.log('▲ ACTION: dispatching observe_existing_user_results_calculated_obj with user_id:', user_id)
    commit('OBSERVE_EXISTING_USER_RESULTS_CALCULATED_OBJ', user_id)
  },
}
