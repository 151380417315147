<template>
  <header class="integrations-header">
    <div class="integrations-header__inner">
      <nav class="integrations-header__nav main-nav">
        <div class="main-nav__toggle">
          <hamburger
            :is-active="menuIsOpen" type="hamburger--emphatic"
            title="Открыть меню"
            class="hamburger--light hamburger--thin hamburger--square" @toggle="toggleMenu"
          />
        </div>
        <a href="//myskills.ru/" class="main-nav__logo" title="Мои достижения, главная">
          <img :src="`/images/logos/img-logo-my-skills.svg`" alt="Логотип Мои достижения">
          <span>Мои достижения</span>
        </a>
        <!-- :isActive="!dev_panel_visibility" -->
        <ul class="main-nav__menu" :class="{'main-nav__menu--open': menuIsOpen}">
          <li class="main-nav__menu-item">
            <a href="//myskills.ru/training" class="main-nav__menu-link main-nav__menu-link--active">Тренажеры</a>
          </li>
          <li class="main-nav__menu-item">
            <a href="//myskills.ru/exam/test/group" class="main-nav__menu-link">Задания</a>
          </li>
          <li class="main-nav__menu-item">
            <a href="//myskills.ru/examresults" class="main-nav__menu-link">Результаты</a>
          </li>
          <li class="main-nav__menu-item">
            <a href="//myskills.ru/video" class="main-nav__menu-link">Видео</a>
          </li>
          <!--<a href="//myskills.ru/consultation/participantconsultations" class="main-nav__menu-link">Консультации</a>-->
          <li class="main-nav__menu-item">
            <a href="//myskills.ru/analytics" class="main-nav__menu-link">Аналитика</a>
          </li>
        </ul>
      </nav>

      <div class="integrations-header__user-info user-info" :class="{'user-info--is-open': userDropdownIsOpen}">
        <a
          class="user-info__username" href="/"
          :title="short_fio"
          @click.prevent="userDropdownIsOpen = !userDropdownIsOpen"
        >{{ short_fio }}</a>
        <!-- <span class="fa fa-angle-down"></span> -->

        <ul class="user-info__dropdown dropdown-menu">
          <li class="dropdown-menu__item">
            <a class="dropdown-menu__link" href="//myskills.ru/profile">Мой профиль</a>
          </li>
          <li class="dropdown-menu__item">
            <a class="dropdown-menu__link dropdown-menu__link--exit" href="//train.myskills.ru/index.php?logout=1" title="Выйти">
              Выйти
              <!-- <span :style="{backgroundImage: 'url(' + `/images/icons/img-my-skills-exit.png` + ')'}"></span> -->
            </a>
          </li>
          <!--
            <form action="/Account/LogOff" id="logoutForm" method="post"><input name="__RequestVerificationToken" type="hidden" value="YQwPQwKUnhGpWTv37ZYK42kC9dEXK04gnbW_RmVwCixYtmzF2OSrF-QVIbrvT1o4IblWIunl2w84DvMDeb7Pk-wywxBNtRlNCYUJJ8hXYvGlkdurfn3WzXBx3gwd1XxMRHuNTtZ7AKe1J9Z9kZBQEw2" />                    <a href="javascript:window.GC.logout();">Выход</a>
            </form>  -->
        </ul>
      </div>
    </div>
  </header>
</template>


<script>
import { mapState } from 'vuex'
import Hamburger from './Hamburger'

export default {
  name: 'integrations-header',
  components: {
    hamburger: Hamburger,
  },
  props: [],
  data () {
    return {
      menuIsOpen: false,
      userDropdownIsOpen: false,
    }
  },
  computed: {
    ...mapState('users', [ 'short_fio' ]),
  },
  methods: {
    toggleMenu () {
      this.$log.debug('toggleMenu() called!')
      this.menuIsOpen = !this.menuIsOpen
    }
  }
}
</script>


<style lang="less" scoped>
  @import "../variables";
  // @import "../variables--my-skills";

  // @menucolor: #3498db;

  .integrations-header {
    font-size: 15px;
    position: relative;
    background-color: @menucolor;
  }

  .integrations-header__inner {
    display: flex;
    flex-wrap: nowrap;
    max-width: 1100px;
    height: 40px;
    margin: auto;

    .responsiveTo(@bp-md - 1, {

    });
  }

  .integrations-header__nav {  // nav.main-nav

  }

  .main-nav {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    background-color: @menucolor;
  }

  .main-nav__logo {  // a
    font-family: @neo_sans_pro_regular;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    height: 40px;

    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    margin-right: 30px;
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;

    background-color: @menucolor;

    .responsiveTo(@bp-md - 1, {
      margin: auto;
    });

    &:hover,
    &:focus {
      text-decoration: none;
    }

    & > img {
      flex-grow: 0;
      display: block;
      width: 43px;
      min-height: auto;
      margin-top: 3px;
      margin-left: 1px;
      margin-right: 4px;
    }

    & > span {
      flex-grow: 1;
      color: #fafafa;
    }
  }

  .main-nav__toggle {  // hamburger
    position: relative;
    z-index: 1;
    width: 105px;
    height: 40px;
    background-color: @menucolor;
    text-align: left;
    cursor: pointer;

    @media screen and (min-width: @bp-md) {
      display: none;
    }
  }

  .main-nav__menu {  // ul
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    background-color: @menucolor;
    will-change: transform;

    .responsiveTo(@bp-md - 1, {
      // display: none;
      position: absolute;
      flex-direction: column;
      transition: transform 0.4s ease-in;
      transform: translateY(-100%);

      &.main-nav__menu--open {
        transform: translateY(40px);
      }
    });

  }

  .main-nav__menu-item {  // li
    // display: inline-block;
  }
  .main-nav__menu-item--active {

  }

  .main-nav__menu-link {  // a
    display: block;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 11px;
    padding-bottom: 11px;
    color: #fafafa;
    text-decoration: none;
    transition: background-color 0.3s ease-in, color 0.3s ease-in;

    .responsiveTo(@bp-md - 1, {
      padding-left: @unit;
      text-align-last: left;
    });

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: lighten(#5dade2, 20%);
      color: #2075af;
    }
  }

  .main-nav__menu-link--active {
    background-color: #5dade2;

    &:after {
      margin: 0 auto;
      // content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid #5dade2;
    }
  }

  .integrations-header__user-info {  // user-info
    position: relative;
    z-index: 4;
  }

  .user-info {
    width: 150px;
    height: 40px;

    &::before {
      content: '';
      position: absolute;
      right: 8px;
      top: 41%;
      width: @unit_half;
      height: @unit_half;
      border-right: 1px solid #f9f9f9;
      border-bottom: 1px solid #f9f9f9;
      transform: rotate(45deg);
    }
  }

  .user-info--is-open {

  }

  .user-info__username {  // a
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    // max-width: calc(100% - 26px);
    padding-top: 11px;
    padding-bottom: 11px;
    white-space: nowrap;
    color: #fafafa;
    text-decoration: none;
  }


  .user-info__dropdown {  // ul.dropdown-menu
    position: absolute;
    z-index: -1;
    top: 35px;
    right: 4px;
    margin: 0;
    opacity: 0;
    transition: z-index 0.3s ease-in, opacity 0.4s ease-in;

    .user-info--is-open & {
      z-index: 1;
      opacity: 1;
    }
  }

  .dropdown-menu {  // ul.user-info__dropdown
    position: absolute;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 150px;
    padding: 0;
    background-color: @white;
    border: 1px solid #e0dede;
    border-radius: 2px;

    &::before {
      content: '';
      position: absolute;
      top: -7px;
      right: -1px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid @white;
    }
  }

  .dropdown-menu__item {  // li
    transition: background-color 0.4s ease-in;

    &:hover,
    &:focus {
      background-color: #f5f5f5;
    }
  }

  .dropdown-menu__link {  // a
    font-size: @fz_smd;
    display: block;
    padding: 5px 7px 5px 15px;
    color: #567492;
    text-decoration: none;
    text-align: left;
  }

  .dropdown-menu__link--exit {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 5px;
      width: 14px;
      height: 15px;
      background-image: url('/images/icons/img-my-skills-exit.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
</style>
