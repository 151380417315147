const classOf = o => Object.prototype.toString.call(o).slice(8, -1)

const type = value => {
  let regex = /^\[object (\S+?)\]$/;
  let matches = Object.prototype.toString.call(value).match(regex) || [];
  matches[1] = Number.isNaN(value) ? 'nan' : matches[1]

  return (matches[1] || 'undefined').toLowerCase();
}

const getCookie = name => {
  var matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
const setCookie = (name, value, options = {}) => {
  let expires = options.expires

  if (expires && classOf(expires) === 'Number') {
    const d = new Date()
    d.setTime(d.getTime() + expires * 1000)
    expires = options.expires = d
  }

  if (expires && expires.toUTCString) options.expires = expires.toUTCString()

  let updatedCookie = name + '=' + encodeURIComponent(value)

  for (let propName in options) {
    updatedCookie += '; ' + propName
    const propValue = options[propName]
    if (propValue !== true) updatedCookie += '=' + propValue
  }

  document.cookie = updatedCookie
}
const deleteCookie = (name) => setCookie(name, '', { expires: -1 })

const isEmptyObj = function isEmptyObj (obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const makeId = function makeId (length) {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  return text
}


function getParamByDotString (obj, str) {
  if (typeof str === 'undefined') return null
  let stringSplitted = str.split('.')
  if (stringSplitted.length === 1) return obj[str]
  return getParamByDotString(obj[stringSplitted[0]], stringSplitted.slice(1).join('.'))
}

const timeout = function timeout (ms) {
  // console.log(`async timeout called with ${ms}ms !`)
  return new Promise(resolve => setTimeout(resolve, ms))
}

const capitalizeFirst = function capitalizeFirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}


//////////////////////////////////
// For tests names translations //
//////////////////////////////////

const legacyTestRoutes = { // FIXME: legacy compatability hack
  'eqtest': 'eq'
}
function fixLegacyRoute (routeName) {
  return legacyTestRoutes[routeName] || routeName
}
function getLegacyRoute (routeName) {
  const foundPair = Object.entries(legacyTestRoutes).find(pair => pair[1] === routeName)
  if (foundPair) return foundPair[0]
  else return routeName
}

function fileToName (testFileName) {
  // console.log(`fileToName(${testFileName})`)
  return testFileName
    .split('')
    .map((char, i) => {
      if (i > 0 && char !== '-' && char === char.toUpperCase()) return '-' + char.toLowerCase()
      return char.toLowerCase()
    })
    .join('')
}
function cleanName (testName) {
  return testName
    .replace(/^test-/, '')
    .replace(/^proxy-test-/, '')
}
function nameToRoute (testName) { // route name is same as data file name or saved results field
  // console.log(`nameToRoute(${testName})`)
  return getLegacyRoute(cleanName(testName))
}
function nameToDataFile (testName) {
  // return nameToRoute(testName)
  return cleanName(testName)
}
function fileToCleanName (testFileName) {
  return cleanName(fileToName(testFileName))
}
function fileToRoute (testFileName) {
  // console.log(`fileToRoute(${testFileName})`)
  return nameToRoute(fileToName(testFileName))
}
function fileToDataFile (testFileName) {
  // console.log(`fileToDataFile(${testFileName})`)
  return nameToDataFile(fileToName(testFileName))
}
function nameToFile (testName) {
  return testName.split('-').map(capitalizeFirst).join('')
}
function cleanRoute (routeName) {
  return routeName.replace(/:.*$/gi, '')
}
function routeToName (routeName) {
  const cleanedRouteName = cleanRoute(routeName)
  // console.log('cleanedRouteName:', cleanedRouteName)
  const properRouteName = fixLegacyRoute(cleanedRouteName)
  // console.log('properRouteName:', properRouteName)
  const proxyTestRoutes = [
    'subject',
    'theme',
    'combo',
  ]
  const isProxyTestRoute = proxyTestRoutes.includes(properRouteName)
  if (isProxyTestRoute) {
    return 'proxy-test-' + properRouteName
  } else {
    return 'test-' + properRouteName
  }
}
function routeToFile (routeName) {
  return nameToFile(routeToName(routeName))
}

const transformTest = {
  fileToName,
  cleanName,
  nameToRoute,
  nameToDataFile,
  fileToCleanName,
  fileToRoute,
  fileToDataFile,
  nameToFile,
  cleanRoute,
  fixLegacyRoute,
  getLegacyRoute,
  routeToName,
  routeToFile,
}

export default {
  classOf,
  type,
  getCookie,
  setCookie,
  deleteCookie,
  isEmptyObj,
  makeId,
  timeout,
  capitalizeFirst,
  transformTest,
  getParamByDotString
}
