/**
 * Checked & extracted project names:
 * 1. 'a'
 * 2. 'mcko'
 * 3. 'lib'
 * 4. 'asi'
 * 5. 'rosel'  -> FIXME: hardcoded in many results, need to fix
 * 6. 'smart'
 * 7. 'ad'     -> FIXME: hardcoded in many results & tests, need to fix
 */

const project_languages_masks = Object.freeze({
  common: '0',
  ikigai: '01',
  knjpu: '02',
})

const show_languages_on = Object.freeze({
  common: [
    'knjpu',
    'ikigai',
    // 'worldskillz',
  ],
  'my-skills': [
    // 'worldskillz',
  ]
})

const show_integrations_header_on = Object.freeze({
  common: [
    // my-skills-m,
    // my-skills-h,
  ],
  'my-skills': [
    // my-skills-m,
    // my-skills-h,
  ]
})

const multiple_completions_projects = Object.freeze([
  'common',
  'lib',
  'future',
  'camp',
  'fa',
  'tiji',
  'tbspb',
  // '4k',
  // 'ikigai',
  // 'a',
  // 'aa'
  // 'my-skills-social-science',
  // 'my-skills-computer-science',
  // 'my-skills-english-language',
  // 'my-skills-russian-language',
  // 'my-skills-biology',
  // 'my-skills-algebra',
])

const show_prev_results_projects = Object.freeze([
  'tbspb',
])

const components_config = Object.freeze({
  'main-page-button': {
    // show_main_page_link_on: [  // This will be at the caller component
    //   'my-skills-m',
    //   'my-skills-h',
    //   // 'my-skills-algebra',
    //   // 'my-skills-biology',
    //   // 'my-skills-computer-science',
    //   // 'my-skills-social-science',
    //   // 'my-skills-russian-language',
    //   // 'my-skills-english-language',
    // ],
    main_page_url_groups: {
      'my-skills': [
        'my-skills-m',
        'my-skills-h',
        'my-skills-algebra',
        'my-skills-biology',
        'my-skills-english-language',
        'my-skills-russian-language',
        'my-skills-social-science',
        'my-skills-computer-science',
      ],
    },
  }
})

const pages_config = Object.freeze({
  custom_page_receivers: {
    navigation: [
      'my-skills*'
    ],
    'navigation-school-subjects': [
      'my-skills*'
    ],
    'navigation-soft-skills': [
      'my-skills*'
    ],
    'navigation-online-courses': [
      'my-skills*'
    ],
  },
})

const tests_config = Object.freeze({
  custom_test_receivers: {
    // beforeTest components
    //
    // tests components
    pages: [
      'my-skills*'
    ],
    burnout: [
      'reanimation',
      'reanimation-out'
    ],
    'meta-mult': [
      // 'foxford-m-1-4',
    ],
    info: [
      'foxford-meta*',
      'kb',
      'kb-out',
      'mcko',
      'mcko-out',
      'mcko-teacher',
      'mcko-teacher-out',
    ],
    hello: [
      'ad1',
      [ 'ad1', [
        'ad1-2021',
      ]],
      'ad2',
      [ 'ad2', [
        'ad2-2021',
      ]],
      'ad3',
      [ 'ad3', [
        'ad3-2021',
      ]],
      'school-1-4',
      'altai',
      'book',
      'camp',
      'cv21',
      'bcpd',
      'fa',
      'foxford-m',
      [ 'foxford-m', [
        'foxford-h',
      ]],
      'ithub',
      'kb',
      'kids-test',
      [ 'kids-test', [
        'kids-test-clone',
        'parents-and-kids',
        'ednav-kids'
      ]],
      'knjpu',
      'leader*',
      'ma', // not like in ey
      'managercupin',
      'managercupfinal',
      'my-skills*', // this will be overritten by next my-skills instances
      'my-skills-h',
      'my-skills-m',
      'oneieg',
      'tbspb',
      'reanimation',
      'reanimation-out',
      'managercuptest',
      'managercuptest-sf',
      'entrepreskill',
      'foxford-m-1-4',
      'foxford-m-5-8',
      [ 'foxford-m-5-8', [ 'foxford-h-9-11' ]],
      'arfg-adult',
      'arfg-junior'
      // 'tiji',
    ],
    entrepreneur: [],
    'eq-drivers': [],
    'eq-driver-adaptability': [],
    'eq-driver-selfesteem': [],
    anonymous: [
      'altai',
      'hearts',
      'knjpu',
      'mentori-e',
      'mentori-t',
      'mentori-t-out',
      'my-skills-h',
      'my-skills-m',
      'reanimation',
      'reanimation-out'
    ],
    selfrating: [
      // 'a',
      [ 'a', [ // use @/views/tests/a/TestSelfrating for this projects:
        'a-clone',
        'demo',
        'softgames-t',
        'unti-t',
        'ednav',
        'arfg-junior',
        'a-kz'
      ]],
      'ad1-second',
      'ad2-second',
      [ 'ad2-second', [ 'foxford-m-5-8' ]],
      'altai',
      'school-1-4',
      'foxford-m',
      [ 'foxford-m', [ // custom data
        'foxford-h',
        'mentori-s',
        'mentori-s-out',
      ]],
      'reanimation',
      'reanimation-out',
      'hearts',
      'ithub',
      'ithub-2',
      'knjpu',
      'leader*', // this will be overritten by next leader instances
      [ 'leader', [ 'foxford-m-1-4' ]],
      'leader-8-9',
      [ 'leader-8-9', [
        'leader-8-9-third'
      ]],
      'leader-8-9-second',
      'leader-10-11',
      [ 'leader-10-11', [
        'leader-10-11-third'
      ]],
      'leader-10-11-second',
      'mcko',
      'mcko-out',
      [ 'mcko', [
        'book',
        'kb',
        'kb-out',
      ]],
      'mcko-teacher',
      'mcko-teacher-out',
      'mentori-e-out',
      'mentori-t',
      'mentori-t-out',
      'my-skills-m',
      'my-skills-h',
      'smart*',
      'cr-adult-in',
      'cr-junior-in'
    ],
    'soft-skills': [
      'ad1-second',
      'ad2-second',
      'ad3-second',
      'ad3-second-out'
    ],
    naviki: [
      'altai',
      'ey',
      [ 'ey', [
        'cv21',
        'bcpd',
        'cognition',
        'digitaleconomics',
        'ey-clone',
        'ma',
        'mts',
        'naos',
        'neva',
        'sts',
        'tolkacheva-sv',
      ]],
      'reanimation',
      'reanimation-out',
      'foxford-m',
      [ 'foxford-m', [ // custom data
        'foxford-h',
      ]],
      'hearts',
      'ithub',
      'kids-test',
      [ 'kids-test', [
        'kids-test-clone',
        'parents-and-kids',
        'ednav-kids'
      ]],
      'knjpu',
      'leader*',
      'managercupin',
      'mcko',
      'mcko-out',
      'mentori-e',
      'mentori-e-out',
      'mentori-s',
      'mentori-t',
      'my-skills-h',
      'my-skills-m',
      'rosel',
      'smart',
      'tbspb',
    ],
    meta: [
      'altai',
      [ 'altai', [ // custom data
        'reanimation',
        'reanimation-out',
      ]],
      'hearts',
      'leader*', // all leader-* tests except leader-10-11* tests
      'leader-10-11*', // also leader-10-11-second
      [ 'leader', [ // custom data
        'ithub',
      ]],
      'mcko',
      'mcko-out',
      'mcko-teacher',
      'mcko-teacher-out',
      'mentori-e',
      'mentori-e-out',
      'mentori-t',
      'mentori-t-out',
      'smart*',
    ],
    involvement: [
      'smart*',
      'reanimation',
      'reanimation-out',
    ],
    'education-profiles': [
      'a',
      [ 'a', [ // use @/views/tests/a/TestEducationProfiles for this projects:
        'a-clone',
        'demo',
        'ednav',
        'a-kz'
      ]],
      'foxford*',
      'leader*',
      'managercupfinal',
      'my-skills-h',
      'my-skills-m',
      'smart*',
    ],
    'academic-performance': [
      'my-skills-h',
      'my-skills-m',
      'smart*',
      'cr-academic-performance'
    ],
    'child-development': [
      'kids-test',
      [ 'kids-test', [
        'kids-test-clone',
        'parents-and-kids',
        'ednav-kids'
      ]],
    ],
    'visual-soft-skills': [
      'ad1',
      [ 'ad1', [
        'ad1-2021',
        'foxford-m-1-4'
      ]],
      'ad2',
      [ 'ad2', [
        'ad2-2021',
      ]],
      'ad3',
      [ 'ad3', [
        'ad3-2021',
      ]],
    ],
    'visual-education-profiles-1': [
      'ad1',
      [ 'ad1', [
        'ad1-2021',
      ]],
      'ad2',
      [ 'ad2', [
        'ad2-2021',
      ]],
      'ad3',
      [ 'ad3', [
        'ad3-2021',
      ]],
      'leader-1-4',
      [ 'leader-1-4', [
        'school-1-4',
        'leader-1-4-2021'
      ]],
      'leader-5-7',
      'foxford-m-1-4',
    ],
    'visual-disc': [
      'ad1',
      [ 'ad1', [
        'ad1-2021',
      ]],
      'ad2',
      [ 'ad2', [
        'ad2-2021',
        'foxford-m-5-8'
      ]],
      'ad3',
      [ 'ad3', [
        'ad3-2021',
        'foxford-h-9-11'
      ]],
      'ithub',
      'leader-1-4-second',
      'leader-8-9',
      'managercupfinal',
      'cr-sova',
      'cr-adult-sova',
      [ 'cr-adult-sova', [
        'arfg-adult'
      ]],
      'cr-junior-sova',
      [ 'cr-junior-sova', [
        'arfg-junior'
      ]]
    ],
    'text-selector': [
      'ranh*'
    ],
    'endless-associations': [
      // 'ranh*',
    ],
    'subject': [ // proxy
      'my-skills*',
    ],
    'subject-themes': [
      'my-skills*',
    ],
    'theme': [ // proxy
      'my-skills*',
    ],
    'combo': [ // proxy
      'ranh*',
      'tgu',
      'tgu-out'
    ],
    confirm: [
      'fa',
      'foxford-meta*',
      'kb',
      'kb-out',
      'mcko',
      'mcko-out',
      'mcko-teacher',
      'mcko-teacher-out',
      'smart*',
      'reanimation',
      'reanimation-out'
    ],
    eq: [
      'altai',
      'knjpu',
      'managercupin',
      'leader-10-11-third',
      [ 'leader-10-11-third', [ 'foxford-h-9-11' ]]
    ],
    'locus-of-control-rating': [
      'ad2-second',
      [ 'ad2-second', [ 'foxford-m-5-8' ]],
      'ad3-second',
      [ 'ad3-second', [ 'foxford-h-9-11' ]],
      'ad3-second-out',
      'leader-10-11-second',
      [ 'leader-10-11-second', [ 'cr-junior-locus-of-control' ]],
      'leader-8-9-second',
      'managercupfinal',
      'cr-adult-locus-of-control'
    ],
    'visual-roles-a': [],
    'finrating': [
      'arfg-junior'
    ]

    // afterTest components
    //
  },

  custom_test_data_receivers: {
    selfrating: [
      'mentori-s',
      'mentori-s-out',
      'foxford-h',
      // 'foxford*', // with @/views/tests/foxford/naviki.js data file for all foxford* projects
    ],
    naviki: [
      'foxford-h',
      'reanimation',
      'reanimation-out'
    ],
    meta: [
      'ithub',
      'ey',
      'tgu',
      'tgu-out',
      'cr-adult-in'
    ]
  },

  test: {
    show_main_page_link_on: [
      'my-skills-m',
      'my-skills-h',
      // 'my-skills-algebra',
      // 'my-skills-biology',
      // 'my-skills-computer-science',
      // 'my-skills-social-science',
      // 'my-skills-russian-language',
      // 'my-skills-english-language',
    ],
    idle_reset_receivers: [ 'lib' ],
  },

  'test-faces': {
    default: {
      pass_test_for_adults_projects: [
        'skillfolio',
        'common',
        'test'
      ],
      custom_levels_count: {
        3: [ 'a', 'demo', 'a-clone', 'my-skills-m', 'my-skills-h', 'ednav', 'a-kz' ]
      }
    }
  },
  'test-eq': {
    'default': {
      short_questions_receivers: [
        'you-eq'
      ]
    },
    'altai': {
      short_questions_receivers: [
        'you-eq'
      ]
    },
    'knjpu': {
      short_questions_receivers: [
        'you-eq'
      ]
    },
    'leader-10-11-third': {
      short_questions_receivers: [
        'you-eq'
      ]
    },
    'managercupin': {
      short_questions_receivers: [
        'you-eq'
      ]
    },
  },
  'test-naviki': {
    default: {
      pass_test_for_projects: [],
    },
    altai: {
      pass_test_for_projects: [],
    },
    'foxford-m': {
      pass_test_for_projects: [],
    },
    hearts: {
      pass_test_for_projects: [],
    },
    knjpu: {
      pass_test_for_projects: [],
    },
    smart: {
      pass_test_for_projects: [],
    },
    'leader': {
      pass_test_for_projects: [],
    },
    'leader-5-7-second': {
      pass_test_for_projects: [],
    },
    'leader-8-9-second': {
      pass_test_for_projects: [],
    },
    'leader-10-11-second': {
      pass_test_for_projects: [],
    },
    'ithub-2': {
      pass_test_for_projects: [],
    },
    'kids-test': {
      pass_test_for_projects: [],
    },
    'mentori-e': {
      pass_test_for_projects: [],
    },
    'mentori-s': {
      pass_test_for_projects: [],
    },
    'mentori-t': {
      pass_test_for_projects: [],
    },
    'my-skills-h': {
      pass_test_for_projects: [],
    },
    'my-skills-m': {
      pass_test_for_projects: [],
    },
  },
  'test-education-profiles': {
    default: {
      // minifiedOn: [
      //   '4k',
      //   'ikigai',
      //   'worldskillz',
      // ],
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    a: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    'a-clone': {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    demo: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    foxford: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    leader: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    managercupfinal: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    'my-skills': {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    'my-skills-h': {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    'my-skills-m': {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    smart: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
  },
  'test-education-profiles-extended': {
    default: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    }
  },
  'test-ikigai': {
    default: {
      noMinificationFor: [ 'asi' ], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    }
  },
  'test-likes-selector': {
    default: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    'my-skills': {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
  },
  'test-meta-mult': {
    default: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    }
  },
  'test-tiji': {
    default: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    },
    tiji: {
      noMinificationFor: [], // defaults: minified
      useStageConfirmationFor: [], // defaults: not using stage confirmation
    }
  }
})

const roles_config = Object.freeze({
  modes: {
    role: [ 'default', 'common' ],
    role2: [
      '4k',
      'ikigai',
      'worldskillz',
      'trainer',
      'digital-fest',
    ],
    role3: [
      'foxford-m',
      'foxford-h',
      'my-skills-m',
      'my-skills-h',
      'mentori-s',
      // 'mentori-t',
      'mentori-e',
      'mentori-e-out',
      'smart',
    ],
    role4: [
      'book',
      'leader-10-11'
    ],
    role5: [ 'kids-test', 'ednav-kids' ],
    role6: [
      'mentori-t',
      'hearts',
    ],
    role7: [ 'tiji' ],
    role8: [ 'a', 'a-clone', 'demo', 'ednav', 'a-kz' ],
    role_knjpu: [ 'knjpu' ]
  }
})

const signup_config = Object.freeze({
  disable_lastname_for: [
    'ednav',
    'ednav-kids'
  ],
  disable_gender_for: [
    'parents-and-kids',
    'managercupin',
  ],
  disable_birthday_for: [
    'parents-and-kids',
    'managercupin',
  ],
  enable_child_age_for: [
    'parents-and-kids',
  ],
  enable_phone_for: [
    'digital-fest',
    'sts',
    'mts',
    'parents-and-kids',
    'cv21',
    'bcpd',
    'tbspb',
    'unti-a',
    'unti-t',
    'softgames-a',
    'softgames-t',
  ],
  enable_region_for: [
    'ednav',
    'ednav-kids'
  ],
  enable_additional_agreement_for: [
    'cv21',
    'bcpd',
  ],
})

const results_config = Object.freeze({
  'result-controls': {
    show_main_page_link_on: [
      'my-skills-m',
      'my-skills-h',
      'my-skills-algebra',
      'my-skills-biology',
      'my-skills-computer-science',
      'my-skills-social-science',
      'my-skills-russian-language',
      'my-skills-english-language',
    ],
  },
  'result-user': {
    // disableFor: [],
    // disableFullCoverFor: ['camp'],
    // enableCustomInnerFor: ['camp'],
    hideLogoFor: [
      'camp'
    ],
    hideExplanationFor: [
      'common',
      'camp',
      'mcko',
      'mcko-out',
      'mcko-teacher',
      'mcko-teacher-out',
    ],
    showExplanationFor: [
      'common',
    ],
    hideRealAgeFor: [
    ],
    showRealAgeFor: [
      'foxford-m',
      'foxford-h',
    ],
    covers: {
      camp: 'results/user/bg-rosa-hutor.jpg'
    },
  },
  'result-user-2': {
    // disableFor: [],
    // disableFullCoverFor: ['camp'],
    // enableCustomInnerFor: ['camp'],
    hideLogoFor: [
      'camp'
    ],
    hideExplanationFor: [
      'common',
      'camp',
      'mcko',
      'mcko-out',
      'mcko-teacher',
      'mcko-teacher-out',
    ],
    showExplanationFor: [
      'common',
    ],
    hideRealAgeFor: [
    ],
    showRealAgeFor: [
      'foxford-m',
      'foxford-h',
    ],
    covers: {
      camp: 'results/user/bg-rosa-hutor.jpg'
    },
  },
  'result-user-3': {
    // disableFor: [],
    // disableFullCoverFor: ['camp'],
    // enableCustomInnerFor: ['camp'],
    hideLogoFor: [
      'camp'
    ],
    hideExplanationFor: [
      'common',
      'camp',
      'mcko',
      'mcko-out',
      'mcko-teacher',
      'mcko-teacher-out',
    ],
    showExplanationFor: [
      'common',
    ],
    hideRealAgeFor: [
    ],
    showRealAgeFor: [
      'foxford-m',
      'foxford-h',
    ],
    covers: {
      camp: 'results/user/bg-rosa-hutor.jpg'
    },
  },
  'result-user-4': {
    hideLogoFor: [
      'camp'
    ],
    hideExplanationFor: [
      'common',
      'camp',
      'mcko',
      'mcko-out',
      'mcko-teacher',
      'mcko-teacher-out',
    ],
    showExplanationFor: [
      'common',
    ],
    hideRealAgeFor: [
    ],
    showRealAgeFor: [
      'foxford-m',
      'foxford-h',
    ],
    covers: {
      camp: 'results/user/bg-rosa-hutor.jpg'
    },
  },
  'result-user-5': {
    hideLogoFor: [
      'camp'
    ],
    hideExplanationFor: [
      'common',
      'camp',
      'mcko',
      'mcko-out',
      'mcko-teacher',
      'mcko-teacher-out',
    ],
    showExplanationFor: [
      'common',
    ],
    hideRealAgeFor: [
    ],
    showRealAgeFor: [
      'foxford-m',
      'foxford-h',
    ],
  },
  'result-user-6': {
    hideLogoFor: [
      'camp',
      'altai'
    ],
    hideExplanationFor: [
      'common',
      'camp',
      'mcko',
      'mcko-out',
      'mcko-teacher',
      'mcko-teacher-out',
    ],
    showExplanationFor: [
      'common',
    ],
    hideRealAgeFor: [
    ],
    showRealAgeFor: [
      'foxford-m',
      'foxford-h',
    ],
  },
  'result-user-7': {
    hideLogoFor: [
      'camp',
      'knjpu'
    ],
    hideExplanationFor: [
      'common',
      'camp',
      'mcko',
      'mcko-out',
      'mcko-teacher',
      'mcko-teacher-out',
    ],
    showExplanationFor: [
      'common',
    ],
    hideRealAgeFor: [
    ],
    showRealAgeFor: [
      'foxford-m',
      'foxford-h',
    ],
  },

  'result-promo': {
    disabled_promo: {
      1: [ 'asi', 'future', 'camp' ],
      2: []
    },
    1: {
      lib: {
        all: {
          button_text: 'Используй промокод «SMART» и получи скидку 2500 рублей',
          button_event_url: 'https://caseid.timepad.ru/event/874510/',
          promo_text: 'Тест <span class="u-uppercase">skillfolio</span> позволяет понять свои сильные стороны, и увидеть какие следует «прокачать». Развить и проработать их Вам поможет курс «<a href="https://eqfactor.ru/park">Парк Чемпионов. Навыки будущего</a>».',
          // course_url: 'https://eqfactor.ru/park',
          // course_title: 'Парк Чемпионов. Навыки будущего',

          // button: '<a class="button primary" target="_blank" style="" href="https://caseid.timepad.ru/event/874510/">Используй промокод SMART и получи скидку 2500 рублей</a>',
          // text: '<p>Тест SKILLFOLIO позволяет понять свои сильные стороны, и увидеть какие следует «прокачать». Развить и проработать их Вам поможет курс«<a href="https://eqfactor.ru/park">Парк Чемпионов. Навыки будущего</a>».</p>',
        }
      },
      future: {
        all: {
          button_text: 'Получить доступ к программному обеспечению «Навыки будущего» на 6 месяцев',
          button_event_url: 'https://eqfactorlab.ru/future',
          promo_text: 'Тест <span class="u-uppercase">skillfolio</span> позволяет понять свои сильные стороны, и увидеть какие следует «прокачать». Развить и проработать их Вам поможет программное обеспечение «<a href="https://eqfactorlab.ru/future">Навыки будущего</a>».',

          // button: '<a class="button primary" target="_blank" style="" href="https://eqfactorlab.ru/future">Получить доступ к программному обеспечению "Навыки будущего"<br>на 6 месяцев</a>',
        }
      },
      'parents-and-kids': {
        all: {
          button_text: 'Присоединяйтесь',
          button_event_url: 'https://skillfolio.ru/parents-and-kids-payment',
          promo_text: `И только сегодня Мы предлагаем Вам принять участие
              в онлайн-практикуме "Родители и Дети" от Виктории Шиманской и Анастасии Гребенкиной
              по специальной цене: Всего 1900 руб, вместо 4900 руб,
              а также получить расширенную консультацию специалиста
              по результатам вашего теста.`,
        }
      },
    },
    2: {
      lib: {
        all: {
          button_text: 'Используй промокод «SMART» и получи скидку 2500 рублей',
          button_event_url: 'https://caseid.timepad.ru/event/874510/',
          promo_text: 'Тест <span class="u-uppercase">skillfolio</span> позволяет понять свои сильные стороны, и увидеть какие следует «прокачать». Развить и проработать их Вам поможет курс «<a href="https://eqfactor.ru/park">Парк Чемпионов. Навыки будущего</a>».',
          // course_url: 'https://eqfactor.ru/park',
          // course_title: 'Парк Чемпионов. Навыки будущего',

          // button: '<a class="button primary" target="_blank" style="" href="https://caseid.timepad.ru/event/874510/">Используй промокод SMART и получи скидку 2500 рублей</a>',
          // text: '<p>Тест SKILLFOLIO позволяет понять свои сильные стороны, и увидеть какие следует «прокачать». Развить и проработать их Вам поможет курс«<a href="https://eqfactor.ru/park">Парк Чемпионов. Навыки будущего</a>».</p>',
        }
      },
      future: {
        all: {
          button_text: 'Получить доступ к программному обеспечению «Навыки будущего» на 6 месяцев',
          button_event_url: 'https://eqfactorlab.ru/future',
          promo_text: 'Тест <span class="u-uppercase">skillfolio</span> позволяет понять свои сильные стороны, и увидеть какие следует «прокачать». Развить и проработать их Вам поможет программное обеспечение «<a href="https://eqfactorlab.ru/future">Навыки будущего</a>».',

          // button: '<a class="button primary" target="_blank" style="" href="https://eqfactorlab.ru/future">Получить доступ к программному обеспечению "Навыки будущего"<br>на 6 месяцев</a>',
        }
      },
      camp: {
        all: {
          button_text: 'Успей записаться по сниженной цене',
          button_event_url: 'https://caseid.timepad.ru/event/874510/',
          promo_text: 'Тест <span class="u-uppercase">skillfolio</span> позволяет понять свои сильные стороны, и увидеть какие следует «прокачать». Развить и проработать их Вам поможет курс «<a href="https://eqfactorlab.ru/camp">Парк Чемпионов. Навыки будущего</a>».',
        }
      },
      big: {
        all: {
          button_text: 'Получить персональную консультацию сейчас',
          button_event_url: 'https://caseid.timepad.ru/event/963721/',
          promo_text: 'Получите часовую персональную консультацию по Вашей траектории развития на основе тестирования.',
        }
      },
      book: {
        all: {
          button_text: 'Купить книгу со скидкой 10% по промокоду "<span class="u-weight-heavy">futureskills</span>"',
          button_event_url: 'https://www.alpinabook.ru/catalog/book-553702/',
          promo_text: 'Виктория Шиманская — доктор психологии, — подготовила в книге "Коммуникация" простые, но эффективные упражнения, которые помогут подросткам лучше понять себя, развить эмпатию и легко находить друзей и единомышленников.',
        }
      },
      'parents-and-kids': {
        all: {
          button_text: 'Присоединяйтесь',
          button_event_url: 'https://skillfolio.ru/parents-and-kids-payment',
          promo_text: `И только сегодня Мы предлагаем Вам принять участие
              в онлайн-практикуме "Родители и Дети" от Виктории Шиманской и Анастасии Гребенкиной
              по специальной цене: Всего 1900 руб, вместо 4900 руб,
              а также получить расширенную консультацию специалиста
              по результатам вашего теста.`,
        }
      },
    }
  },
  'result-promo-media': {
    1: {},
    2: {
      book: {
        all: {
          button_text: 'futureskills',
          button_event_url: 'https://www.alpinabook.ru/catalog/book-553702/',
          promo_text: 'Скидка 10% на предзаказ по промокоду',
          promo_img: `/images/results/promo-media/img-communication-book.jpg`,
          promo_logo: `/images/logos/img-logo-alpina.png`,
          promo_text2: 'на сайте alpina.ru',
          promo_qr_code: `/images/results/promo-media/img-qr-alpina.svg`,
        }
      }
    }
  },
  'result-promo-4': {
    1: {},
    2: {
      cv21: {
        kid: {
          promo_text: `
                100 участников проекта получат возможность пройти персональную
                карьерную консультацию у ведущих специалистов платформы Skillfolio:
                какую тактику выбрать в поиске работы, на что обратить внимание будущего работодателя,
                как и зачем писать сопроводительное письмо и другие вопросы,
                ответы на которые помогут сделать следующий шаг на пути к своему предназначению.
                Всю информацию ты получишь на указанный при регистрации e-mail.
              `,
        },
        adult: {
          promo_text: `
                100 участников проекта получат возможность пройти персональную
                карьерную консультацию у ведущих специалистов платформы Skillfolio:
                какую тактику выбрать в поиске работы, на что обратить внимание будущего работодателя,
                как и зачем писать сопроводительное письмо и другие вопросы,
                ответы на которые помогут сделать следующий шаг на пути к своему предназначению.
                Всю информацию Вы получите на указанный при регистрации e-mail.
              `,
        }
      },
      tbspb: {
        kid: {
          promo_text: `
                100 участников проекта получат возможность пройти персональную
                карьерную консультацию у ведущих специалистов платформы Skillfolio:
                какую тактику выбрать в поиске работы, на что обратить внимание будущего работодателя,
                как и зачем писать сопроводительное письмо и другие вопросы,
                ответы на которые помогут сделать следующий шаг на пути к своему предназначению.
                Всю информацию ты получишь на указанный при регистрации e-mail.
              `,
        },
        adult: {
          promo_text: `
                100 участников проекта получат возможность пройти персональную
                карьерную консультацию у ведущих специалистов платформы Skillfolio:
                какую тактику выбрать в поиске работы, на что обратить внимание будущего работодателя,
                как и зачем писать сопроводительное письмо и другие вопросы,
                ответы на которые помогут сделать следующий шаг на пути к своему предназначению.
                Всю информацию Вы получите на указанный при регистрации e-mail.
              `,
        }
      }
    }
  },

  'result-abilities': {
    emotions_primary_formula_users: [ // legacy
      'asi',
      'future',
      'mcko',
      'mcko-teacher',
      'lib',
      'kursk',
      // 'test'
    ],
  },
  'result-abitites-loc': {
    emotions_primary_formula_users: [
      'asi',
      'future',
      'mcko',
      'mcko-teacher',
      'lib',
      'kursk',
      // 'test'
    ],
  },
  'result-eq': {
    emotions_primary_formula_users: [
      'asi',
      'future',
      'mcko',
      'mcko-teacher',
      'lib',
      'kursk',
      // 'test'
    ],
  },
  'result-greeting-5': {
    project_logo_data: {
      default: {
        img: 'asi',
        href: 'https://asi.ru/',
        title: 'сайт АСИ',
        alt: 'логотип АСИ'
      },
      bcpd: {
        img: 'alpharabius',
        href: 'http://alpharabius.kz/',
        title: 'сайт alpharabius',
        alt: 'логотип alpharabius'
      }
    }
  },

  'result-role': {
    description_receivers: [
      'ey', 'cognition', 'reanimation', 'reanimation-out', 'cv21', 'bcpd', 'tbspb', 'naos', 'neva', 'ey-clone', 'mts', 'sts', 'ma', 'tolkacheva-sv', 'digitaleconomics'
    ],
    custom_image_receivers: [
      'ey', 'cognition', 'reanimation', 'reanimation-out', 'cv21', 'bcpd', 'tbspb', 'naos', 'neva', 'ey-clone', 'mts', 'sts', 'ma', 'tolkacheva-sv', 'digitaleconomics'
    ],
  },
  'result-role-3': {
    description_receivers: [
      'smart',
    ],
  },
  'result-visual-role-a-1': {
    description_receivers: [
      'smart',
    ],
  },
  'result-role-4': {
    description_receivers: [
      'a',
      'demo',
      'a-clone',
      'ednav',
      'a-kz'
    ],
  },
  'result-role-8': {
    description_receivers: [
      'demo',
      'a-clone',
    ],
  },
  'result-role-9': {
    description_receivers: [
      'a',
      'demo',
      'a-clone',
      'ednav',
      'a-kz'
    ],
  },
  'result-role-10': {
    description_receivers: [
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'ey-clone',
      'mts',
      'sts',
      'ma',
      'tolkacheva-sv',
      'digitaleconomics'
    ],
    custom_image_receivers: [
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'ey-clone',
      'mts',
      'sts',
      'ma',
      'tolkacheva-sv',
      'digitaleconomics'
    ],
  },
  'result-role-10-loc': {
    description_receivers: [
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'ey-clone',
      'mts',
      'sts',
      'ma',
      'tolkacheva-sv',
      'digitaleconomics'
    ],
    custom_image_receivers: [
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'ey-clone',
      'mts',
      'sts',
      'ma',
      'tolkacheva-sv',
      'digitaleconomics'
    ],
  },
  'result-role-12': {
    description_receivers: [
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'ey-clone',
      'mts',
      'sts',
      'ma',
      'tolkacheva-sv',
      'digitaleconomics'
    ],
    custom_image_receivers: [
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'ey-clone',
      'mts',
      'sts',
      'ma',
      'tolkacheva-sv',
      'digitaleconomics'
    ],
  },
  'result-role-13': {
    description_receivers: [
      'a',
      'ednav',
      'a-kz'
    ],
  },
  // FIXME: this result is bad practice
  'result-role-ad': {
    texts: {}, // TODO: add this or fix
    roles: {}, // TODO: add this or fix
    description_receivers: [],
  },
  'result-role-sova': {
    project_images: {
      'default': 'cr',
      'cr-sova': 'cr',
      'cr-adult-sova': 'cr',
      'cr-junior-sova': 'ad2',
      'ad1': 'ad1',
      'ad2': 'ad2',
      'ad3': 'ad3',
      'ad1-2021': 'ad1',
      'ad2-2021': 'ad2',
      'ad3-2021': 'ad3',
      'foxford-m-1-4': 'ad1',
      'foxford-m-5-8': 'ad2',
      'foxford-h-9-11': 'ad3',
      'arfg-adult': 'cr',
      'arfg-junior': 'ad2',
    }
  },
  'result-role-disc': {
    description_receivers: [],
  },
  'result-role-disc-1': {
    description_receivers: [],
  },
  'result-role-disc-2': {
    description_receivers: [],
  },
  'result-personality': {
    description_receivers: [],
  },
  'result-role-school-subject': {
    description_receivers: [
      // 'my-skills-social-science',
      // 'my-skills-computer-science',
      // 'my-skills-english-language',
      // 'my-skills-russian-language',
      // 'my-skills-biology',
      // 'my-skills-algebra',
    ],
  },

  'result-skills': {
    only_skills_radar: [ 'mcko' ],
    hide_meta_on_skills_radar: [ 'mcko' ],
    show_meta_radar: [ 'mcko-teacher', 'mcko-teacher-out', 'mcko-out', 'foxford-meta-m', 'foxford-meta-h' ],
    only_meta_radar: [ 'mcko-teacher' ],
    additional_text_receivers: [
      'a',
      'a-kz',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv',
      'digitaleconomics',
      'ednav'
    ]
  },
  'result-entrepreneur': {
    additional_text_receivers: []
  },
  'result-skills-2': {
    show_skills_radar: [],
    only_skills_radar: [ 'mcko' ],
    hide_meta_on_skills_radar: [ 'mcko' ],
    show_meta_radar: [ 'mcko-teacher', 'mcko-teacher-out', 'mcko-out' ],
    only_meta_radar: [ 'mcko-teacher' ],
  },
  'result-skills-3': {
    show_skills_radar: [],
    only_skills_radar: [],
    hide_meta_on_skills_radar: [],
    show_meta_radar: [ 'mentori-s-out', 'mentori-t-out', 'book' ],
    only_meta_radar: [ 'mentori-s-out', 'mentori-t-out', 'book' ],
    additional_text_receivers: [
      'my-skills-m',
      'my-skills-h',
      'mentori-s',
      'mentori-t',
      'mentori-e',
      'mentori-e-out',
      'mentori-t-out',
      'mentori-s-out',
      'book',
      'smart',
    ]
  },
  'result-skills-4': {
    show_skills_radar: [],
    only_skills_radar: [ 'mcko' ],
    hide_meta_on_skills_radar: [ 'mcko' ],
    show_meta_radar: [
      'mcko-teacher',
      'mcko-teacher-out',
      'mcko-out',
      'foxford-meta-m',
      'foxford-meta-h',
      'a',
      'a-kz',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv',
      'digitaleconomics',
      'managercupfinal',
      'ednav'
    ],
    only_meta_radar: [
      'mcko-teacher',
      'a',
      'a-kz',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'digitaleconomics',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv',
      'ednav'
    ],
    additional_text_receivers: [
      'a',
      'a-kz',
      'ednav',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'digitaleconomics',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv'
    ]
  },
  'result-skills-5': {
    show_skills_radar: [],
    only_skills_radar: [ 'mcko' ],
    hide_meta_on_skills_radar: [ 'mcko' ],
    show_meta_radar: [
      'mcko-teacher',
      'mcko-teacher-out',
      'mcko-out',
      'foxford-meta-m',
      'foxford-meta-h',
      'a',
      'a-kz',
      'ednav',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv',
      'digitaleconomics',
      'managercupfinal',
      'managercuptest',
      'managercuptest-sf',
      'tgu',
    ],
    only_meta_radar: [
      'mcko-teacher',
      'managercuptest-sf',
      'a',
      'a-kz',
      'ednav',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'digitaleconomics',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv',
      'tgu',
    ],
    additional_text_receivers: [
      'a',
      'a-kz',
      'ednav',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'digitaleconomics',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv',
      'tgu',
    ]
  },
  'result-skills-6': {
    show_skills_radar: [],
    only_skills_radar: [ 'mcko' ],
    hide_meta_on_skills_radar: [ 'mcko' ],
    show_meta_radar: [
      'mcko-teacher',
      'mcko-teacher-out',
      'mcko-out',
      'foxford-meta-m',
      'foxford-meta-h',
      'a',
      'a-kz',
      'ednav',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv',
      'digitaleconomics',
      'managercupfinal'
    ],
    only_meta_radar: [
      'mcko-teacher',
      'a',
      'a-kz',
      'ednav',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'digitaleconomics',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv'
    ],
    additional_text_receivers: [
      'a',
      'a-kz',
      'ednav',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'digitaleconomics',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv'
    ]
  },
  'result-tiji': {
    show_skills_radar: [],
    only_skills_radar: [],
    hide_meta_on_skills_radar: [],
    show_meta_radar: [],
    only_meta_radar: [],
    additional_text_receivers: [
      // 'tiji'
    ]
  },
  'result-skills-out': {
    additional_text_receivers: [],
  },
  'result-skills-ikigai-out': {
    additional_text_receivers: [],
  },
  'result-skills-school-subject': {
    show_skills_radar: [],
    only_skills_radar: [],
    additional_text_receivers: [
      'my-skills-social-science',
      'my-skills-computer-science',
      'my-skills-english-language',
      'my-skills-russian-language',
      'my-skills-biology',
      'my-skills-algebra',
    ]
  },
  // FIXME: this result is bad practice
  'result-soft-skills-ad': {
    subtitle_texts: { // TODO: refactor this...
      ad1: [
        `Есть очень много навыков, которые помогают тебе общаться с друзьями и родителями,
        понимать мир вокруг, создавать что-то новое. Давай узнаем немного о тебе?`,
      ],
      ad2: [
        `Существует множество навыков, которые помогают тебе общаться с людьми, понимать
        окружающий мир, создавать что-то новое. Давай узнаем о тебе немного больше?`,
      ],
      ad3: [
        `В современном мире есть навыки, которые очень важны для жизни в обществе, работы,
        самореализации. Давай посмотрим, что наиболее ярко проявляется у тебя.`
      ],
      'ad1-second': [
        `Есть очень много навыков, которые помогают тебе общаться с друзьями и родителями,
        понимать мир вокруг, создавать что-то новое. Давай узнаем немного о тебе?`,
      ],
      'ad2-second': [
        `Существует множество навыков, которые помогают тебе общаться с людьми, понимать
        окружающий мир, создавать что-то новое. Давай узнаем о тебе немного больше?`,
      ],
      'ad3-second': [
        `В современном мире есть навыки, которые очень важны для жизни в обществе, работы,
        самореализации. Давай посмотрим, что наиболее ярко проявляется у тебя.`
      ],
      'ad3-second-out': [
        `В современном мире есть навыки, которые очень важны для жизни в обществе, работы,
        самореализации. Давай посмотрим, что наиболее ярко проявляется у тебя.`
      ],
      'foxford-m-1-4': [
        `Есть очень много навыков, которые помогают тебе общаться с друзьями и родителями,
        понимать мир вокруг, создавать что-то новое. Давай узнаем немного о тебе?`,
      ],

    },
    description_texts: { // TODO: refactor this
      ad1: {
        communication: [
          `общаешься с окружающими тебя людьми. Это называется коммуникацией. Ты
          легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом
          ты помнишь о правилах этикета и стараешься быть вежливым со всеми.`,
        ],
        adaptive: [
          `принимаешь новые правила игры. Это называется адаптивностью. Новые условия
          и требования тебя не пугают, ты легко приспособишься ко всему, что происходит
          вокруг.`,
        ],
        research: [
          `исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят,
          когда тебе предлагают интересную задачу. Ты знаешь множество способов изучить
          этот мир и готов(а) предложить новые решения вопросов.`,
        ],
        eq: [
          `понимаешь других людей. Этот важный навык называется эмоциональным
          интеллектом. Ты понимаешь, чего хотят окружающие, или какое настроение у
          твоего друга. И сам(а) ты легко управляешь своими эмоциями.`,
        ],
        it: [
          `работаешь с информацией. Нужно что-то узнать? Ты это сделаешь лучше всех! Ты
          найдёшь новую информацию и представишь её так, что она станет понятна другим.`,
        ],
        creative: [
          `генерируешь творческие идеи. Это называется креативностью. Ты легко ищешь
          новые подходы в решении нестандартных задач и создаешь что-то новое.`,
        ],
        'health-saving': [
          `заботишься о себе. Этот полезный навык называется здоровьесбережение. Ты
          помнишь о правилах личной гигиены, заботишься о своем здоровье, а если что-то
          случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'team-work': [
          `работаешь в команде. Ты умеешь работать так, что команда приходит к победе.
          Ты хорошо понимаешь, что должен делать каждый член команды, чтобы ваш
          результат был самым лучшим.`,
        ],
        'critical-thinking': [
          `критически мыслишь. Ты внимательно относишься к информации. Её очень
          много вокруг, но ты всегда подумаешь о том, можно ли доверять тому ли иному
          источнику. Ты умеешь делать выводы из полученной информации и применяешь
          их в дальнейшем.`,
        ],
      },
      'ad1-2021': {
        communication: [
          `общаешься с окружающими тебя людьми. Это называется коммуникацией. Ты
          легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом
          ты помнишь о правилах этикета и стараешься быть вежливым со всеми.`,
        ],
        adaptive: [
          `принимаешь новые правила игры. Это называется адаптивностью. Новые условия
          и требования тебя не пугают, ты легко приспособишься ко всему, что происходит
          вокруг.`,
        ],
        research: [
          `исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят,
          когда тебе предлагают интересную задачу. Ты знаешь множество способов изучить
          этот мир и готов(а) предложить новые решения вопросов.`,
        ],
        eq: [
          `понимаешь других людей. Этот важный навык называется эмоциональным
          интеллектом. Ты понимаешь, чего хотят окружающие, или какое настроение у
          твоего друга. И сам(а) ты легко управляешь своими эмоциями.`,
        ],
        it: [
          `работаешь с информацией. Нужно что-то узнать? Ты это сделаешь лучше всех! Ты
          найдёшь новую информацию и представишь её так, что она станет понятна другим.`,
        ],
        creative: [
          `генерируешь творческие идеи. Это называется креативностью. Ты легко ищешь
          новые подходы в решении нестандартных задач и создаешь что-то новое.`,
        ],
        'health-saving': [
          `заботишься о себе. Этот полезный навык называется здоровьесбережение. Ты
          помнишь о правилах личной гигиены, заботишься о своем здоровье, а если что-то
          случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'team-work': [
          `работаешь в команде. Ты умеешь работать так, что команда приходит к победе.
          Ты хорошо понимаешь, что должен делать каждый член команды, чтобы ваш
          результат был самым лучшим.`,
        ],
        'critical-thinking': [
          `критически мыслишь. Ты внимательно относишься к информации. Её очень
          много вокруг, но ты всегда подумаешь о том, можно ли доверять тому ли иному
          источнику. Ты умеешь делать выводы из полученной информации и применяешь
          их в дальнейшем.`,
        ],
      },
      'ad1-second': {
        adaptive: [
          `Ты умеешь принимать новые правила игры. Это называется адаптивностью. Новые условия и требования тебя не пугают, ты легко приспособишься ко всему, что происходит вокруг.`,
        ],
        'health-saving': [
          `Ты знаешь, как заботиться о себе. Этот полезный навык называется здоровьесбережение. Ты помнишь о правилах личной гигиены, заботишься о своем здоровье. А если что-то случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'it': [
          'Ты легко находишь нужную тебе информацию – в интернете, или через знакомых взрослых. И, что очень важно, ты знаешь – как эту информацию применить в жизни.'
        ],
        'research': [
          'Ты с любопытством исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят, когда тебе предлагают узнать что-то неизвестное. Ты знаешь множество способов изучить этот мир, понять что, почему и как.'
        ],
        'critical-thinking': [
          'Ты умеешь мыслить трезво и четко. Всегда собираешь достаточно информации и фактов, и только потом принимаешь решение. Любая идея должна быть проверена.'
        ],
        'creative': [
          'Твои идеи порой внезапны и своеобразны, как сова в костюме единорога, сидящая на спине дельфина. Чтобы решить проблему ты ставишь мир с ног на голову, и находишь неожиданное, но эффективное решение.'
        ],
        'communication': [
          'Коммуникация – это означает, что ты умеешь общаться с окружающими тебя людьми. Ты легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом ты помнишь о правилах этикета и стараешься быть вежливым. '
        ],
        'team-work': [
          'Работаешь в команде. Ты умеешь договариваться с другими так, что команда приходит к победе. Ты хорошо понимаешь, что должен делать каждый, чтобы ваш результат был самым лучшим. '
        ],
        'eq': [
          'Ты понимаешь, чего хотят окружающие, или какое настроение у твоего друга. И сам(а) ты легко управляешь своими эмоциями. Ты умеешь понимать настроение своих другзей. И свои эмоции ты тоже хорошо чувствуешь и знаешь, как о них рассказать.'
        ]
      },
      ad2: {
        communication: [
          `общаешься с окружающими тебя людьми. Это называется коммуникацией. Ты
          легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом
          ты помнишь о правилах этикета и стараешься быть вежливым со всеми.`,
        ],
        adaptive: [
          `принимаешь новые правила игры. Это называется адаптивностью. Новые условия
          и требования тебя не пугают, ты легко приспособишься ко всему, что происходит
          вокруг.`,
        ],
        research: [
          `исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят,
          когда тебе предлагают интересную задачу. Ты знаешь множество способов изучить
          этот мир и готов(а) предложить новые решения вопросов.`,
        ],
        eq: [
          `понимаешь других людей. Этот важный навык называется эмоциональным
          интеллектом. Ты понимаешь, чего хотят окружающие, или какое настроение у
          твоего друга. И сам(а) ты легко управляешь своими эмоциями.`,
        ],
        it: [
          `работаешь с информацией. Нужно что-то узнать? Ты это сделаешь лучше всех! Ты
          найдёшь новую информацию и представишь её так, что она станет понятна другим.`,
        ],
        creative: [
          `генерируешь творческие идеи. Это называется креативностью. Ты легко ищешь
          новые подходы в решении нестандартных задач и создаешь что-то новое.`,
        ],
        'health-saving': [
          `заботишься о себе. Этот полезный навык называется здоровьесбережение. Ты
          помнишь о правилах личной гигиены, заботишься о своем здоровье, а если что-то
          случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'team-work': [
          `работаешь в команде. Ты умеешь работать так, что команда приходит к победе.
          Ты хорошо понимаешь, что должен делать каждый член команды, чтобы ваш
          результат был самым лучшим.`,
        ],
        'critical-thinking': [
          `критически мыслишь. Ты внимательно относишься к информации. Её очень
          много вокруг, но ты всегда подумаешь о том, можно ли доверять тому ли иному
          источнику. Ты умеешь делать выводы из полученной информации и применяешь
          их в дальнейшем.`,
        ],
      },
      'ad2-2021': {
        communication: [
          `общаешься с окружающими тебя людьми. Это называется коммуникацией. Ты
          легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом
          ты помнишь о правилах этикета и стараешься быть вежливым со всеми.`,
        ],
        adaptive: [
          `принимаешь новые правила игры. Это называется адаптивностью. Новые условия
          и требования тебя не пугают, ты легко приспособишься ко всему, что происходит
          вокруг.`,
        ],
        research: [
          `исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят,
          когда тебе предлагают интересную задачу. Ты знаешь множество способов изучить
          этот мир и готов(а) предложить новые решения вопросов.`,
        ],
        eq: [
          `понимаешь других людей. Этот важный навык называется эмоциональным
          интеллектом. Ты понимаешь, чего хотят окружающие, или какое настроение у
          твоего друга. И сам(а) ты легко управляешь своими эмоциями.`,
        ],
        it: [
          `работаешь с информацией. Нужно что-то узнать? Ты это сделаешь лучше всех! Ты
          найдёшь новую информацию и представишь её так, что она станет понятна другим.`,
        ],
        creative: [
          `генерируешь творческие идеи. Это называется креативностью. Ты легко ищешь
          новые подходы в решении нестандартных задач и создаешь что-то новое.`,
        ],
        'health-saving': [
          `заботишься о себе. Этот полезный навык называется здоровьесбережение. Ты
          помнишь о правилах личной гигиены, заботишься о своем здоровье, а если что-то
          случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'team-work': [
          `работаешь в команде. Ты умеешь работать так, что команда приходит к победе.
          Ты хорошо понимаешь, что должен делать каждый член команды, чтобы ваш
          результат был самым лучшим.`,
        ],
        'critical-thinking': [
          `критически мыслишь. Ты внимательно относишься к информации. Её очень
          много вокруг, но ты всегда подумаешь о том, можно ли доверять тому ли иному
          источнику. Ты умеешь делать выводы из полученной информации и применяешь
          их в дальнейшем.`,
        ],
      },
      'ad2-second': {
        adaptive: [
          `Ты умеешь принимать новые правила игры. Это называется адаптивностью. Новые условия и требования тебя не пугают, ты легко приспособишься ко всему, что происходит вокруг.`,
        ],
        'health-saving': [
          `Ты знаешь, как заботиться о себе. Этот полезный навык называется здоровьесбережение. Ты помнишь о правилах личной гигиены, заботишься о своем здоровье. А если что-то случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'it': [
          'Ты легко находишь нужную тебе информацию – неважно, в интернете, или через знакомых взрослых. И, что очень важно, ты знаешь – как эту информацию применить в жизни.'
        ],
        'research': [
          'Ты с любопытством исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят, когда тебе предлагают интересную задачу. Ты знаешь множество способов изучить этот мир и готов(а) предложить новые решения вопросов.'
        ],
        'critical-thinking': [
          'Ты умеешь мыслить трезво и четко. Всегда собираешь достаточно информации и фактов, и только потом принимаешь решение. Любая идея должна быть проверена на надежность, эффективность и соответствие твоим внутренним (или принятым в компании, в школе) стандартам.'
        ],
        'creative': [
          'Твои идеи порой внезапны и своеобразны, как сова в костюме единорога, сидящая на спине дельфина. Чтобы решить проблему ты ставишь мир с ног на голову, и находишь неожиданное, но эффективное решение.'
        ],
        'communication': [
          'Коммуникация – это означает, что ты умеешь общаться с окружающими тебя людьми. Ты легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом ты помнишь о правилах этикета и стараешься быть безупречно вежливым.'
        ],
        'team-work': [
          'Работаешь в команде. Ты умеешь договариваться с людьми. Зачастую именно ты становишься центром принятия решений в команде, понимаешь как нужно действовать.  Это отчасти похоже на работу, например, тренера спортивной команды – ты получаешь и обрабатываешь информацию об игроках, вырабатываешь стратегию, и рассказываешь – кто что должен делать.'
        ],
        'eq': [
          'Тебе легко понять – что чувствует прямо сейчас твой собеседник, что он имеет в виду. Ты знаешь – как реагировать на разное эмоциональное состояние другого человека. Также ты прекрасно понимаешь, что чувствуешь сам(а), и что с этим можно делать. Знаешь, как в игре The Sims, для того, чтобы прийти в определенное настроение, персонаж может совершать определенные действия – принять душ, послушать музыку, пробежаться. Ты точно так же знаешь – как прийти к какому-то необходимому состоянию.'
        ]
      },
      ad3: {
        communication: [
          `общаешься с окружающими тебя людьми. Это называется коммуникацией. Ты
          легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом
          ты помнишь о правилах этикета и стараешься быть вежливым со всеми.`,
        ],
        adaptive: [
          `принимаешь новые правила игры. Это называется адаптивностью. Новые условия
          и требования тебя не пугают, ты легко приспособишься ко всему, что происходит
          вокруг.`,
        ],
        research: [
          `исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят,
          когда тебе предлагают интересную задачу. Ты знаешь множество способов изучить
          этот мир и готов(а) предложить новые решения вопросов.`,
        ],
        eq: [
          `понимаешь других людей. Этот важный навык называется эмоциональным
          интеллектом. Ты понимаешь, чего хотят окружающие, или какое настроение у
          твоего друга. И сам(а) ты легко управляешь своими эмоциями.`,
        ],
        it: [
          `работаешь с информацией. Нужно что-то узнать? Ты это сделаешь лучше всех! Ты
          найдёшь новую информацию и представишь её так, что она станет понятна другим.`,
        ],
        creative: [
          `генерируешь творческие идеи. Это называется креативностью. Ты легко ищешь
          новые подходы в решении нестандартных задач и создаешь что-то новое.`,
        ],
        'health-saving': [
          `заботишься о себе. Этот полезный навык называется здоровьесбережение. Ты
          помнишь о правилах личной гигиены, заботишься о своем здоровье, а если что-то
          случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'team-work': [
          `работаешь в команде. Ты умеешь работать так, что команда приходит к победе.
          Ты хорошо понимаешь, что должен делать каждый член команды, чтобы ваш
          результат был самым лучшим.`,
        ],
        'critical-thinking': [
          `критически мыслишь. Ты внимательно относишься к информации. Её очень
          много вокруг, но ты всегда подумаешь о том, можно ли доверять тому ли иному
          источнику. Ты умеешь делать выводы из полученной информации и применяешь
          их в дальнейшем.`,
        ],
      },
      'ad3-2021': {
        communication: [
          `общаешься с окружающими тебя людьми. Это называется коммуникацией. Ты
          легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом
          ты помнишь о правилах этикета и стараешься быть вежливым со всеми.`,
        ],
        adaptive: [
          `принимаешь новые правила игры. Это называется адаптивностью. Новые условия
          и требования тебя не пугают, ты легко приспособишься ко всему, что происходит
          вокруг.`,
        ],
        research: [
          `исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят,
          когда тебе предлагают интересную задачу. Ты знаешь множество способов изучить
          этот мир и готов(а) предложить новые решения вопросов.`,
        ],
        eq: [
          `понимаешь других людей. Этот важный навык называется эмоциональным
          интеллектом. Ты понимаешь, чего хотят окружающие, или какое настроение у
          твоего друга. И сам(а) ты легко управляешь своими эмоциями.`,
        ],
        it: [
          `работаешь с информацией. Нужно что-то узнать? Ты это сделаешь лучше всех! Ты
          найдёшь новую информацию и представишь её так, что она станет понятна другим.`,
        ],
        creative: [
          `генерируешь творческие идеи. Это называется креативностью. Ты легко ищешь
          новые подходы в решении нестандартных задач и создаешь что-то новое.`,
        ],
        'health-saving': [
          `заботишься о себе. Этот полезный навык называется здоровьесбережение. Ты
          помнишь о правилах личной гигиены, заботишься о своем здоровье, а если что-то
          случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'team-work': [
          `работаешь в команде. Ты умеешь работать так, что команда приходит к победе.
          Ты хорошо понимаешь, что должен делать каждый член команды, чтобы ваш
          результат был самым лучшим.`,
        ],
        'critical-thinking': [
          `критически мыслишь. Ты внимательно относишься к информации. Её очень
          много вокруг, но ты всегда подумаешь о том, можно ли доверять тому ли иному
          источнику. Ты умеешь делать выводы из полученной информации и применяешь
          их в дальнейшем.`,
        ],
      },
      'ad3-second': {
        adaptive: [
          `Ты умеешь принимать новые правила игры. Если ситуация неожиданно меняется, ты можешь быстро сориентировать и понять, что теперь нужно делать. Это называется адаптивностью. Новые условия и требования тебя не пугают, ты легко приспособишься ко всему, что происходит вокруг.`,
        ],
        'health-saving': [
          `Ты знаешь, как заботиться о себе. Этот полезный навык называется здоровьесбережение. Ты заботишься о своем здоровье, помнишь о правилах личной гигиены. А если что-то случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'it': [
          'Ты легко находишь нужную тебе информацию – неважно, в интернете, или через знакомых взрослых. И, что очень важно, ты знаешь – как эту информацию применить в жизни.'
        ],
        'research': [
          'Ты с любопытством исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят, когда тебе предлагают интересную задачу. Ты знаешь множество способов изучить этот мир и готов(а) предложить новые решения вопросов.'
        ],
        'critical-thinking': [
          'Ты умеешь мыслить трезво и четко. Всегда собираешь достаточно информации и фактов, и только потом принимаешь решение. Любая идея должна быть проверена на надежность, эффективность и соответствие твоим внутренним (или принятым в компании, в школе) стандартам.'
        ],
        'creative': [
          'Твои идеи порой внезапны и своеобразны, как сова в костюме единорога, сидящая на спине дельфина. Чтобы решить проблему ты ставишь мир с ног на голову, и находишь неожиданное, но эффективное решение.'
        ],
        'communication': [
          'Коммуникация – это означает, что ты умеешь общаться с окружающими тебя людьми. Ты легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом ты помнишь о правилах этикета и стараешься быть вежливым и внимательным к окружающим.'
        ],
        'team-work': [
          'Работаешь в команде. Ты умеешь договариваться с людьми. Зачастую именно ты становишься центром принятия решений в команде, понимаешь как нужно действовать. Быстро собираешь, структурируешь информацию и объясняешь всем – как нужно действовать, чтобы достичь успеха. Это отчасти похоже на работу, например, тренера спортивной команды – ты получаешь, отбираешь и обрабатываешь информацию об игроках, вырабатываешь стратегию, и рассказываешь – кто что должен делать.'
        ],
        'eq': [
          'Тебе легко понять – что чувствует прямо сейчас твой собеседник, что он имеет в виду, когда говорит или делает что-то. И ты знаешь – как реагировать на разное эмоциональное состояние другого человека. Также ты прекрасно понимаешь, что чувствуешь сам(а), и что с этим можно делать. Знаешь, как в игре The Sims 4, для того, чтобы прийти в определенное настроение, персонаж может совершать определенные действия – принять душ, послушать музыку, пробежаться. Ты точно так же знаешь – как прийти к какому-то необходимому состоянию.'
        ]
      },
      'ad3-second-out': {
        adaptive: [
          `Ты умеешь принимать новые правила игры. Если ситуация неожиданно меняется, ты можешь быстро сориентировать и понять, что теперь нужно делать. Это называется адаптивностью. Новые условия и требования тебя не пугают, ты легко приспособишься ко всему, что происходит вокруг.`,
        ],
        'health-saving': [
          `Ты знаешь, как заботиться о себе. Этот полезный навык называется здоровьесбережение. Ты заботишься о своем здоровье, помнишь о правилах личной гигиены. А если что-то случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'it': [
          'Ты легко находишь нужную тебе информацию – неважно, в интернете, или через знакомых взрослых. И, что очень важно, ты знаешь – как эту информацию применить в жизни.'
        ],
        'research': [
          'Ты с любопытством исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят, когда тебе предлагают интересную задачу. Ты знаешь множество способов изучить этот мир и готов(а) предложить новые решения вопросов.'
        ],
        'critical-thinking': [
          'Ты умеешь мыслить трезво и четко. Всегда собираешь достаточно информации и фактов, и только потом принимаешь решение. Любая идея должна быть проверена на надежность, эффективность и соответствие твоим внутренним (или принятым в компании, в школе) стандартам.'
        ],
        'creative': [
          'Твои идеи порой внезапны и своеобразны, как сова в костюме единорога, сидящая на спине дельфина. Чтобы решить проблему ты ставишь мир с ног на голову, и находишь неожиданное, но эффективное решение.'
        ],
        'communication': [
          'Коммуникация – это означает, что ты умеешь общаться с окружающими тебя людьми. Ты легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом ты помнишь о правилах этикета и стараешься быть вежливым и внимательным к окружающим.'
        ],
        'team-work': [
          'Работаешь в команде. Ты умеешь договариваться с людьми. Зачастую именно ты становишься центром принятия решений в команде, понимаешь как нужно действовать. Быстро собираешь, структурируешь информацию и объясняешь всем – как нужно действовать, чтобы достичь успеха. Это отчасти похоже на работу, например, тренера спортивной команды – ты получаешь, отбираешь и обрабатываешь информацию об игроках, вырабатываешь стратегию, и рассказываешь – кто что должен делать.'
        ],
        'eq': [
          'Тебе легко понять – что чувствует прямо сейчас твой собеседник, что он имеет в виду, когда говорит или делает что-то. И ты знаешь – как реагировать на разное эмоциональное состояние другого человека. Также ты прекрасно понимаешь, что чувствуешь сам(а), и что с этим можно делать. Знаешь, как в игре The Sims 4, для того, чтобы прийти в определенное настроение, персонаж может совершать определенные действия – принять душ, послушать музыку, пробежаться. Ты точно так же знаешь – как прийти к какому-то необходимому состоянию.'
        ]
      },
      'foxford-m-1-4': {
        adaptive: [
          `Ты умеешь принимать новые правила игры. Это называется адаптивностью. Новые условия и требования тебя не пугают, ты легко приспособишься ко всему, что происходит вокруг.`,
        ],
        'health-saving': [
          `Ты знаешь, как заботиться о себе. Этот полезный навык называется здоровьесбережение. Ты помнишь о правилах личной гигиены, заботишься о своем здоровье. А если что-то случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'it': [
          'Ты легко находишь нужную тебе информацию – в интернете, или через знакомых взрослых. И, что очень важно, ты знаешь – как эту информацию применить в жизни.'
        ],
        'research': [
          'Ты с любопытством исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят, когда тебе предлагают узнать что-то неизвестное. Ты знаешь множество способов изучить этот мир, понять что, почему и как.'
        ],
        'critical-thinking': [
          'Ты умеешь мыслить трезво и четко. Всегда собираешь достаточно информации и фактов, и только потом принимаешь решение. Любая идея должна быть проверена.'
        ],
        'creative': [
          'Твои идеи порой внезапны и своеобразны, как сова в костюме единорога, сидящая на спине дельфина. Чтобы решить проблему ты ставишь мир с ног на голову, и находишь неожиданное, но эффективное решение.'
        ],
        'communication': [
          'Коммуникация – это означает, что ты умеешь общаться с окружающими тебя людьми. Ты легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом ты помнишь о правилах этикета и стараешься быть вежливым. '
        ],
        'team-work': [
          'Работаешь в команде. Ты умеешь договариваться с другими так, что команда приходит к победе. Ты хорошо понимаешь, что должен делать каждый, чтобы ваш результат был самым лучшим. '
        ],
        'eq': [
          'Ты понимаешь, чего хотят окружающие, или какое настроение у твоего друга. И сам(а) ты легко управляешь своими эмоциями. Ты умеешь понимать настроение своих другзей. И свои эмоции ты тоже хорошо чувствуешь и знаешь, как о них рассказать.'
        ]
      },
    },

    additional_text_receivers: [
      'ad1',
      'ad2',
      'ad3',
      'ad1-2021',
      'ad2-2021',
      'ad3-2021',
      'ad1-second',
      'ad2-second',
      'ad3-second',
      'ad3-second-out',
      'foxford-m-1-4'
    ],
    no_pdf: [
      'foxford-m-1-4'
    ]
  },
  'result-education-profiles-ad': {
    additional_text_receivers: [
      'ad1',
      'ad2',
      'ad3',
      'ad1-2021',
      'ad2-2021',
      'ad3-2021'
    ],
  },
  'result-soft-skills-out': {
    subtitle_texts: { // TODO: refactor this...
      'ad3-second-out': [
        `В современном мире есть навыки, которые очень важны для жизни в обществе, работы,
        самореализации. Давай посмотрим, что наиболее ярко проявляется у тебя.`
      ]
    },
    description_texts: { // TODO: refactor this
      'ad3-second-out': {
        adaptive: [
          `Ты умеешь принимать новые правила игры. Если ситуация неожиданно меняется, ты можешь быстро сориентировать и понять, что теперь нужно делать. Это называется адаптивностью. Новые условия и требования тебя не пугают, ты легко приспособишься ко всему, что происходит вокруг.`,
        ],
        'health-saving': [
          `Ты знаешь, как заботиться о себе. Этот полезный навык называется здоровьесбережение. Ты заботишься о своем здоровье, помнишь о правилах личной гигиены. А если что-то случается, ты всегда знаешь, что нужно поговорить с родителями и посетить врача.`,
        ],
        'it': [
          'Ты легко находишь нужную тебе информацию – неважно, в интернете, или через знакомых взрослых. И, что очень важно, ты знаешь – как эту информацию применить в жизни.'
        ],
        'research': [
          'Ты с любопытством исследуешь этот мир. Тебе нравится учиться чему-то новому. Твои глаза горят, когда тебе предлагают интересную задачу. Ты знаешь множество способов изучить этот мир и готов(а) предложить новые решения вопросов.'
        ],
        'critical-thinking': [
          'Ты умеешь мыслить трезво и четко. Всегда собираешь достаточно информации и фактов, и только потом принимаешь решение. Любая идея должна быть проверена на надежность, эффективность и соответствие твоим внутренним (или принятым в компании, в школе) стандартам.'
        ],
        'creative': [
          'Твои идеи порой внезапны и своеобразны, как сова в костюме единорога, сидящая на спине дельфина. Чтобы решить проблему ты ставишь мир с ног на голову, и находишь неожиданное, но эффективное решение.'
        ],
        'communication': [
          'Коммуникация – это означает, что ты умеешь общаться с окружающими тебя людьми. Ты легко знакомишься, а новые люди быстро становятся твоими друзьями. При этом ты помнишь о правилах этикета и стараешься быть вежливым и внимательным к окружающим.'
        ],
        'team-work': [
          'Работаешь в команде. Ты умеешь договариваться с людьми. Зачастую именно ты становишься центром принятия решений в команде, понимаешь как нужно действовать. Быстро собираешь, структурируешь информацию и объясняешь всем – как нужно действовать, чтобы достичь успеха. Это отчасти похоже на работу, например, тренера спортивной команды – ты получаешь, отбираешь и обрабатываешь информацию об игроках, вырабатываешь стратегию, и рассказываешь – кто что должен делать.'
        ],
        'eq': [
          'Тебе легко понять – что чувствует прямо сейчас твой собеседник, что он имеет в виду, когда говорит или делает что-то. И ты знаешь – как реагировать на разное эмоциональное состояние другого человека. Также ты прекрасно понимаешь, что чувствуешь сам(а), и что с этим можно делать. Знаешь, как в игре The Sims 4, для того, чтобы прийти в определенное настроение, персонаж может совершать определенные действия – принять душ, послушать музыку, пробежаться. Ты точно так же знаешь – как прийти к какому-то необходимому состоянию.'
        ]
      },
    },

    additional_text_receivers: [
      'ad3-second-out',
    ],
  },
  'result-meta-skills': {
    additional_text_receivers: [
      'a',
      'a-kz',
      'ednav',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'digitaleconomics',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv'
    ]
  },
  'result-meta-skills-loc': {
    additional_text_receivers: [
      'a',
      'a-kz',
      'ednav',
      'demo',
      'a-clone',
      'ey',
      'cognition',
      'reanimation',
      'reanimation-out',
      'cv21',
      'bcpd',
      'tbspb',
      'naos',
      'neva',
      'digitaleconomics',
      'ey-clone',
      'sts',
      'mts',
      'ma',
      'tolkacheva-sv'
    ]
  },
  'result-meta-mult': {
    additional_text_receivers: [
      'leader-1-4',
      'school-1-4'
    ],
  },
  'result-meta-out': {
    additional_text_receivers: [],
  },
  'result-involvement': {
    maxInvolvementGroups: {
      100: [ 'default' ],
      50: [ 'smart' ]
    }
  },
  'result-secondary-abilities': {
    additional_pdf_receivers: [
      'cr-eq',
    ],
  },
  'result-seven-competence': {
    additional_pdf_receivers: []
  },
  'result-visual-leader-profile': {
    additional_pdf_receivers: []
  }

})

const mapProjectData = configMapData({
  project_languages_masks,
  show_languages_on,
  show_integrations_header_on,
  multiple_completions_projects,
  show_prev_results_projects,
  components_config,
  pages_config,
  tests_config,
  roles_config,
  signup_config,
  results_config,
})


export {
  mapProjectData,
  project_languages_masks,
  show_languages_on,
  show_integrations_header_on,
  multiple_completions_projects,
  show_prev_results_projects,
  components_config,
  pages_config,
  tests_config,
  roles_config,
  signup_config,
  results_config,
}

/**
 * @param {Object} store - data store object for the lookup
 * @returns {configMapData~mapProjectData} - the returned factory function, object generator
 */
function configMapData (data) {
  /**
   * @const {Object} _data - frozen object with any data
   */
  const _data = Object.freeze(data)

  /**
   *
   * # Inner function, returned from factory
   *
   * ## caller usage:
   *   where string arguments mean inner props lookup,
   *   and arrays - saving group of props on same level
   *   if empty array - save all props from object
   *
   * ```js
   * mapProjectData('prop') - returns object with one prop {`prop`: store.prop}
   * mapProjectData(['prop1', 'prop2']) - returns object with two props
   *   {prop1: store.prop1, prop2: store.prop2}
   * mapProjectData(['prop1', 'prop2', 'prop3']) - returns same as previous but three props
   * mapProjectData('prop', []) - returns all object from store.prop with their own names
   *   ! not good practice!
   * mapProjectData('prop', ['subprop']) - access store.prop, and returns object with
   *   one prop from there: {subprop: store.prop.subprop}
   * mapProjectData('prop', ['subprop1', 'subprop2']) - access store.prop, and returns
   *   object with two props from there:
   *   {subprop1: store.prop.subprop1, subprop2: store.prop.subprop2}
   * mapProjectData('prop', 'subprop', ['subsubprop1', 'subsubprop2']) - access
   *   store.prop.subprop, and returns object with two props from there:
   *   {subsubprop1: store.prop.subprop.subsubprop1, subsubprop2: store.prop.subprop.subsbuprop2}
   * mapProjectData('prop', 'subprop', 'subsubprop') - returns object with one prop:
   *   {subsubprop: store.prop.subprop.subsubprop}
   *```
   *
   * @param {...(string|string[])} restMappings - property lookup string or properties array
   * @returns {Object} freezed object with selected properties & found values
   *
   */

  return (function mapProjectData (...restParams) {
    const firstParam = restParams.splice(0, 1)[0]
    const gotMoreParams = restParams && restParams.length

    switch (typeof firstParam) {
    case 'object':
      if (firstParam instanceof Array) {
        const propertiesList = firstParam
        if (propertiesList.length) {
          // return selected props from obj
          return propertiesList.reduce((acc, property) => {
            acc[property] = this[property]
            return acc
          }, {})
        } else {
          // return all props from obj
          return this
        }
      } else if (firstParam instanceof Object) {
        console.warn('Not implemented for Objects!')
      }
      break

    case 'string':
      const accessedProp = this[firstParam]
      if (gotMoreParams) {
        if (accessedProp instanceof Object &&
            typeof accessedProp !== 'array') {
          return mapProjectData.bind(accessedProp)(...restParams)
        } else console.warn(`Wrong lookup - property ${firstParam} is Array, but expected Object`)
      } else return Object.freeze({ [firstParam]: accessedProp })
      break
    default:
      console.warn('Not implemented for this params type!')
    }
  }).bind(_data)
}
