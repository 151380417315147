// eslint-disable-next-line no-unused-vars
/* global router */
/* eslint-disable no-multi-spaces */

import _ from 'lodash'
import Vue from 'vue'
import axios from 'axios'
import CaseidApi from '@/services/CaseidApi'
import UntiApi from '@/services/UntiApi'
import TestDataApi from '@/services/TestDataApi'
import getBoundProjectNames from '@/functions/getBoundProjectNames'
// import { SAVE_RESULT } from '@/store/action-types'

import {
  SET_CALLBACK_URL,
  REMOVE_CALLBACK_URL,
  SET_CALLBACK_XHR_REQ,
  SET_TOKEN,
  REMOVE_TOKEN,
  RESET_ALL_TOKENS,
  SET_INTEGRATION,
  REMOVE_INTEGRATION,
  SET_ACTIVITY_UUID,
  REMOVE_ACTIVITY_UUID,
  SET_TOKEN_PAIR,
  REMOVE_TOKEN_PAIR,
  SET_RAN_TOKEN,
  REMOVE_RAN_TOKEN,
  SET_CODE,
  REMOVE_CODE,
  SET_REDIRECT_URL,
  REMOVE_REDIRECT_URL,
  UPDATE_LAST_TR_CHECK_TIME,
} from '../mutation-types'

import * as A from '../action-types'

// import tempApi from '../../temp-assets/api.js'


export const namespaced = true


export const state = {
  // Common integration
  tokens: {},
  // Custom integration params (used by new Big Skillfolio)
  integration: {},

  // ASI & old Big Skillfolio integration token (name & value divided by @)
  token_pair: { name: '', value: '' },
  // MYSKILLS integration (tripleDES code)
  code: '',
  // RAN integration token
  ran_token: '',
  // UNTI integration
  activity_uuid: '',

  // Other stuff...
  callback_url: '',
  callback_xhr_req: false,
  redirect_url: '',
  last_tr_check_time: new Date(0),
}


export const getters = {
  skillfolio_api_loaded: () => {  // promise
    console.log('↺↺ RECALCULATING skillfolio_api_loaded ↺↺')
    return new Promise(resolve => {
      // console.log('↺↺ skillfolio_api_loaded ↺↺ async code')
      if (window.skillfolioApi._loaded) resolve(true)
      else {
        document.addEventListener('SkillfolioAPIAsyncLoaded', function onSkillfolioApiLoadAtStorage () {
          // console.log('*** onSkillfolioApiLoadAtStorage Storage handler called ***')
          if (window.skillfolioApi._loaded) {
            console.log('*** skillfolio API methods are loaded!')
            document.removeEventListener('SkillfolioAPIAsyncLoaded', onSkillfolioApiLoadAtStorage)
            resolve(true)
          }
        })
      }
    })
  },
}


// hasn't got access to rootState, rootGetter or anything, should be the pure fn
// if such behaviour is needed — better to move it out to the corresponding action
export const mutations = {
  [SET_CALLBACK_URL] (state, cb) {
    state.callback_url = cb
  },

  [REMOVE_CALLBACK_URL] (state) {
    state.callback_url = ''
  },

  [SET_CALLBACK_XHR_REQ] (state, use_xhr) {
    state.callback_xhr_req = use_xhr
  },

  [SET_TOKEN] (state, [ source, token ]) {
    Vue.set(state.tokens, source, token)
  },

  [REMOVE_TOKEN] (state, source) {
    state.tokens[source] = null
  },

  [RESET_ALL_TOKENS] (state) {
    state.tokens = {}
  },

  [SET_INTEGRATION] (state, data) {
    state.integration = data
  },

  [REMOVE_INTEGRATION] (state) {
    state.integration = {}
  },


  [SET_ACTIVITY_UUID] (state, activity) {
    state.activity_uuid = activity
  },

  [REMOVE_ACTIVITY_UUID] (state) {
    state.activity_uuid = ''
  },

  [SET_TOKEN_PAIR] (state, token_pair) {
    const tokenData = token_pair.match(/(.+)@(.+)/i)
    console.log('tokenData', tokenData)
    state.token_pair.name = tokenData[1]
    state.token_pair.value = tokenData[2]
  },

  [REMOVE_TOKEN_PAIR] (state) {
    state.token_pair.name = ''
    state.token_pair.value = ''
  },

  [SET_RAN_TOKEN] (state, ran_token) {
    state.ran_token = ran_token
  },

  [REMOVE_RAN_TOKEN] (state) {
    state.ran_token = ''
  },

  [SET_CODE] (state, code) {
    console.log('received code:', code)
    const savedCode = encodeURIComponent(code)
    console.log('saved code:', savedCode)
    state.code = savedCode
  },

  [REMOVE_CODE] (state) {
    state.code = ''
  },

  [SET_REDIRECT_URL] (state, redirect_url) {
    state.redirect_url = redirect_url
  },

  [REMOVE_REDIRECT_URL] (state) {
    state.redirect_url = ''
  },

  [UPDATE_LAST_TR_CHECK_TIME] (state) {
    state.last_tr_check_time = Date.now()
  },
}


export const actions = {
  [A.SET_CALLBACK_URL] ({ commit }, cb) {
    if (cb === 'null') commit('REMOVE_CALLBACK_URL')
    else commit('SET_CALLBACK_URL', cb)
  },

  [A.REMOVE_CALLBACK_URL] ({ commit }) {
    commit('REMOVE_CALLBACK_URL')
  },

  [A.SET_CALLBACK_XHR_REQ] ({ commit }, use_xhr) {
    let xhr_value = false
    switch (use_xhr) {
    case 'true':
      xhr_value = true
      break
    case 'false':
      xhr_value = false
    }
    commit('SET_CALLBACK_XHR_REQ', xhr_value)
  },

  [A.SET_TOKEN] ({ commit }, [ source, token ]) {
    if (token === 'null') commit('REMOVE_TOKEN', source)
    else commit('SET_TOKEN', [ source, token ])
  },

  [A.REMOVE_TOKEN] ({ commit }, source) {
    commit('REMOVE_TOKEN', source)
  },

  [A.RESET_ALL_TOKENS] ({ commit }) {
    commit('RESET_ALL_TOKENS')
  },

  [A.SET_INTEGRATION] ({ commit }, data) {
    if (_.isEmpty(data)) commit('REMOVE_INTEGRATION')
    else commit('SET_INTEGRATION', data)
  },

  [A.REMOVE_INTEGRATION] ({ commit }, source) {
    commit('REMOVE_INTEGRATION', source)
  },

  [A.SET_ACTIVITY_UUID] ({ commit }, activity) {
    commit('SET_ACTIVITY_UUID', activity)
  },

  [A.REMOVE_ACTIVITY_UUID] ({ commit }) {
    commit('REMOVE_ACTIVITY_UUID')
  },

  [A.START_UNTI_TESTING] () {
    return UntiApi.sendCurrentStart()
  },

  [A.END_UNTI_TESTING] () {
    return UntiApi.sendCurrentEnd()
  },

  [A.SAVE_UNTI_TESTING] (ctx, send_data) {
    return UntiApi.sendCurrentResult(send_data)
  },

  [A.SET_TOKEN_PAIR] ({ commit }, token_pair) {
    if (token_pair === 'null') commit('REMOVE_TOKEN_PAIR')
    else commit('SET_TOKEN_PAIR', token_pair)
  },

  [A.REMOVE_TOKEN_PAIR] ({ commit }) {
    commit('REMOVE_TOKEN_PAIR')
  },

  [A.SET_RAN_TOKEN] ({ commit, dispatch }, ran_token) {
    if (ran_token === 'null') commit('REMOVE_RAN_TOKEN')
    else {
      console.log('ran_token received:', ran_token)
      commit('SET_RAN_TOKEN', ran_token)
      dispatch('allow_multiple_completions', null, { root: true })
      // now Ran added ?user_id param to test, no additional request needed

      // // requesting for user_id in RAN API:
      // axios
      //   .get(`https://showcase-backend.kubedev1.ktsstudio.com/api/diagnostics.user_info?ran_token=${ran_token}`)
      //   .then(response => {
      //     console.log('RAN response with user data received:', response)
      //     if (response && response.data) {
      //       const data = response.data
      //       if (data.status && data.status.toLowerCase() === 'ok') {
      //         const user_data = data.data
      //         if (user_data) {
      //           if (user_data.id != null) {  // GOT USER_ID
      //             dispatch('users/set_user', String(user_data.id), {root: true})
      //             return String(user_data.id)
      //           } else console.warn('RAN API responsed with bad user id:', user_data.id)
      //         } else {
      //           console.warn('RAN API responsed no data:', data)
      //         }
      //       } else {
      //         console.warn('RAN user info API is unavailable, status is:', data.status, 'response data is:', data)
      //       }
      //     } else {
      //       console.warn('RAN API responsed with wrong data:', response)
      //     }
      //   })
      //   .catch(err => console.warn('RAN api responsed with user check error:', err))

      // // TODO: temporary disabled checking of previous completions for RAN
      // .then(uid => {
      //   if (uid != null) {
      //     dispatch(A.SKILLFOLIO_GET_USER_LAST_COMPLETION_HASH)
      //       .then(last_completion_hash => {
      //         console.log('Last result for User id found:', last_completion_hash)
      //         // Not showing results temporary, just redirect to url
      //         if (state.redirect_url) {
      //           // window.location.href = state.redirect_url
      //           const event_id = 'skillfolio_reload_parent'
      //           dispatch(A.INTEGRATION_POST_MESSAGE, {
      //             event_id: event_id,
      //             data: {goToUrl: state.redirect_url}
      //           })
      //         } else {
      //           dispatch('hide_loader', null, {root: true})
      //           router.push({
      //             name: 'results',
      //             params: { skillfolio_id: last_completion_hash }
      //           })
      //         }
      //       })
      //   } else {
      //     console.warn('No user_id, can\'t search for completion!')
      //   }
      // })
    }
  },

  [A.REMOVE_RAN_TOKEN] ({ commit }) {
    commit('REMOVE_RAN_TOKEN')
  },

  async [A.SET_CODE] ({ commit }, code) {
    if (code === 'null') commit('REMOVE_CODE')
    else commit('SET_CODE', code)
  },

  async [A.REMOVE_CODE] ({ commit }) {
    commit('REMOVE_CODE')
  },

  [A.SET_REDIRECT_URL] ({ commit }, redirect_url) {
    if (redirect_url === 'null') commit('REMOVE_REDIRECT_URL')
    else commit('SET_REDIRECT_URL', redirect_url)
  },

  [A.REMOVE_REDIRECT_URL] ({ commit }) {
    commit('REMOVE_REDIRECT_URL')
  },
  [A.RESET_INTEGRATION_PARAMS] ({ dispatch }) {
    return Promise.all([
      dispatch(A.REMOVE_CALLBACK_URL),
      dispatch(A.SET_CALLBACK_XHR_REQ, 'false'),
      dispatch(A.RESET_ALL_TOKENS),
      dispatch(A.REMOVE_ACTIVITY_UUID),
      dispatch(A.REMOVE_INTEGRATION),
      dispatch(A.REMOVE_TOKEN_PAIR),
      dispatch(A.REMOVE_RAN_TOKEN),
      dispatch(A.REMOVE_CODE),
      dispatch(A.REMOVE_REDIRECT_URL)
    ])
  },

  /**
   * Check user access by email on skillfolio API,
   * If it's outer testing - use additional callbacks.
   * But access checking runs only on inner testing database table.
   *
   * @param {object} payload - Object with email, and several callbacks:
   *                           onError(), onDeclined(), onAlreadyPassed()
   *                           ifMissingInnerTesting(),ifAccessGranted()
   * @returns {Promise} Promise, resolved with server response or
   * rejected with an error
   */
  [A.SKILLFOLIO_CHECK_USER_ACCESS] ({ getters, rootState, rootGetters }, payload) {  // uses root state
    const ACCESS = {
      DECLINED: 0,
      APPROVED: 1,
      PASSED: 2,
    }
    return getters.skillfolio_api_loaded
      .then(() => {
        // return Promise.resolve(1)
        if (window.skillfolioApi.checkUserAccess) {
          console.log('going to use API email check method...')
          // checking acces for inner and outer projects always in inner project db table:
          return window.skillfolioApi.checkUserAccess(payload.email, rootState.project_name.replace(/(-out)$/gi, ''))
        } else {
          console.warn('No API method for email check!');
          return Promise.resolve(0)
        }
      })
      .then(response => {
        console.log('response:', response)
        if      (response === ACCESS.DECLINED) payload.onDeclined()
        else if (response === ACCESS.APPROVED && rootGetters.is_outer_testing) payload.ifMissingInnerTesting()  // TODO: hack for '-out' testing logic
        else if (response === ACCESS.APPROVED ||
                 response === ACCESS.PASSED && rootGetters.is_outer_testing) payload.ifAccessGranted()  // TODO: hack for '-out' testing logic
        else if (response === ACCESS.PASSED) payload.onAlreadyPassed() // cb for passed not-outer tests
        return response
      }, err => {
        console.warn('Server error: ' + err)
        payload.onError()
        return Promise.reject(err)
      })
  },

  [A.SKILLFOLIO_GET_PROFESSIONS_LIST] ({ getters }, payload) {
    // const api_url = `http://api.skillfolio.ru/professions/getlist/${payload.skill}/${payload.interest}/`

    return getters.skillfolio_api_loaded
      .then(() => {
        // return Promise.resolve(1)
        if (window.skillfolioApi.getProfessionsList) {
          console.log('going to use API get profession list method...')
          return window.skillfolioApi.getProfessionsList(payload.skill, payload.interest)
        } else {
          console.warn('No API method for profession list recieving !')
          return Promise.resolve(0)
        }
      })
      .then(response => {
        console.log('response:', response)
        return response
      }, err => {
        console.warn('Server error: ' + err)
      })
  },

  [A.SKILLFOLIO_GET_STAT_BY_USER_AND_TEST] ({ getters, rootState }, payload) {
    // const api_url = `http://api.skillfolio.ru/sys/statById/getlist/${payload.user_id}/${payload.test_name}/`

    return getters.skillfolio_api_loaded
      .then(() => {
        // return Promise.resolve(1)
        if (window.skillfolioApi.getStatByUserAndTest) {
          console.log('going to use API get test stat for user method...')
          return window.skillfolioApi.getStatByUserAndTest(payload.user_id, payload.test_name)
        } else {
          console.warn('No API method for test stat recieving for user!')
          return Promise.resolve(0)
        }
      })
      .then(response => {
        console.log('response:', response)
        console.log('payload:', payload)
        return response.filter(r => getBoundProjectNames(payload.project_name).indexOf(r[2]) > -1)
      }, err => {
        console.warn('Server error: ' + err)
      })
  },

  // http://api.skillfolio.ru/ad/getbyprofile/11/socio-pedagogical
  [A.SKILLFOLIO_GET_PROGRAM_BY_EDUCATION_PROFILE] ({ getters }, { education_profile, age }) {
    return getters.skillfolio_api_loaded
      .then(() => {
        return window.skillfolioApi.apiGet(`ad/getbyprofile/${age}/${education_profile}`)
      })
  },
  // http://api.skillfolio.ru/ad/getbyskill/11/it
  [A.SKILLFOLIO_GET_PROGRAM_BY_SOFT_SKILLS] ({ getters }, { soft_skill, age }) {
    return getters.skillfolio_api_loaded
      .then(() => {
        return window.skillfolioApi.apiGet(`ad/getbyskill/${age}/${soft_skill}`)
      })
  },
  // http://api.skillfolio.ru/ad/getbyprofileskill/12/socio-pedagogical/it
  // age, profile, skills => age(profile) + age(skill)
  [A.SKILLFOLIO_GET_PROGRAMS_BY_SKILLS_OR_PROFILE] ({ getters }, { education_profile, soft_skill, age }) {
    return getters.skillfolio_api_loaded
      .then(() => {
        return window.skillfolioApi.apiGet(`ad/getbyprofileskill/${age}/${education_profile}/${soft_skill}`)
      })
  },
  // http://api.skillfolio.ru/ad/getlist/12
  [A.SKILLFOLIO_GET_ALL_PROGRAMS_BY_AGE] ({ getters }, { age }) {
    return getters.skillfolio_api_loaded
      .then(() => {
        return window.skillfolioApi.apiGet(`ad/getlist/${age}`)
      })
  },

  [A.SKILLFOLIO_GET_TEMP_RESULTS] ({ getters, dispatch }, hash) {
    console.log('skillfolio_get_temp_results called')
    return getters.skillfolio_api_loaded.then(() => {
      console.info('got skillfolio API object')
      console.log('looking for temp results with hash:', hash)
      // return tempApi.getTempResult(hash)
      return window.skillfolioApi.getTempResult(hash)
        .then(response => {
          console.log('API response (get) was:', response)
          if (response && response.tr && response._etag) {
            console.log('data received successfully, saving in store...')
            // TODO: save tr and _etag to storage
            dispatch('results/save_temp_results', JSON.parse(JSON.stringify(response.tr)), { root: true })
            dispatch('results/save_temp_results_etag', response._etag, { root: true })
          } else console.warn('Error occured while getting temp result!')
          return response
        })
    })
  },

  [A.SKILLFOLIO_SAVE_TEMP_RESULTS] ({ getters, dispatch }, { results, hash }) {
    return getters.skillfolio_api_loaded.then(() => {
      console.info('got skillfolio API object')
      let send_data = JSON.stringify({
        tr: results,
        _id: hash
      })
      console.log('send_data is:', send_data)
      // return tempApi.saveTempResult(send_data)
      return window.skillfolioApi.saveTempResult(send_data)
        .then(response => {
          console.log('API response (post) was:', response)
          if (response && response._status && response._status.toLowerCase() === 'ok') {
            console.log('data created successfully, creating in store...')
            // TODO: create new tr in storage
            dispatch('results/save_temp_results', results, { root: true })
            dispatch('results/save_temp_results_etag', response._etag, { root: true })
          } else console.warn('Error occured while creating temp result!')
          return response
        })
    })
  },

  [A.SKILLFOLIO_UPDATE_TEMP_RESULTS] ({ getters, dispatch }, { hash, etag, updated_results }) {
    return getters.skillfolio_api_loaded.then(() => {
      console.info('got skillfolio API object')
      let send_data = JSON.stringify({
        tr: updated_results,
      })
      console.log('send_data is:', send_data)
      // return tempApi.updateTempResult(hash, etag, send_data)
      return window.skillfolioApi.updateTempResult(hash, etag, send_data)
        .then(response => {
          console.log('API response (update) was:', response)
          if (response && response._status.toLowerCase() === 'ok') {
            console.log('data updated successfully, rewriting store...')
            // TODO: save new hash and/or etag, and tr to storage
            dispatch('results/save_temp_results', updated_results, { root: true })  // TODO: here i do rewrite, not update...
            dispatch('results/save_temp_results_etag', response._etag, { root: true })
          } else console.warn('Error occured while updating temp result!')
          return response
        })
    })
  },

  [A.SKILLFOLIO_REWRITE_TEMP_RESULTS] ({ getters, dispatch }, { hash, etag, changed_results }) {
    return getters.skillfolio_api_loaded.then(() => {
      console.info('got skillfolio API object')
      let send_data = JSON.stringify({
        tr: changed_results,
      })
      console.log('send_data is:', send_data)
      // return tempApi.updateTempResult(hash, etag, send_data)
      return window.skillfolioApi.rewriteTempResult(hash, etag, send_data)
        .then(response => {
          console.log('API response (update) was:', response)
          if (response && response._status.toLowerCase() === 'ok') {
            console.log('data updated successfully, rewriting store...')
            // TODO: save new hash and/or etag, and tr to storage
            dispatch('results/save_temp_results', changed_results, { root: true })  // TODO: here i do rewrite, not update...
            dispatch('results/save_temp_results_etag', response._etag, { root: true })
          } else console.warn('Error occured while updating temp result!')
          return response
        })
    })
  },

  [A.SKILLFOLIO_DELETE_TEMP_RESULTS] ({ getters, dispatch }, { hash, etag }) {
    return getters.skillfolio_api_loaded.then(() => {
      console.info('got skillfolio API object')
      // return tempApi.deleteTempResult(hash, etag)
      return window.skillfolioApi.deleteTempResult(hash, etag)
        .then(response => {
          console.log('API response (delete) was:', response)
          if (response && response._status.toLowerCase() === 'ok') {
            console.log('data deleted successfully, removing from store...')
            // TODO: remove hash and/or etag, and tr from storage
            dispatch('results/remove_temp_results', null, { root: true })
            dispatch('results/remove_temp_results_etag', null, { root: true })
          } else console.warn('Error occured while deleting temp result!')
          return response
        })
    })
  },

  [A.RETRIEVE_PROGRESS_FROM_TEMP] ({ commit, dispatch, rootGetters }) {
    const user_hash = rootGetters['users/user_hash']
    console.warn('■ ■ Checking temp results for user_hash:', user_hash)
    return dispatch(A.SKILLFOLIO_GET_TEMP_RESULTS, user_hash)
      .then(response => {
        // checking temp results:
        console.log('■ ■ ========== ■ ■')
        console.log('Temp results retrieved:', response.tr)
        commit('UPDATE_LAST_TR_CHECK_TIME')
        const temp_raw_results = { ...response.tr._raw }
        console.log('temp_raw_results:', temp_raw_results)
        delete response.tr._raw
        const temp_results = response.tr
        let user_results = rootGetters['results/user_results']
        console.log('User results:', user_results)
        // MEMO: injecting Temp results AND Temp RAW results into user_results
        //       IF there is no previously saved local user_result !
        const savedResultsPromises = Object.keys(temp_results)
          .reduce((arr, resultKey) => {
            const localResultValue = user_results[resultKey]
            const tempResultValue = temp_results[resultKey]
            const tempRawResultValue = temp_raw_results[resultKey] ? temp_raw_results[resultKey].raw : null
            console.log('localResultValue:', localResultValue)
            console.log('tempResultValue:', tempResultValue)
            console.log('tempRawResultValue:', tempRawResultValue)
            if (!localResultValue || _.isEmpty(localResultValue)) {
              arr.push(dispatch('results/save_result', [ resultKey, tempResultValue, tempRawResultValue ], { root: true }))
            }
            return arr
          }, [])
        console.log('savedResultsPromise:', savedResultsPromises)
        console.log('Temp results injected into results !')
        // TODO: make sure this return happens AFTER dispatching SAVE_RESULT five lines before!
        return Promise.all(savedResultsPromises)
      })
      .catch(err => {
        console.log('■ ■ ========== ■ ■')
        console.log('Got error while retrieving temp results:')
        console.warn(err)
        return false
      })
  },

  [A.SAVE_TO_API] ({ dispatch, rootState }, data) {  // uses root state, & results module actions & users module actions, state, and may use getters
    if (rootState.dev_mode) dispatch('results/add_to_info', { dev: rootState.dev_id }, { root: true })

    dispatch('results/add_to_info', {
      licence_agreement: data.licence_agreement,
      privacy_policy: data.privacy_policy,
      email: data.email,
      firstname: data.firstname || 'unknown',
      lastname: data.lastname || 'unknown',
      gender: data.gender || 'unknown',
    }, { root: true })

    if (data.school) dispatch('results/add_to_info', { school: data.school }, { root: true })
    if (data.region) dispatch('results/add_to_info', { region: data.region }, { root: true })

    if (data.integration_data) dispatch('results/add_to_info', { integration_data: data.integration_data }, { root: true })

    // HACK: hacky fallback for integrations without age
    if (data.integration) data.user_age_group = data.user_age_group || 'adult'
    // FIXME: check next line - maybe it's unreachable now, and can be deleted
    if (!rootState.users.user_age_group) dispatch('users/set_user_age_group', data.user_age_group, { root: true })
    // HACK: if no user_age_group, then calculate
    if (!rootState.users.user_age_group) dispatch('users/set_user_age_group', rootState.users.user_age < 18 ? 'kid' : 'adult', { root: true })

    return dispatch(A.SKILLFOLIO_SAVE_RESULT, {
      email: data.email,
      firstname: data.firstname || 'unknown',
      lastname: data.lastname || 'unknown',
      gender: data.gender || 'unknown',  // TODO: need to fix this in case of no TestAnonymous component in tests list
      // gender: data.gender || ((rootGetters[rootGetters['results/user_results'].anonymous && rootGetters[rootGetters['results/user_results'].anonymous.gender) ? rootGetters[rootGetters['results/user_results'].anonymous.gender : 'unknown')
      subscribe: data.subscribe || false,
      // birthday: data.birthday,
    })
      .then(response => dispatch(A.SKILLFOLIO_ON_SUCCESS, { response, data }))
      .catch(err => dispatch(A.SKILLFOLIO_ON_ERROR, err))
  },

  [A.SKILLFOLIO_SAVE_RESULT] ({ state, getters, dispatch, rootState, rootGetters }, additionalObj) {  // uses results module state and getters, users module state

    return getters.skillfolio_api_loaded.then(() => {
      console.info('got skillfolio API object')
      let send_data = {
        ...rootState.results.req_root,
        info: rootState.results.u_info,
        ...additionalObj,
        ...rootGetters['results/user_results_extended'],
        raw: rootGetters['results/user_raw_results'],
        results_calculated: rootGetters['results/user_results_calculated'],
        version: 0.2,
      }
      if (state.ran_token) {
        console.log('Has ran_token, adding additional results_api field to request')
        const skills = send_data.skillfolio.naviki
        let skills_transformed_data = {}
        if (skills) { // MEMO: not all ranh projects got naviki test inside
          skills_transformed_data = {
            communication: parseInt(skills.content, 10) * 10,
            cooperation: parseInt(skills.infomanagement, 10) * 10,
            creative: parseInt(skills.outofthebox, 10) * 10,
            critical: parseInt(skills.decisions, 10) * 10,
            digital: parseInt(skills.digital, 10) * 10,
            eq: parseInt(skills.eq, 10) * 10,
            system: parseInt(skills.trans, 10) * 10
          }
        }
        const combo = send_data.skillfolio.combo
        let combo_transformed_data = {}
        if (combo) { // MEMO: not all ranh projects got naviki test inside
          combo_transformed_data = Object.entries(combo)
            .reduce((acc, [ key, value ]) => {
              acc['combo.' + key] = parseInt(value, 10)
              return acc
            }, {})
          // combo_transformed_data = {
          //   'combo.critical-in-digital': parseInt(combo['critical-in-digital'], 10),
          //   'combo.self-development': parseInt(combo['self-development'], 10),
          //   'combo.digital-communication': parseInt(combo['digital-communication'], 10),
          //   'combo.info-management': parseInt(combo['info-management'], 10),
          //   'combo.creative': parseInt(combo['creative'], 10),
          // }
        }
        send_data = {
          ...send_data,
          results_api: {
            integration: `ran-${send_data.skillfolio.mode}`,
            auth: {
              client_id: '49a403775f7b49339fa49c55f8aeeacb',
              token: state.ran_token,
            },
            data: {
              ...skills_transformed_data,
              ...combo_transformed_data,
            }
          }
        }
      }
      // // HACK: for KTS Big Skillfolio platform Syncing:
      // // https://feature-master-send-test-results-to-back.skl.kube1.ktsdev.ru/api/event/tests/submit
      // const save_to_big_skillfolio_back = state.callback_url &&
      //   decodeURIComponent(decodeURIComponent(state.callback_url)).search(/(api\/tests\/submit)|(api\/event\/tests\/submit)/) > -1
      // if (save_to_big_skillfolio_back) send_data.skillfolio.results_sync = decodeURIComponent(decodeURIComponent(state.callback_url))
      if (state.integration.results_sync) send_data.skillfolio.results_sync = state.integration.results_sync

      console.log('send_data:', send_data)

      return window.skillfolioApi.saveResult(send_data)
        .then((response) => {
          console.log('API answered with:', response)

          if (response && response._status.toLowerCase() === 'ok') {
            console.log('data saved successfully to Skillfolio API')

            if (rootState.users.user_id) {
              console.log('user_id found')

              // Clear temp results if has any:
              dispatch(A.SKILLFOLIO_DELETE_TEMP_RESULTS, {
                hash: rootGetters['users/user_hash'],
                etag: rootState.results.temp_results_etag,
              })
                .then(response => {
                  console.log('temp results deleted from API:', response)
                })

              // injecting link to results into post message data
              send_data.info.results_link = `https://test.skillfolio.ru/results/${response._id}`

              const event_id = 'skillfolio_message'
              // TODO: rewrite INTEGRATION_post_message sending code, with DRY
              if (state.token_pair.name && state.callback_xhr_req && state.callback_url) { // ASI integration (https://asi-futurestaff.mob-edu.ru)
                console.log('Has token, callback_xhr_req, and callback_url !')
                const config = { headers: { [state.token_pair.name]: state.token_pair.value }, withCredentials: true }
                console.log('making ajax request to', state.callback_url, 'with token in headers:', config)
                axios
                  .post(state.callback_url, send_data, config)
                  .finally(() => {
                    // sending postMessage() to parent window
                    console.log('has user_id, sending postMessage to parent HTML with event_id:', event_id)
                    dispatch(A.INTEGRATION_POST_MESSAGE, {
                      event_id: event_id,
                      data: send_data
                    })
                  })
              } else if (state.ran_token) { // RANH integration (https://vitrinadiagnostik.ru)
                dispatch(A.INTEGRATION_POST_MESSAGE, {
                  event_id: event_id,
                  data: { isPassed: true }
                })
                const reload_event_id = 'skillfolio_reload_parent'
                dispatch(A.INTEGRATION_POST_MESSAGE, {
                  event_id: reload_event_id,
                  data: { goToUrl: state.redirect_url }
                })
                // // LEGACY RAN INTEGRATION //
                // } else if (state.ran_token && state.callback_xhr_req && state.callback_url) {
                //   console.log('Has ran_token, callback_xhr_req, and callback_url !')
                //   console.log('making ajax request to', state.callback_url)
                //   const skills = send_data.skillfolio.naviki
                //   console.log('skills data:', skills)
                //   axios
                //     .post(state.callback_url, {
                //       client_id: '49a403775f7b49339fa49c55f8aeeacb',
                //       ran_token: state.ran_token,
                //       results: {
                //         communication: parseInt(skills.content, 10) * 10,
                //         cooperation: parseInt(skills.infomanagement, 10) * 10,
                //         creative: parseInt(skills.outofthebox, 10) * 10,
                //         critical: parseInt(skills.decisions, 10) * 10,
                //         digital: parseInt(skills.digital, 10) * 10,
                //         eq: parseInt(skills.eq, 10) * 10,
                //         system: parseInt(skills.trans, 10) * 10,
                //       }
                //     })
                //     .then(response => {
                //       console.log('RAN API saving results response:', response);
                //       // TRY/CATCH block goes here
                //       try {
                //         if (response.data.status.toLowerCase() == 'ok') {
                //           // window.location.href = state.redirect_url
                //           const event_id = 'skillfolio_reload_parent'
                //           dispatch(A.INTEGRATION_POST_MESSAGE, {
                //             event_id: event_id,
                //             data: {goToUrl: state.redirect_url}
                //           })
                //         } else {
                //           console.warn('RAN API not redirecting with response:', response)
                //           console.log('to state.redirect_url:', state.redirect_url)
                //         }
                //       } catch (err) {
                //         console.warn('Some error in response handling occured...', err)
                //       }
                //     })
                //     .catch(err => console.warn('ran server responsed with error: ' + err))
                // // end of: LEGACY RAN INTEGRATION //
              } else if (state.callback_xhr_req && state.callback_url) { // send POST data with AJAX to callback_url, then make default postMessage()
                console.log('Has callback_xhr_req and callback_url !')
                console.log('making ajax request to', state.callback_url)
                axios
                  .post(state.callback_url, send_data)
                  .finally(() => {
                    // sending postMessage() to parent window
                    console.log('has user_id, sending postMessage to parent HTML with event_id:', event_id)
                    dispatch(A.INTEGRATION_POST_MESSAGE, {
                      event_id: event_id,
                      data: send_data
                    })
                  })
              } else if (state.callback_url) { // send default postMessage, then send POST request with html form to callback_url
                console.log('Has user_id and cb, sending post form request to callback url:', state.callback_url)

                // sending postMessage() to parent window
                console.log('has user_id, sending postMessage to parent HTML with event_id:', event_id)
                dispatch(A.INTEGRATION_POST_MESSAGE, {
                  event_id: event_id,
                  data: send_data
                })

                const callbackAPIForm = document.querySelector('.test__callback-form')
                const dataTextArea = callbackAPIForm.querySelector('#skillfolio-results')
                dataTextArea.value = JSON.stringify(send_data)
                console.log('dataTextArea:', dataTextArea)
                callbackAPIForm.submit()
              } else if (state.tokens.unti && state.activity_uuid) { // UNTI integration
                console.log('Has tokens.unti and activity_uuid, sending result to Unti API')
                // TODO: refactor next 2 lines to vuex actions
                UntiApi.sendCurrentEnd()
                UntiApi.sendCurrentResult(send_data)
              } else { // default integration, used in many our Big Skillfolio projects
                // sending postMessage() to parent window
                console.log('has user_id, sending postMessage to parent HTML with event_id:', event_id)
                dispatch(A.INTEGRATION_POST_MESSAGE, {
                  event_id: event_id,
                  data: send_data
                })
              }
            }

            // MEMO: save completion at Constructor API, and update PromoUser instance,
            //       if promo-access code available in results
            // if (rootGetters['projects/use_constructor']) {
            //   console.warn('use constructor for saving completion hash')
            //   TestDataApi.saveCompletion({
            //     hash: response._id,
            //     project_name: send_data.skillfolio.mode,
            //     uid: send_data.info.user_id,
            //     email: send_data.info.email,
            //   })
            //     .then(completion_created_response => {
            //       console.warn('Completion instance was saved at Constructor API:', completion_created_response)
            //       const promo_access = send_data.skillfolio['promo-access']
            //       if (promo_access) {
            //         const user_promo_code = promo_access['promo-code']
            //         const user_email = promo_access['email']
            //         TestDataApi.completePromoUser({
            //           code: user_promo_code,
            //           project_mode: send_data.skillfolio.mode,
            //           email: user_email,
            //           completion_hash: response._id,
            //         })
            //           .then(promo_user_updated => console.warn('Promo user completed diagnostics:', promo_user_updated))
            //           .catch(err => console.warn('Promo user instance could\'nt be updated with completion because of the error:', err))
            //       }
            //     })
            //     .catch(err => {
            //       console.warn('Could\'nt create Completion instance at Constructor API:', err)
            //     })
            // } else console.warn('Not using constructor for saving completion hash')
          } else {
            throw new Error('SKILLFOLIO API RESPONSED WITH ERROR: ' + response._status)
          }

          return response
        })
    })
  },

  [A.SKILLFOLIO_ON_SUCCESS] ({ dispatch, rootState, rootGetters }, { response, data }) {  // uses users module state, uses results module getters and actions, uses completions module actions
    if (response) {

      const next_route = data.next_route  // if does not exist - not showing results
      if (next_route &&
        next_route.params &&
        next_route.params.skillfolio_id === null) {
        next_route.params.skillfolio_id = response._id
      }

      dispatch('results/add_to_root', response, { root: true })

      if (rootGetters['results/user_results']['utm_source']) {
        dispatch(A.CASEID_SAVE_UTM_SOURCE, {
          email: data.email,
          firstname: data.anonymous_firstname,
          lastname: data.lastname,
          birthday: data.birthday,
          // gender: data.get_gender,  // TODO: need to fix this in case of no TestAnonymous component in tests list,
          gender: data.gender,  // TODO: need to fix this in case of no TestAnonymous component in tests list,
          skillfolioid: response._id,
        })
          .then(() => dispatch('completions/complete_tests', { skillfolio_id: response._id, next_route }, { root: true }))

      } else if (rootState.users.asi_uid) {
        dispatch(A.CASEID_SAVE_ASI_UID, {
          externalTestId: response._id
        })
          .then(() => dispatch('completions/complete_tests', { skillfolio_id: response._id, next_route }, { root: true }))

      } else dispatch('completions/complete_tests', { skillfolio_id: response._id, next_route }, { root: true })
      return true
    } else {
      console.log('▲ ACTION: dispatching SKILLFOLIO_on_success: Error in skillfolio API succeed response :(')
      return false
    }

  },

  [A.SKILLFOLIO_ON_ERROR] (store, err) {
    console.warn('▲ ACTION: dispatching SKILLFOLIO_on_error() returns error: ' + err)
    console.log('▲ ACTION: API unavailable')
    return Promise.reject(err)
    // TODO: show prompt, that results UNSAVED, and if user wants - he can save'em locally!
    // this.no_connection_wanna_local ?
    // TODO: save done_results with local store.rootState.users.user_id, and open them in new TAB
    // this.save_with_local_id
    // this.show_in_new_tab
  },

  [A.SKILLFOLIO_GET_COMPLETION] ({ getters, dispatch }, skillfolio_id) {
    if (!skillfolio_id) {
      console.warn('skillfolio_id not found, API call declined...')
      return
    }
    dispatch('show_loader', null, { root: true })
    return getters.skillfolio_api_loaded
      .then(() => window.skillfolioApi.getResultById(skillfolio_id))
      .finally(() => dispatch('hide_loader', null, { root: true }))
  },
  [A.SKILLFOLIO_GET_AND_SAVE_COMPLETION] ({ dispatch }, hash) {
    console.log(`▲ ACTION (sync): dispatching SKILLFOLIO_get_and_save_completion with hash: ${hash}`)
    dispatch('show_loader', null, { root: true })
    return dispatch(A.SKILLFOLIO_GET_COMPLETION, hash)
      // (!) ACHTUNG: next line dispatching save_completion, which can also dispatch this action... Kind'a loop here.
      .then(completion => dispatch('completions/save_completion', { hash, completion }, { root: true }))
      .finally(() => dispatch('hide_loader', null, { root: true }))
  },

  [A.SKILLFOLIO_CHECK_EMAIL_IN_DB] ({ getters, rootState }, email) {
    return getters.skillfolio_api_loaded.then(() => window.skillfolioApi.checkUserAccess(email, rootState.project_name))
  },

  [A.SKILLFOLIO_CHECK_USER_ID_EXISTENCE] ({ getters, rootState }) {  // uses root mutations
    console.log('▲ ACTION (sync): dispatching SKILLFOLIO_check_user_id_existence')
    // return getters.skillfolio_api_loaded.then(() => window.skillfolioApi._get(`integrations/${rootState.project_name}/${rootState.users.user_id}`))
    return getters.skillfolio_api_loaded.then(() => window.skillfolioApi.apiGet(`integrations/${rootState.project_name}/${rootState.users.user_id}`))
  },

  [A.SKILLFOLIO_GET_USER_COMPLETION_HASHES] ({ getters, dispatch, rootState }, { project_name = null, user_id = null, user_email = null } = {}) {
    console.log('▲ ACTION (sync): dispatching SKILLFOLIO_get_user_completion_hashes')
    dispatch('show_loader', null, { root: true })
    const getCompletionByEmail = !user_id && user_email
    if (getCompletionByEmail) {
      console.log('searching Completion by email!')
      return getters.skillfolio_api_loaded
        // .then(() => window.skillfolioApi._get(`integrations/m/${project_name || rootState.project_name || '_all_'}/${user_email}/id`))
        // .then(response => {
        //   console.log('Completion found!')
        //   return response.data
        // })
        .then(() => window.skillfolioApi.apiGet(`integrations/m/${project_name || rootState.project_name || '_all_'}/${user_email}/id`))
        .catch(err => {
          console.warn('skillfolioAPi.apiGet() by email error is:', err)
          throw new Error(err)
        })
        .finally(() => {
          dispatch('hide_loader', null, { root: true })
        })
    } else {
      console.log('searching Completion by user_id!')
      return getters.skillfolio_api_loaded
        // .then(() => window.skillfolioApi._get(`integrations/${project_name || rootState.project_name}/${user_id || rootState.users.user_id}/id`))
        // .then(response => {
        //   console.log('Completion found!')
        //   return response.data
        // })
        .then(() => window.skillfolioApi.apiGet(`integrations/${project_name || rootState.project_name}/${user_id || rootState.users.user_id}/id`))
        .catch(err => {
          console.warn('skillfolioApi.apiGet() by user_id error is:', err)
          throw new Error(err)
        })
        .finally(() => {
          dispatch('hide_loader', null, { root: true })
        })
    }
  },

  [A.SKILLFOLIO_GET_USER_LAST_COMPLETION_HASH] ({ dispatch }) {
    console.log('▲ ACTION (sync): dispatching SKILLFOLIO_get_user_last_completion')
    dispatch('show_loader', null, { root: true })
    return dispatch(A.SKILLFOLIO_GET_USER_COMPLETION_HASHES)
      .then(hashes => {
        console.log('hashes:', hashes)
        if (hashes && hashes.length) return hashes.pop()[0]
        // else return null  // TODO: idea! - can add temporary ID here
      })
      .catch(err => {
        console.log('SKILLFOLIO_get_user_completion_hashes error is:', err)
        throw new Error(err)  // TODO: idea! - can add temporary ID here
      })
      .finally(() => {
        dispatch('hide_loader', null, { root: true })
      })
  },

  [A.SKILLFOLIO_GET_CUSTOM_COMPLETION_HASH] ({ dispatch }, { project_name, user_id, user_email }) {
    console.log('▲ ACTION (sync): dispatching SKILLFOLIO_get_custom_completion_hash() with payload:', { project_name, user_id, user_email })
    if (!project_name && (!user_id || !user_email)) {
      console.warn('No info for searching completion given!')
      return Promise.reject(new Error('No info for searching completion given!'))
    }
    dispatch('show_loader', null, { root: true })
    return dispatch(A.SKILLFOLIO_GET_USER_COMPLETION_HASHES, { project_name, user_id, user_email })
      .then(hashes => {
        console.log('hashes:', hashes)
        if (hashes && hashes.length) return hashes.pop()[0]
        // else return null  // TODO: idea! - can add temporary ID here
      })
      .catch(err => {
        console.log('SKILLFOLIO_get_user_completion_hashes error is:', err)
        throw new Error(err)  // TODO: idea! - can add temporary ID here
      })
      .finally(() => {
        dispatch('hide_loader', null, { root: true })
      })
  },

  [A.SKILLFOLIO_DECRYPT_TRIPLEDES_CODE] ({ getters, dispatch, rootState }, code) {  // uses root state
    console.log('▲ ACTION (sync): dispatching SKILLFOLIO_decrypt_tripleDes_code')
    dispatch('show_loader', null, { root: true })
    const prepareForMySkills = project_name => project_name.replace(/(-h|-m|-pages|-algebra|-biology|-social-science|-computer-science|-english-language|-russian-language)$/gi, '')
    const prepareForOthers = project_name => project_name
    return getters.skillfolio_api_loaded
      // .then(() => window.skillfolioApi._get(`uid/${prepareForOthers(prepareForMySkills(rootState.project_name))}?code=${code}`))
      // .then(response => {
      //   console.log('decryptor initial response:', response)
      //   return response.data
      // })
      .then(() => window.skillfolioApi.apiGet(`uid/${prepareForOthers(prepareForMySkills(rootState.project_name))}?code=${code}`))
      .catch(err => {
        console.log('skillfolioApi.apiGet() error is:', err)
        throw new Error(err)
      })
      .finally(() => {
        dispatch('hide_loader', null, { root: true })
      })
  },

  [A.CASEID_SAVE_UTM_SOURCE] ({ rootState, rootGetters }, additionalObj) {  // uses results module getters, uses users module state
    if (rootGetters['results/user_results'].utm_source) {
      console.info('got utm_source in storage results! send data to /skillfolioutms/${rootGetters[\'results/user_results\'].utm_source}/students/')
      const send_data = {
        ...additionalObj,
        asi_uid: rootState.users.asi_uid,
      }
      return CaseidApi.saveWithSkillfolioUtm(rootGetters['results/user_results'].utm_source, send_data)
    } else {
      const err_msg = 'No utm_source in storage, not saving skillfolioutm in caseid API!'
      console.log(err_msg)
      return Promise.reject(err_msg)
    }
  },

  [A.CASEID_SAVE_ASI_UID] ({ rootState, rootGetters }, additionalObj) {  // uses users module state, uses results module getters
    if (rootState.users.asi_uid) {
      console.info('got asi_uid in storage! send data to /asi/')
      const send_data = {
        userId: rootState.users.asi_uid,
        ...additionalObj,
        characteristic: rootGetters['results/user_results'],
      }
      return CaseidApi.saveToAsiApi(send_data)
    } else {
      const err_msg = 'No asi_uid in storage, not saving asi in caseid API!'
      console.log(err_msg)
      return Promise.reject(err_msg)
    }
  },

  [A.INTEGRATION_POST_MESSAGE] (store, data) {
    return window.parent.postMessage(data, '*')  // 'http://skillfolio.ru'
  },
}
