<template>
  <div class="progressbar" :class="{inn}">
    <div :style="{width: width + '%', height: borderMode !== 'none' ? '10px' : '12px'}" />
  </div>
</template>

<script>
export default {
  name: 'progressbar',
  props: {
    'progress': Number,
    'borderMode': String
  },
  data () {
    return {
      inn: true,
    }
  },
  computed: {
    width () {
      return Math.min(this.progress, 1) * 100
    }
  },
  mounted () {
    this.progress_timeout_m = setTimeout(() => this.inn = false, 200)
  }
}
</script>

<style lang="less" scoped>

  @import "../variables";

  .progressbar {
    border-radius: @bdrs_half;
    //border: 1px solid @blue;
    border: var(--progress-border);
    width: 100px;
    height: @unit;
    overflow: hidden;
    margin: 0 3px;
    transition: width 0.5s ease;
    background-color: var(--progress-background-color);
    &.inn {
      width: @unit;
    }
    div {
      //background-color: @blue;
      background-color: var(--progress-fill-color);
      //height: 10px;
      transition: width .5s ease;
      border-radius: 5px;
    }
  }
</style>
