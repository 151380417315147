console.log('≡≡ local-accessible-pages.js loaded! ≡≡');

export default {
  // Route Name Format: name:param | name:param=value | name:param1:param2 | name:param1=value1:param2=value2
  common: [],
  a: [],
  'all-tests': [
  ],
  test: [
    // 'navigation'
  ],
  ey: [],
  'my-skills-pages': [
    'navigation-school-subjects',
    'navigation-soft-skills',
    'navigation-online-courses',
  ],
  skillfolio: [
  ],
  'caseid-skillfolio': [],
}
