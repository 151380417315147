// Modules' mutations can't use root anything, thought they are
// hasn't got access to rootState, rootGetter or anything, and should be the pure fns,
// but if such behaviour is needed — better to move it out to the corresponding action

// root
export const UPDATE_VERSION = 'UPDATE_VERSION'
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const UPDATE_LOADER = 'UPDATE_LOADER'
export const CLEAR_LOADER = 'CLEAR_LOADER'
export const ENABLE_DEV_MODE = 'ENABLE_DEV_MODE'
export const DISABLE_DEV_MODE = 'DISABLE_DEV_MODE'
export const SET_DEV_ID = 'SET_DEV_ID'
export const TOGGLE_DEV_PANEL = 'TOGGLE_DEV_PANEL'
export const UPDATE_IDLE_TIME = 'UPDATE_IDLE_TIME'
export const ALLOW_MULTIPLE_COMPLETIONS = 'ALLOW_MULTIPLE_COMPLETIONS'
export const FORBID_MULTIPLE_COMPLETIONS = 'FORBID_MULTIPLE_COMPLETIONS'

// projects
export const SET_PROJECT_DATA = 'SET_PROJECT_DATA'
export const RESET_PROJECT_DATA = 'RESET_PROJECT_DATA'
export const REMOVE_PROJECT_DATA = 'REMOVE_PROJECT_DATA'

// users
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const REMOVE_AUTH_TOKEN = 'REMOVE_AUTH_TOKEN'
export const SET_AUTH_JWT = 'SET_AUTH_JWT'
export const REFRESH_AUTH_JWT = 'REFRESH_AUTH_JWT'
export const REMOVE_AUTH_JWT = 'REMOVE_AUTH_JWT'
export const SET_AUTH_USER = 'SET_AUTH_USER'
export const REMOVE_AUTH_USER = 'REMOVE_AUTH_USER'
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS'

export const SET_USER_ID = 'SET_USER_ID'
export const SET_ASI_UID = 'SET_ASI_UID' // legacy
export const RESET_USER_ID = 'RESET_USER_ID'
export const RESET_ASI_UID = 'RESET_ASI_UID' // legacy
export const SET_USER_AGE = 'SET_USER_AGE'
export const RESET_USER_AGE = 'RESET_USER_AGE'
export const SET_USER_AGE_GROUP = 'SET_USER_AGE_GROUP'
export const RESET_USER_AGE_GROUP = 'RESET_USER_AGE_GROUP'
export const SET_SHORT_FIO = 'SET_SHORT_FIO'
export const REMOVE_SHORT_FIO = 'REMOVE_SHORT_FIO'
export const SET_PARALLEL = 'SET_PARALLEL'
export const REMOVE_PARALLEL = 'REMOVE_PARALLEL'
export const SET_MOSCOW_STUDENT = 'SET_MOSCOW_STUDENT'
export const REMOVE_MOSCOW_STUDENT = 'REMOVE_MOSCOW_STUDENT'

// tests
export const ADD_PROJECT_AGE_GROUP = 'ADD_PROJECT_AGE_GROUP'
export const REMOVE_PROJECT_AGE_GROUP = 'REMOVE_PROJECT_AGE_GROUP'
export const REMOVE_PROJECT_AGE_GROUPS = 'REMOVE_PROJECT_AGE_GROUPS'
export const ADD_ACCESSIBLE_PAGES = 'ADD_ACCESSIBLE_PAGES'
export const REMOVE_ACCESSIBLE_PAGES = 'REMOVE_ACCESSIBLE_PAGES'
export const ADD_TESTS_SEQUENCE = 'ADD_TESTS_SEQUENCE'
export const REMOVE_TESTS_SEQUENCE = 'REMOVE_TESTS_SEQUENCE'
// TODO: add switching test sequence logic
export const SET_APPENDED_DATA = 'SET_APPENDED_DATA' // used in root actions
export const CHANGE_APPENDED_MODE = 'CHANGE_APPENDED_MODE'
export const ADD_SINGLE_TEST = 'ADD_SINGLE_TEST' // TODO: add corresponding action and logic

// results
export const SAVE_RESULT = 'SAVE_RESULT' // uses users module state
export const SAVE_RAW_RESULT = 'SAVE_RAW_RESULT' // uses users module state
export const SAVE_RESULTS = 'SAVE_RESULTS' // uses users module state
export const REMOVE_RESULT = 'REMOVE_RESULT' // uses users module state
export const REMOVE_RAW_RESULT = 'REMOVE_RAW_RESULT' // uses users module state
export const UPDATE_RESULT = 'UPDATE_RESULT' // uses users module state
export const SET_USER_BIRTHDAY_RESULT = 'SET_USER_BIRTHDAY_RESULT' // uses users module state
export const SET_ANONYMOUS_GENDER_RESULT = 'SET_ANONYMOUS_GENDER_RESULT' // uses users module state
export const SAVE_RESULTS_CALCULATED = 'SAVE_RESULTS_CALCULATED' // uses users module state
export const RESET_RESULTS = 'RESET_RESULTS'
export const RESET_RAW_RESULTS = 'RESET_RAW_RESULTS'
export const RESET_RESULTS_CALCULATED = 'RESET_RESULTS_CALCULATED'
export const RESET_USER_RESULTS = 'RESET_USER_RESULTS' // uses users module state
export const RESET_USER_RAW_RESULTS = 'RESET_USER_RAW_RESULTS' // uses users module state
export const RESET_USER_RESULTS_CALCULATED = 'RESET_USER_RESULTS_CALCULATED' // uses users module state

export const SAVE_TEMP_RESULTS = 'SAVE_TEMP_RESULTS'
export const UPDATE_TEMP_RESULTS = 'UPDATE_TEMP_RESULTS'
export const REMOVE_TEMP_RESULTS = 'REMOVE_TEMP_RESULTS'

export const SAVE_TEMP_RESULTS_ETAG = 'SAVE_TEMP_RESULTS_ETAG'
export const REMOVE_TEMP_RESULTS_ETAG = 'REMOVE_TEMP_RESULTS_ETAG'

export const SET_INFO = 'SET_INFO'
export const RESET_INFO = 'RESET_INFO'
export const ADD_TO_INFO = 'ADD_TO_INFO'
export const REMOVE_FROM_INFO = 'REMOVE_FROM_INFO'
export const SET_ROOT = 'SET_ROOT'
export const RESET_ROOT = 'RESET_ROOT'
export const ADD_TO_ROOT = 'ADD_TO_ROOT'
export const REMOVE_FROM_ROOT = 'REMOVE_FROM_ROOT'
export const ADD_NEW_USER_RESULTS_OBJ = 'ADD_NEW_USER_RESULTS_OBJ'
export const OBSERVE_EXISTING_USER_RESULTS_OBJ = 'OBSERVE_EXISTING_USER_RESULTS_OBJ'
export const ADD_NEW_USER_RAW_RESULTS_OBJ = 'ADD_NEW_USER_RAW_RESULTS_OBJ'
export const OBSERVE_EXISTING_USER_RAW_RESULTS_OBJ = 'OBSERVE_EXISTING_USER_RAW_RESULTS_OBJ'
export const ADD_NEW_USER_RESULTS_CALCULATED_OBJ = 'ADD_NEW_USER_RESULTS_CALCULATED_OBJ'
export const OBSERVE_EXISTING_USER_RESULTS_CALCULATED_OBJ = 'OBSERVE_EXISTING_USER_RESULTS_CALCULATED_OBJ'

// completions
export const ADD_COMPLETION = 'ADD_COMPLETION'
export const REMOVE_COMPLETION = 'REMOVE_COMPLETION'
export const REMOVE_USER_COMPLETION = 'REMOVE_USER_COMPLETION' // uses users module state
export const UPDATE_COMPLETION_RESULTS_CALCULATED = 'UPDATE_COMPLETION_RESULTS_CALCULATED'
export const UPDATE_COMPLETION_REQUEST_ROOT = 'UPDATE_COMPLETION_REQUEST_ROOT'
export const RESET_COMPLETIONS = 'RESET_COMPLETIONS'
export const ADD_SKILLFOLIO_ID = 'ADD_SKILLFOLIO_ID'
export const REMOVE_SKILLFOLIO_ID = 'REMOVE_SKILLFOLIO_ID'
export const ADD_USER_COMPLETIONS_HASHES = 'ADD_USER_COMPLETIONS_HASHES'
export const ADD_HASH_TO_USER_COMPLETIONS_HASHES = 'ADD_HASH_TO_USER_COMPLETIONS_HASHES'
export const CLEAR_USER_COMPLETIONS_HASHES = 'CLEAR_USER_COMPLETIONS_HASHES'
export const RESET_USER_COMPLETIONS_HASHES = 'RESET_USER_COMPLETIONS_HASHES'
export const ADD_SUITABLE_COMPLETION_HASHES_PAIR = 'ADD_SUITABLE_COMPLETION_HASHES_PAIR'
export const RESET_SUITABLE_COMPLETION_HASHES = 'RESET_SUITABLE_COMPLETION_HASHES'

// api
export const SET_CALLBACK_URL = 'SET_CALLBACK_URL'
export const REMOVE_CALLBACK_URL = 'REMOVE_CALLBACK_URL'
export const SET_CALLBACK_XHR_REQ = 'SET_CALLBACK_XHR_REQ'
export const SET_TOKEN = 'SET_TOKEN'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'
export const RESET_ALL_TOKENS = 'RESET_ALL_TOKENS'
export const SET_INTEGRATION = 'SET_INTEGRATION'
export const REMOVE_INTEGRATION = 'REMOVE_INTEGRATION'
export const SET_ACTIVITY_UUID = 'SET_ACTIVITY_UUID'
export const REMOVE_ACTIVITY_UUID = 'REMOVE_ACTIVITY_UUID'
export const SET_TOKEN_PAIR = 'SET_TOKEN_PAIR'
export const REMOVE_TOKEN_PAIR = 'REMOVE_TOKEN_PAIR'
export const SET_RAN_TOKEN = 'SET_RAN_TOKEN'
export const REMOVE_RAN_TOKEN = 'REMOVE_RAN_TOKEN'
// export const SET_UNTI_TOKEN = 'SET_UNTI_TOKEN'
// export const REMOVE_UNTI_TOKEN = 'REMOVE_UNTI_TOKEN'
export const SET_CODE = 'SET_CODE'
export const REMOVE_CODE = 'REMOVE_CODE'
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL'
export const REMOVE_REDIRECT_URL = 'REMOVE_REDIRECT_URL'
export const UPDATE_LAST_TR_CHECK_TIME = 'UPDATE_LAST_TR_CHECK_TIME'
