import Vue from 'vue'
import utils from '@/utils'
import TestDataApi from '@/services/TestDataApi'
import { RETRIEVE_PROGRESS_FROM_TEMP } from '@/store/action-types'

import {
  SET_AUTH_USER,
  REMOVE_AUTH_USER,
  SET_AUTH_STATUS,
  SET_AUTH_TOKEN,
  REMOVE_AUTH_TOKEN,
  SET_AUTH_JWT,
  REFRESH_AUTH_JWT,
  REMOVE_AUTH_JWT,

  SET_USER_ID,
  RESET_USER_ID,
  SET_ASI_UID,
  RESET_ASI_UID,
  SET_USER_AGE,
  RESET_USER_AGE,
  SET_USER_AGE_GROUP,
  RESET_USER_AGE_GROUP,
  SET_SHORT_FIO,
  REMOVE_SHORT_FIO,
  SET_PARALLEL,
  REMOVE_PARALLEL,
  SET_MOSCOW_STUDENT,
  REMOVE_MOSCOW_STUDENT,
} from '../mutation-types'
import * as A from '../action-types'

import md5 from 'blueimp-md5'


export const namespaced = true

export const state = {
  auth: {
    status: false,
    // integration: {},
    user: {},
    token: JSON.parse(localStorage.getItem('api_auth_token')),
    jwt: JSON.parse(localStorage.getItem('api_jwt')),
    // endpoints: {
    //   base_url: 'localhost/api/v1/',
    //   obtain_token: 'localhost/api/v1/auth/token-obtain',
    //   obtain_jwt: 'localhost/api/v1/auth/jwt-obtain',
    //   refresh_jwt: 'localhost/api/v1/auth/jwt-refresh',
    // },
  },

  // simple client no-auth user
  user_id: null,
  user_age_group: null,
  user_age: null,
  // user_gender: null, // TODO: need to add this state, it's methods and use it

  // asi integration
  asi_uid: null,

  // myskills integration
  short_fio: '',
  parallel: '',
  is_moscow_student: null,
}

export const getters = {
  auth_user: state => state.auth.user,
  auth_status: state => state.auth.status,
  // auth_endpoint_base_url: state => state.auth.endpoints.base_url,
  // obtain_token_endpoint: state => state.auth.endpoints.obtain_token,
  // obtain_jwt_token_endpoint: state => state.auth.endpoints.obtain_jwt,
  // refresh_jwt_token_endpoint: state => state.auth.endpoints.refresh_jwt,

  get_user_age: state => state.user_age,
  get_user_age_group: state => state.user_age_group,
  user_gender: (state, getters, rootState) => rootState.results.u_info.gender, // uses results module state
  user_hash: (state, getters, rootState) => md5(rootState.project_name + '_' + state.user_id).slice(0, 24)
}

// hasn't got access to rootState, rootGetter or anything, should be the pure fn
// if such behaviour is needed — better to move it out to the corresponding action
export const mutations = {
  [SET_AUTH_TOKEN] (state, token) {
    state.auth.token = token
  },
  [REMOVE_AUTH_TOKEN] (state) {
    state.auth.token = ''
  },
  [SET_AUTH_JWT] (state, jwt) {
    Vue.set(state.auth, 'jwt', jwt)
  },
  [REFRESH_AUTH_JWT] (state, access) {
    state.auth.jwt.access = access
  },
  [REMOVE_AUTH_JWT] (state) {
    Vue.set(state.auth, 'jwt', {})
  },
  [SET_AUTH_USER] (state, auth_user) {
    Vue.set(state.auth, 'user', auth_user)
  },
  [REMOVE_AUTH_USER] (state) {
    Vue.set(state.auth, 'user', {})
  },
  [SET_AUTH_STATUS] (state, status) {
    state.auth.status = status
  },


  [SET_USER_ID] (state, user_id) {
    state.user_id = user_id
  },
  [RESET_USER_ID] (state) {
    state.user_id = null
  },
  [SET_ASI_UID] (state, asi_uid) {
    state.asi_uid = asi_uid
  },
  [RESET_ASI_UID] (state) {
    state.asi_uid = null
  },
  [SET_USER_AGE] (state, age) {
    state.user_age = age
  },
  [RESET_USER_AGE] (state) {
    state.user_age = null
  },
  [SET_USER_AGE_GROUP] (state, age) {
    state.user_age_group = age
  },
  [RESET_USER_AGE_GROUP] (state) {
    state.user_age_group = null
  },
  [SET_SHORT_FIO] (state, short_fio) {
    state.short_fio = short_fio
  },
  [REMOVE_SHORT_FIO] (state) {
    state.short_fio = ''
  },
  [SET_PARALLEL] (state, parallel) {
    state.parallel = parallel
  },
  [REMOVE_PARALLEL] (state) {
    state.parallel = ''
  },
  [SET_MOSCOW_STUDENT] (state, is_moscow_student) {
    state.is_moscow_student = is_moscow_student
  },
  [REMOVE_MOSCOW_STUDENT] (state) {
    state.is_moscow_student = null
  },
}

export const actions = {
  [A.SET_AUTH_TOKEN] ({ commit }, token) {
    if (utils.classOf(token) !== 'String') {
      console.warn('Unknown auth token, should be String')
      return
    }
    localStorage.setItem('api_auth_token', token)
    // TestDataApi.client.defaults.headers.common['Authorization'] = token
    TestDataApi.renewAuthHeaders()
    commit('SET_AUTH_TOKEN', token)
  },
  [A.REMOVE_AUTH_TOKEN] ({ commit }) {
    localStorage.removeItem('api_auth_token')
    // TestDataApi.client.defaults.headers.common['Authorization'] = ''
    TestDataApi.renewAuthHeaders()
    commit('REMOVE_AUTH_TOKEN')
  },
  [A.SET_AUTH_JWT] ({ commit }, jwt) {
    if (utils.classOf(jwt) !== 'Object') {
      console.warn('Bad auth JWT, should be Object')
      return
    }
    localStorage.setItem('api_jwt', JSON.stringify(jwt))
    // TestDataApi.client.defaults.headers.common['Authorization'] = `Bearer ${jwt.access}`
    TestDataApi.renewAuthHeaders()
    commit('SET_AUTH_JWT', jwt)
  },
  [A.REFRESH_AUTH_JWT] ({ commit }, jwt_access) {
    if (utils.classOf(jwt_access) !== 'String') {
      console.warn('Bad auth JWT access, should be String')
      return
    }
    const jwtInStorage = localStorage.getItem('api_jwt')
    if (!jwtInStorage) {
      console.warn(`Can't refresh unexistent api jwt in localStorage!`)
      return
    }
    localStorage.setItem('api_jwt', {
      refresh: jwtInStorage.refresh,
      access: jwt_access,
    })
    // TestDataApi.client.defaults.headers.common['Authorization'] = `Bearer ${jwt_access}`
    TestDataApi.renewAuthHeaders()
    commit('REFRESH_AUTH_JWT', jwt_access)
  },
  [A.REMOVE_AUTH_JWT] ({ commit }) {
    localStorage.removeItem('api_jwt')
    // TestDataApi.client.defaults.headers.common['Authorization'] = ``
    TestDataApi.renewAuthHeaders()
    commit('REMOVE_AUTH_JWT')
  },
  [A.SET_AUTH_USER] ({ commit }, auth_user) {
    if (utils.classOf(auth_user) !== 'Object') {
      console.warn('Unknown auth user type, should be an Object')
      return
    }
    commit('SET_AUTH_USER', auth_user)
  },
  [A.REMOVE_AUTH_USER] ({ commit }) {
    commit('REMOVE_AUTH_USER')
  },
  [A.AUTHENTICATE_USER] ({ commit, dispatch }, [ auth_user, token ]) { // , token
    switch (utils.classOf(token)) {
    case 'String':
      // commit('SET_AUTH_TOKEN', token)
      dispatch(A.SET_AUTH_TOKEN, token)
      break
    case 'Object':
      // commit('SET_AUTH_JWT', token)
      dispatch(A.SET_AUTH_JWT, token)
      break
    }
    dispatch(A.SET_AUTH_USER, auth_user)
    commit('SET_AUTH_STATUS', true)
  },
  [A.DEAUTHENTICATE_USER] ({ commit, dispatch }) {
    commit('REMOVE_AUTH_USER')
    // if (state.auth.token) commit('REMOVE_AUTH_TOKEN')
    if (state.auth.token) dispatch(A.REMOVE_AUTH_TOKEN)
    // if (state.auth.jwt) commit('REMOVE_AUTH_JWT')
    if (state.auth.jwt) dispatch(REMOVE_AUTH_JWT)
    commit('SET_AUTH_STATUS', false)
  },


  [A.SET_USER_ID] ({ commit, dispatch }, user_id) { // uses results module
    commit('SET_USER_ID', user_id)
    dispatch('results/add_to_info', { user_id: user_id }, { root: true })
  },
  [A.RESET_USER_ID] ({ commit, dispatch }) {
    commit('RESET_USER_ID')
    dispatch('results/remove_from_info', 'user_id', { root: true })
  },
  [A.RESET_ASI_UID] ({ commit, dispatch }) { // NEW FEATURE
    commit('RESET_ASI_UID')
    dispatch('results/remove_from_info', 'asi_uid', { root: true })
  },
  [A.SET_ASI_UID] ({ commit, dispatch }, asi_uid) { // uses results module
    // FIXME: asi_uid always stored in info after reset to null, need to fix
    commit('SET_ASI_UID', asi_uid)
    dispatch('results/add_to_info', { asi_uid: asi_uid }, { root: true })
  },
  [A.SET_USER_AGE] ({ commit, dispatch }, age) {
    console.log('▲ ACTION: dispatching set_user_age with age:', age)
    if (age) {
      commit('SET_USER_AGE', age)
      dispatch('results/add_to_info', { user_age: age }, { root: true })
    } else console.warn('There is no age, commit declined :(')
  },
  [A.RESET_USER_AGE] ({ commit, dispatch }) {
    console.log('▲ ACTION: dispatching reset_user_age')
    commit('RESET_USER_AGE')
    dispatch('results/remove_from_info', 'user_age', { root: true })
  },
  [A.SET_USER_AGE_GROUP] ({ commit }, age_group) {
    console.log('▲ ACTION: dispatching set_user_age_group with age_group:', age_group)
    if (age_group) commit('SET_USER_AGE_GROUP', age_group)
    // TODO: maybe also saving it into info, like user_id and user_age ?
    else console.warn('There is no age, commit declined :(')
  },
  [A.RESET_USER_AGE_GROUP] ({ commit }) {
    console.log('▲ ACTION: dispatching reset_user_age_group')
    commit('RESET_USER_AGE_GROUP')
  },
  [A.SET_SHORT_FIO] ({ commit }, short_fio) {
    if (short_fio === 'null') commit('REMOVE_SHORT_FIO')
    else commit('SET_SHORT_FIO', short_fio)
  },
  [A.REMOVE_SHORT_FIO] ({ commit }) {
    commit('REMOVE_SHORT_FIO')
  },
  [A.SET_PARALLEL] ({ commit }, parallel) {
    if (parallel === 'null') commit('REMOVE_PARALLEL')
    else commit('SET_PARALLEL', parallel)
  },
  [A.REMOVE_PARALLEL] ({ commit }) {
    commit('REMOVE_PARALLEL')
  },
  [A.SET_MOSCOW_STUDENT] ({ commit }, is_moscow_student) {
    if (is_moscow_student === 'null') commit('REMOVE_MOSCOW_STUDENT')
    else commit('SET_MOSCOW_STUDENT', is_moscow_student)
  },
  [A.REMOVE_MOSCOW_STUDENT] ({ commit }) {
    commit('REMOVE_MOSCOW_STUDENT')
  },
  [A.SET_USER] ({ state, commit, dispatch, rootState }, user_id) { // uses results module
    console.log('▲ ACTION: dispatching set_user')
    if (!user_id && user_id !== null) {
      console.log('▲ ACTION: setting user_id implicitly to', state.user_id)
      user_id = state.user_id // if no user_id send to action - use state.user_id value
    }

    const change_user = state.user_id !== user_id
    if (change_user) {
      console.log('▲ ACTION: user changed !')

      const no_user_results = !rootState.results.results[user_id]
      if (no_user_results || rootState.multiple_completions) {
        console.log('▲ ACTION: No results object for user, or multiple completions allowed, creating...')
        dispatch('results/add_new_user_results_obj', user_id, { root: true })
      } else {
        console.warn('▲ ACTION: Already got this user results object!!!')
        dispatch('results/observe_existing_user_results_obj', user_id, { root: true })
      }

      const no_user_raw_results = !rootState.results.raw_results[user_id]
      if (no_user_raw_results || rootState.multiple_completions) {
        console.log('▲ ACTION: No raw results object for user, or multiple completions allowed, creating...')
        dispatch('results/add_new_user_raw_results_obj', user_id, { root: true })
      } else {
        console.warn('▲ ACTION: Already got this user raw results object!!!')
        dispatch('results/observe_existing_user_raw_results_obj', user_id, { root: true })
      }

      const no_user_results_calculated = !rootState.results.results_calculated[user_id]
      if (no_user_results_calculated || rootState.multiple_completions) {
        console.log('▲ ACTION: No results calculated object for user, or multiple completions allowed, creating...')
        dispatch('results/add_new_user_results_calculated_obj', user_id, { root: true })
      } else {
        console.warn('▲ ACTION: Already got this user results calculated object !!!')
        dispatch('results/observe_existing_user_results_calculated_obj', user_id, { root: true })
      }

      commit('SET_USER_ID', user_id)
      dispatch('results/add_to_info', { user_id: user_id }, { root: true })

      dispatch('results/remove_temp_results', null, { root: true })
      dispatch('results/remove_temp_results_etag', null, { root: true })
      // Retreiving temporary results:
      return dispatch(`apis/${RETRIEVE_PROGRESS_FROM_TEMP}`, null, { root: true })
    } else {
      console.log('▲ ACTION: user not changed !')
      if (rootState.multiple_completions) {
        console.log('▲ ACTION: multiple completions allowed, creating clean results obj...')
        dispatch('results/add_new_user_results_obj', user_id, { root: true })
        dispatch('results/add_new_user_raw_results_obj', user_id, { root: true })
        dispatch('results/add_new_user_results_calculated_obj', user_id, { root: true })
      } else {
        console.log('▲ ACTION: stale user results restored...')
        dispatch('results/observe_existing_user_results_obj', user_id, { root: true })
        dispatch('results/observe_existing_user_raw_results_obj', user_id, { root: true })
        dispatch('results/observe_existing_user_results_calculated_obj', user_id, { root: true })
      }
      // TODO: next line is reinsurance in case user_id was losen somewhere
      dispatch('results/add_to_info', { user_id: user_id }, { root: true })

      // Retreiving temporary results if an hour from last update passed:
      if (Date.now() - rootState.apis.last_tr_check_time > 1000 * 60 * 60) {
        console.log('An hour passed since last TR check, rechecking')
        return dispatch(`apis/${RETRIEVE_PROGRESS_FROM_TEMP}`, null, { root: true })
      } else return true // MEMO: if no retrieving — SET_USER successfully ends here
    }
  },
  [A.RESET_USER] ({ dispatch }) {
    return Promise.all([
      dispatch(A.RESET_USER_ID),
      dispatch(A.RESET_ASI_UID),
      dispatch(A.RESET_USER_AGE),
      dispatch(A.RESET_USER_AGE_GROUP),
      // integration params
      dispatch(A.REMOVE_SHORT_FIO),
      dispatch(A.REMOVE_PARALLEL),
      dispatch(A.REMOVE_MOSCOW_STUDENT),
      // constructor API auth params
      dispatch(A.DEAUTHENTICATE_USER)
    ])
  },
  [A.RESET_USER_DETAILS] ({ dispatch }) {
    // MEMO: clearing user state, wich was set through passing test
    //       such as user_age (could be also passed with ?get=query) & user_age_group
    return Promise.all([
      dispatch(A.RESET_USER_AGE),
      dispatch(A.RESET_USER_AGE_GROUP),
    ])
  },
}
