import Vue from 'vue'
import utils from '@/utils'
import _ from 'lodash'
const transformTest = utils.transformTest

import {
  SET_PROJECT_DATA,
  RESET_PROJECT_DATA,
  REMOVE_PROJECT_DATA,
} from '../mutation-types'
import * as A from '../action-types'

export const namespaced = true

export const state = {
  // auth: {
  //   status: false,
  //   user: {},
  //   token: localStorage.getItem('api_auth_token'),
  // },

  // use_test_data_constructor_for: [
  //   'custom-test-data',
  //   'x-test',
  //   'y-test',
  //   'z-test',
  //   'miller-project',
  //   'g-test',
  //   'c-test',
  //   'matosh',
  //   'froo',
  //   'froo-out',
  //   'jjp',
  //   'scale-analytic-thinking',
  //   'scale-creative-thinking',
  //   'b-test',
  //
  //   // 'leader-1-4-2021',
  //   'leader-5-7-2021',
  //   'leader-8-9-2021',
  //   'leader-10-11-2021',
  //   'leader-1-4-2021-second',
  //   'leader-5-7-2021-second',
  //   'leader-8-9-2021-second',
  //   'leader-10-11-2021-second',
  //   'leader-1-4-2021-third',
  //   'leader-5-7-2021-third',
  //   'leader-8-9-2021-third',
  //   'leader-10-11-2021-third',
  //
  //   // 'school-1-4',
  //   'school-5-7',
  //   'school-8-9',
  //   'school-10-11',
  //   'school-1-4-second',
  //   'school-5-7-second',
  //   'school-8-9-second',
  //   'school-10-11-second',
  //   'school-1-4-third',
  //   'school-5-7-third',
  //   'school-8-9-third',
  //   'school-10-11-third',
  //
  //   'inclusion-1',
  //   'inclusion-1-out',
  //   'inclusion-2',
  //   'inclusion-2-out',
  //
  //   'na',
  //   'mc-in-2021',
  //   'mc-in-2-2021',
  //
  //   'anxiety-in-2021'
  // ],
  projects: {
    common: { // MEMO: configuration & data for `common` project
      public: true,
      config: {},
      // app_config: {},
      // router_config: {},
      // store_config: {},
      // pages_config: {},
      // test_config: {},
      // tests_config: {},
      // signup_config: {},
      // results_config: {},
      app: {},
      router: {},
      storage: {},
      pages: [],
      test: {},
      test_config: {},
      tests: [],
      signup: {},
      results: [],
      results_config: {},
      completions: [],
    },
  }
}

export const getters = {
  // methods-like, non cacheble, non reactive:
  should_use_constructor_for: state => project_name => !_.isEmpty(state.projects[project_name]),

  get_project_data: state => project_name => state.projects[project_name],
  get_project_tests: (state, getters) => project_name => getters.get_project_data(project_name) ? getters.get_project_data(project_name).tests : [],
  get_selected_tests: (state, getters) => (project_name, test_name) => getters.get_project_tests(project_name).filter(t => transformTest.routeToName(transformTest.nameToRoute(t.name)) === test_name),
  get_project_results: (state, getters) => project_name => getters.get_project_data(project_name) ? getters.get_project_data(project_name).results : [],
  get_selected_results: (state, getters) => (project_name, result_name) => getters.get_project_results(project_name).filter(r => r.name === result_name),
  get_project_results_config: (state, getters) => project_name => getters.get_project_data(project_name) ? getters.get_project_data(project_name).results_config : {},
  get_project_signup: (state, getters) => project_name => getters.get_project_data(project_name) ? getters.get_project_data(project_name).signup : {},

  get_current_selected_tests: (state, getters) => test_name => getters.current_project_tests.filter(t => transformTest.routeToName(transformTest.nameToRoute(t.name)) === test_name),
  // {
  //   const result1 = transformTest.routeToName(transformTest.nameToRoute(t.name))
  //   console.log('result1: ', result1)
  //   return result1 === test_name
  // }

  // standard getters, cacheble, reactive
  current_project_data: (state, getters, rootState) => getters.get_project_data(rootState.project_name),

  use_constructor: (state, getters) => !_.isEmpty(getters.current_project_data),
  use_constructor_for: (state, getters) => some_project => !_.isEmpty(getters.get_project_data(some_project)),

  current_project_langs: (state, getters) => getters.current_project_data ? getters.current_project_data.langs : '',
  current_project_show_languages: (state, getters) => getters.current_project_data ? getters.current_project_data.show_languages : false,
  current_project_integrations_header: (state, getters) => getters.current_project_data ? getters.current_project_data.show_integrations_header : false,
  current_project_idle_timer: (state, getters) => getters.current_project_data ? getters.current_project_data.idle_timer : 0,
  current_project_show_main_page_link: (state, getters) => getters.current_project_data ? getters.current_project_data.show_main_page_link : false,
  current_project_allow_multiple_completions: (state, getters) => getters.current_project_data ? getters.current_project_data.allow_multiple_completions : false,
  current_project_show_prev_completions: (state, getters) => getters.current_project_data ? getters.current_project_data.show_prev_completions : false,
  current_project_config: (state, getters) => getters.current_project_data ? getters.current_project_data.config : {},

  current_project_test_config: (state, getters) => getters.current_project_data ? getters.current_project_data.test_config : {},
  current_project_tests: (state, getters) => getters.current_project_data ? getters.current_project_data.tests : [],
  current_tests: (state, getters, rootState, rootGetters) => getters.current_project_tests.filter(t => t.route === (rootGetters['tests/next_uncompleted_test'].name + (t.type_prop ? `:type=${t.type_prop}` : ''))),
  current_test: (state, getters) => getters.current_tests[0], // MEMO: if more than one test with same name — takes first
  current_test_logic_config: (state, getters) => getters.current_test ? getters.current_test.logic_config : {},
  current_test_presentation_config: (state, getters) => getters.current_test ? getters.current_test.presentation_config : {},
  current_test_props: (state, getters) => getters.current_test ? getters.current_test.props : {},
  current_test_css_classes: (state, getters) => getters.current_test ? getters.current_test.css_classes : [],

  current_project_results: (state, getters) => getters.current_project_data ? getters.current_project_data.results : [],
  current_project_results_config: (state, getters) => getters.current_project_data ? getters.current_project_data.results_config : {},

  current_project_signup: (state, getters) => getters.current_project_data ? getters.current_project_data.signup : {},
}

export const mutations = {
  [SET_PROJECT_DATA] (state, [ project_name, project_data ]) {
    Vue.set(state.projects, project_name, project_data)
  },
  [RESET_PROJECT_DATA] (state, project_name) {
    Vue.set(state.projects, project_name, {
      public: state.projects[project_name].public,
      app: {},
      router: {},
      storage: {},
      pages: {},
      test: {},
      tests: {},
      signup: {},
      results: {},
    })
  },
  [REMOVE_PROJECT_DATA] (state, project_name) {
    Vue.delete(state.projects, project_name)
  },
}

export const actions = {
  [A.SET_PROJECT_DATA] ({ commit }, [ project_name, project_data ]) {
    commit('SET_PROJECT_DATA', [ project_name, project_data ])
  },
  [A.RESET_PROJECT_DATA] ({ commit }, project_name) {
    commit('RESET_PROJECT_DATA', project_name)
  },
  [A.REMOVE_PROJECT_DATA] ({ commit }, project_name) {
    commit('REMOVE_PROJECT_DATA', project_name)
  },
  [A.SET_CURRENT_PROJECT_DATA] ({ commit, rootState }, project_data) {
    commit('SET_PROJECT_DATA', [ rootState.project_name, project_data ])
  },
  [A.RESET_CURRENT_PROJECT_DATA] ({ commit, rootState }) {
    commit('RESET_PROJECT_DATA', rootState.project_name)
  },
  [A.REMOVE_CURRENT_PROJECT_DATA] ({ commit, rootState }) {
    commit('REMOVE_PROJECT_DATA', rootState.project_name)
  },
}
