// eslint-disable-next-line no-unused-vars
/* global router */

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import createLogger from 'vuex/dist/logger'

// import * as projects from './modules/projects.js'
import * as projects from './modules/projects.js'
import * as users from './modules/users.js'
import * as tests from './modules/tests.js'
import * as results from './modules/results.js'
import * as completions from './modules/completions.js'
import * as apis from './modules/apis.js'

import {
  UPDATE_VERSION,
  SET_PROJECT_NAME,
  SET_LANGUAGE,
  UPDATE_LOADER,
  CLEAR_LOADER,
  ENABLE_DEV_MODE,
  DISABLE_DEV_MODE,
  SET_DEV_ID,
  TOGGLE_DEV_PANEL,
  UPDATE_IDLE_TIME,
  ALLOW_MULTIPLE_COMPLETIONS,
  FORBID_MULTIPLE_COMPLETIONS,
} from './mutation-types'
import * as A from './action-types'

// import router from '../router/router'  // router now is global Node vaiable

import config from '@/config'
import {
  show_languages_on,
  project_languages_masks,
  multiple_completions_projects,
  show_prev_results_projects
} from '@/projects-config'


const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const plugins = [
  vuexLocal.plugin,
]
if (config.node_env === 'development') plugins.push(createLogger({}))


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    projects,
    users,
    tests,
    results,
    completions,
    apis,
  },

  state: {
    version: config.package_version,
    project_name: '',
    loader: 0,
    idle_time: 0,
    dev_mode: false,
    dev_id: null,
    dev_panel_visibility: false,
    languages: [ 'ru', 'en', 'kz' ],
    language: 'ru',
    multiple_completions: false,
  },


  getters: {
    // root module getters
    app_version: state => state.version,
    is_outer_testing: state => state.project_name.match(/(-out)$/gi),
    show_languages: (state, getters) => getters['projects/use_constructor']
      ? getters['projects/current_project_show_languages']
      : show_languages_on['common'].indexOf(state.project_name) > -1
    ,
    available_languages: (state, getters) => {
      const mask = (getters['projects/use_constructor']
        ? getters['projects/current_project_langs']
        : project_languages_masks[state.project_name]
      ) || project_languages_masks['common']
      // const mask = project_languages_masks[state.project_name] || project_languages_masks['common']
      return mask.split('').map(i => state.languages[parseInt(i, 10)])
    },
    loader_visible: state => state.loader > 0,

    multiple_completions_allowed: state => multiple_completions_projects.includes(state.project_name),
    multiple_completions_allowed_for: () => project_name => multiple_completions_projects.includes(project_name),

    show_prev_results: state => show_prev_results_projects.includes(state.project_name),
    show_prev_results_for: () => project_name => show_prev_results_projects.includes(project_name),
  },


  mutations: {
    // root mutations
    [UPDATE_VERSION] (state, new_version) {
      state.version = new_version
    },
    [SET_PROJECT_NAME] (state, name = config.project_name) {
      state.project_name = name
    },
    [SET_LANGUAGE] (state, lang) {
      if (state.languages.indexOf(lang) > -1) state.language = lang
      else console.warn('Unexpected language code!')
    },
    [UPDATE_LOADER] (state, value = 0) {
      state.loader += value
    },
    [CLEAR_LOADER] (state) {
      state.loader = 0
    },
    [ENABLE_DEV_MODE] (state) {
      console.log('µ Result is committing to vuex state.dev_mode = true')
      state.dev_mode = true
    },
    [DISABLE_DEV_MODE] (state) {
      console.log('µ Result is committing to vuex state.dev_mode = false')
      state.dev_mode = false
    },
    [SET_DEV_ID] (state, is_dev) {
      console.log('µ Result is committing to vuex state.dev_id = ' + is_dev)
      state.dev_id = is_dev
    },
    [TOGGLE_DEV_PANEL] (state) {
      state.dev_panel_visibility = !state.dev_panel_visibility
    },
    [UPDATE_IDLE_TIME] (state, new_idle_time) {
      state.idle_time = new_idle_time
    },
    [ALLOW_MULTIPLE_COMPLETIONS] (state) {
      state.multiple_completions = true
    },
    [FORBID_MULTIPLE_COMPLETIONS] (state) {
      state.multiple_completions = false
    },
  },


  actions: {
    // root actions
    [A.UPDATE_VERSION] ({ commit }, new_version) {
      console.log('▲ ACTION: dispatching update_version with new_version:', new_version)
      commit(UPDATE_VERSION, new_version)
    },
    [A.SET_PROJECT_NAME] ({ commit, state, getters, dispatch }, name) {
      console.log('▲ ACTION: dispatching set_project_name with name:', name)
      const change_project = name !== state.project_name
      if (change_project) {
        console.log('▲ ACTION: CHANGING PROJECT_NAME from "' + state.project_name + '" to "' + name + '", resetting results')

        dispatch(A.SET_LANGUAGE, state.languages[0])

        if (getters.multiple_completions_allowed_for(name))
          dispatch(A.ALLOW_MULTIPLE_COMPLETIONS)
        else
          dispatch(A.FORBID_MULTIPLE_COMPLETIONS)

        dispatch('apis/reset_integration_params'),
        dispatch('users/reset_user'),
        dispatch('results/reset_all_results')
        dispatch('completions/remove_skillfolio_id'),
        dispatch('completions/reset_completions')
        dispatch('completions/reset_user_completions_hashes')
      }

      const switch_between_paired_projects = name + '-out' === state.project_name || name === state.project_name + '-out'
      if (!switch_between_paired_projects) {
        console.log('Not paired projects, clear paired completion hashes cash')
        dispatch('completions/reset_suitable_completion_hashes')
      } else console.log('Switched between paired projects, paired completions remains...')

      commit('SET_PROJECT_NAME', name)
    },
    [A.SET_JUST_PROJECT_NAME] ({ commit }, name) {
      commit('SET_PROJECT_NAME', name)
    },
    [A.RESET_PROJECT] ({ state, getters, dispatch, }) {
      return Promise.all([
        dispatch(A.SET_LANGUAGE, state.languages[0]),
        getters.multiple_completions_allowed
          ? dispatch(A.ALLOW_MULTIPLE_COMPLETIONS)
          : dispatch(A.FORBID_MULTIPLE_COMPLETIONS),
        dispatch('apis/reset_integration_params'),
        dispatch('users/reset_user'),
        dispatch('results/reset_all_results'),
        dispatch('completions/remove_skillfolio_id'),
        dispatch('completions/reset_completions'),
        dispatch('completions/reset_user_completions_hashes')
      ])
    },
    [A.SHOW_LOADER] ({ commit }) {
      console.log('▲ ACTION: dispatching show_loader')
      commit('UPDATE_LOADER', 1)
    },
    [A.HIDE_LOADER] ({ commit }) {
      console.log('▲ ACTION: dispatching hide_loader')
      commit('UPDATE_LOADER', -1)
    },
    [A.CLEAR_LOADER] ({ commit }) {
      console.log('▲ ACTION: dispatching clear_loader')
      commit('CLEAR_LOADER')
    },
    [A.DEFAULT_STORE_PREPARATION] ({ dispatch, state, getters, commit }) {
      console.log('▲ ACTION (sync): dispatching default_store_preparation')
      return new Promise((resolve, reject) => {
        console.log('▲ ACTION (async): default_store_preparation promise is called')

        // set project name:
        if (!state.project_name) commit('SET_PROJECT_NAME')
        else console.log('▲ Already set project_name, no VUEX mutations committed')

        // get appendix data & test sequence from API
        console.log('▲ state.tests._append:', state.tests._append)
        if (!state.tests._append || !Object.keys(state.tests._append).length) {
          dispatch('tests/set_appended_data')
        }
        console.log('▲ state.tests.pages:', state.tests.pages)
        console.log('▲ getters[\'tests/project_pages\']:', getters['tests/project_pages'])
        console.log('▲ state.tests.tests:', state.tests.tests)
        console.log('▲ getters[\'tests/project_tests\']:', getters['tests/project_tests'])

        const no_accessible_pages_found = !(getters['tests/project_pages'] && getters['tests/project_pages'].length)
        const no_tests_sequence_found = !(getters['tests/project_tests'] && getters['tests/project_tests'].length)

        // if (no_accessible_pages_found && no_tests_sequence_found) {
        if (true) {
          console.log('Not found pages nor tests, loading from API...')
          // set local default tests sequences to storage
          // dispatch('add_tests_sequence')
          // fetch tests sequences from API
          dispatch('tests/fetch_tests_sequence').then(() => {
            console.log('▲ API response fetched')
            resolve()
          }).catch(error => {
            console.log('▲ API error:', error)
            reject('▲ API UNAVAILABLE, TEST IS CHOSEN FROM LOCAL SEQUENCE')
          })
        } else {
          console.log('▲ Already set accessible pages or/and tests sequence, for ' + state.project_name + ' project, no API request')

          dispatch('tests/change_appended_mode', state.project_name)
          dispatch('users/set_user_age_group', getters['tests/project_age_group'])
          console.log('=========================')
          reject('▲ ALREADY GOT TESTS SEQUENCE')
        }
      })
    },
    [A.ENABLE_DEV_MODE] ({ commit }, dev_id) {
      console.log('▲ ACTION: dispatching enable_dev_mode with dev_id:' + dev_id)

      const disable_dev_mode = (dev_id === 'null')
      if (disable_dev_mode) {
        commit('DISABLE_DEV_MODE')
        commit('SET_DEV_ID', null)
        return
      }

      const active_developers = [
        // sf developers
        'vitor',
        'kapco',
        'vk',
        'ermakos',
        'ermak',
        'kiri',
        // 'ilya',
        // 'govoruhin',
        // 'ig',
        'alexey',
        'tarasov',
        'at',
        'ke',
        'cosmos',
        'cosmostas',
        'sm',
        // 'alena',
        // 'bulatova',
        // 'ab',
        'bigdata',
        // managers
        'ekaterina',
        'chu',
        'ec',
        'ulka',
        'anna',
        'mukhina',
        'am',
        // big skf developers
        'igor123',
        // asi developers
        'vorontsov',
        'test-mcko',
        'default-tester',
      ]
      const enable_dev_mode = active_developers.indexOf(dev_id) > -1
      if (enable_dev_mode) {
        commit('ENABLE_DEV_MODE')
        commit('SET_DEV_ID', dev_id)
      } else console.warn('unexpected developer! :(')
    },
    [A.DISABLE_DEV_MODE] ({ commit }) {
      console.log('▲ ACTION: dispatching disable_dev_mode')
      commit('DISABLE_DEV_MODE')
      commit('SET_DEV_ID', null)
    },
    [A.TOGGLE_DEV_PANEL] ({ commit }) {
      console.log('▲ ACTION: dispatching toggle_dev_panel')
      commit('TOGGLE_DEV_PANEL')
    },
    [A.SET_LANGUAGE] ({ commit }, lang) {
      console.log('▲ ACTION: dispatching set_language')
      commit('SET_LANGUAGE', lang)
    },
    [A.ON_IDLE] ({ state, commit }, [ cb_idle_time, cb ]) {
      console.log('▲ ACTION (sync): dispatching on_idle')

      setInterval(() => timerIncrement(), 60 * 1000)

      document.addEventListener('mousemove', () => commit('UPDATE_IDLE_TIME', 0))
      document.addEventListener('keydown', () => commit('UPDATE_IDLE_TIME', 0))

      function timerIncrement () {
        commit('UPDATE_IDLE_TIME', state.idle_time + 1)
        const minutes_before_reset = cb_idle_time / (60 * 1000)
        // console.log('idle time is over', state.idle_time, 'minute, call cb() in over', minutes_before_reset, 'minutes')
        if (state.idle_time >= minutes_before_reset) { // minutes
          // console.log('Going to call cb() in on_idle timerIncrement() action')
          cb()
        }
      }
    },
    [A.ALLOW_MULTIPLE_COMPLETIONS] ({ commit }) {
      console.log('▲ ACTION: dispatching allow_multiple_completions')
      commit('ALLOW_MULTIPLE_COMPLETIONS')
    },
    [A.FORBID_MULTIPLE_COMPLETIONS] ({ commit }) {
      console.log('▲ ACTION: dispatching forbid_multiple_completions')
      commit('FORBID_MULTIPLE_COMPLETIONS')
    },
  },


  plugins,

})
