<template>
  <div
    class="loader"
    :style="{
      backgroundImage: 'url(' + `/images/svg-loaders/rings.svg` + ')',
    }"
    title="Loading..."
  >
    <button v-if="show_close_btn" class="loader__close" @click="clear_loader">
      close
    </button>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'loader',
  props: {
  },
  data () {
    return {
      show_close_btn: false,
      show_close_timer: null,
      clear_loader_timer: null,
    }
  },
  computed: {
    ...mapState([ 'dev_mode' ]),
  },
  mounted () {
    if (this.dev_mode) {
      this.show_close_timer = setTimeout(() => this.show_close_btn = true, 5 * 1000)
    } else {
      this.clear_loader_timer = setTimeout(() => this.clear_loader(), 3 * 1000)
    }
  },
  beforeDestroy () {
    if (this.dev_mode) {
      clearTimeout(this.show_close_timer)
      this.show_close_btn = false
    } else {
      clearTimeout(this.clear_loader_timer)
    }
  },
  methods: {
    ...mapActions([ 'clear_loader' ]),
  },
}
</script>


<style lang="less" scoped>
  @import "../variables";

  .loader__close {
    position: fixed;
    right: @unitX4;
    top: @unitX4;
    transform: translate(-50%, -50%);
    width: @unitX4;
    height: @unitX4;
    border-width: 0;
    padding: @unit;
    font-size: 0;
    opacity: 0.75;
    background-color: white;
    transition: opacity 0.4s ease-in;

    &:hover,
    &:focus {
      opacity: 0.88;
      background-color: @white;
    }

    &:active {
      opacity: 1;
      background-color: @white;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: @unitX4;
      height: @unitX4;
      background-color: @transparent;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: cover;
      background-image: url('~/../../assets/i-close-cross.svg');
    }
  }

</style>
