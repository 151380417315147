const bound_suffixes = [
  '-second',
  '-third',
  '-4th',
  '-5th',
  '-6th',
  '-7th',
  '-8th',
  '-9th',
  '-10th'
]

export default function getBoundProjectNames (project_name) {
  const suffix = bound_suffixes.find(s => project_name.match(new RegExp(s + '$')))
  if (suffix) {
    const initial_project_name = project_name.slice(0, project_name.match(suffix).index)
    console.log('initial project_name:', initial_project_name)
    return [
      initial_project_name,
      ...bound_suffixes.slice(0, bound_suffixes.indexOf(suffix) + 1).map(s => initial_project_name + s)
    ]
  } else return []
}
