import axios from 'axios'
import config from '@/config'
import store from '@/store/store'

const apiBaseUrl = config.unti_api_host
const appToken = '60tolQboUanIOzdrBVtukf68yKiwYRQ2'

const apiClient = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})


export default {
  baseUrl: apiBaseUrl,
  sendStart: ({ activity_uuid, user_id, unti_token }) => {
    console.log(`Sending diagnostic start marker to Unti API`)
    return apiClient.post(`/${activity_uuid}/start`, {
      unti_id: user_id,
      token: unti_token,
      t: appToken,
    })
  },
  sendEnd: ({ activity_uuid, user_id, unti_token }) => {
    console.log(`Sending diagnostic end marker to Unti API`)
    return apiClient.post(`/${activity_uuid}/end`, {
      unti_id: user_id,
      token: unti_token,
      t: appToken,
    })
  },
  sendResult: ({ activity_uuid, user_id, unti_token, resultData }) => {
    console.log(`Sending diagnostic result to Unti API`)
    const results = resultData.skillfolio
    const resultsCalculated = resultData.results_calculated
    const resultsPairs = Object.entries(results)
    const resultsCalculatedPairs = Object.entries(resultsCalculated)
    const excludedResults = [
      'mode',
      'mode_sub'
    ]
    const clearedResultsPairs = resultsPairs
      .filter(pair => pair[1])
      .filter(pair => !excludedResults.includes(pair[0]))
    const clearedResultsCalculatedPairs = resultsCalculatedPairs.filter(pair => pair[1])
    const combinedResults = clearedResultsPairs.concat(clearedResultsCalculatedPairs)
    const extensions = combinedResults.reduce((acc, r) => {
      acc['https://my.2035.university/xapi/v1/results/skillfolio-' + r[0]] = r[1]
      return acc
    }, {})

    // const extensions = {
    //   'https://my.2035.university/xapi/v1/results/selfportrait': resultData,
    // }
    return apiClient.post(`/${activity_uuid}/lrsdata`, {
      unti_id: user_id,
      token: unti_token,
      t: appToken,
      extensions,
    })
  },

  sendCurrentStart: () => {
    console.log(`Sending diagnostic start marker to Unti API for current user`)
    return apiClient.post(`/${store.state.apis.activity_uuid}/start`, {
      unti_id: store.state.users.user_id,
      token: store.state.apis.tokens.unti,
      t: appToken,
    })
  },
  sendCurrentEnd: () => {
    console.log(`Sending diagnostic end marker to Unti API for current user`)
    return apiClient.post(`/${store.state.apis.activity_uuid}/end`, {
      unti_id: store.state.users.user_id,
      token: store.state.apis.tokens.unti,
      t: appToken,
    })
  },
  sendCurrentResult: (resultData) => {
    console.log(`Sending diagnostic result to Unti API for current user`)
    // console.log('UNTI result data:', resultData)
    const results = resultData.skillfolio
    const resultsCalculated = resultData.results_calculated
    // console.log('UNTI results:', results)
    // console.log('UNTI resultsCalculated:', resultsCalculated)
    const resultsPairs = Object.entries(results)
    const resultsCalculatedPairs = Object.entries(resultsCalculated)
    // console.log('UNTI resultsPairs:', resultsPairs)
    // console.log('UNTI resultsCalculatedPairs:', resultsCalculatedPairs)
    const excludedResults = [
      'mode',
      'mode_sub'
    ]
    const clearedResultsPairs = resultsPairs
      .filter(pair => pair[1])
      .filter(pair => !excludedResults.includes(pair[0]))
    const clearedResultsCalculatedPairs = resultsCalculatedPairs.filter(pair => pair[1])
    // console.log('UNTI clearedResultsPairs:', clearedResultsPairs)
    // console.log('UNTI crearedResultsCalculatedPairs:', clearedResultsCalculatedPairs)

    const combinedResults = clearedResultsPairs.concat(clearedResultsCalculatedPairs)
    console.log('UNTI combinedResults:', combinedResults)
    const extensions = combinedResults.reduce((acc, r) => {
      acc['https://my.2035.university/xapi/v1/results/skillfolio-' + r[0]] = r[1]
      return acc
    }, {})
    console.log('UNTI extensions:', extensions)

    // const extensions = {
    //   'https://my.2035.university/xapi/v1/results/selfportrait': resultData,
    // }
    return apiClient.post(`/${store.state.apis.activity_uuid}/lrsdata`, {
      unti_id: store.state.users.user_id,
      token: store.state.apis.tokens.unti,
      t: appToken,
      extensions,
    })
  },


}
