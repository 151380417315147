import Hypher from 'hypher'
import russian from 'hyphenation.ru'
// import english from 'hyphenation.en-us'

console.log('Hyphenation.js called')

const hypher_ru = new Hypher(russian)
// const hypher_en = new Hypher(english)

export default {
  // eslint-disable-next-line no-unused-vars
  hyphenate (text, lang = 'ru') {
    // const hypher = lang === 'ru' ? hypher_ru : hypher_en
    const hypher = hypher_ru
    return hypher.hyphenate(text).join('\u00AD')
      .replace(/\u00AD\s\u00AD?|\s\u00AD/gi, ' ')
      .replace(/\u00AD\,/gi, ',')
      .replace(/\u00AD\./gi, '.')
      .replace(/\u00AD\u00bb\u00bb/gi, '\u00bb')
      .replace(/\u00ab\u00AD/gi, '\u00ab')
  }
}
