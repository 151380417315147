import config from '@/config'
import axios from 'axios'

const apiBaseUrl = config.caseid_api_host
const apiClient = axios.create({
  baseURL: apiBaseUrl,
  // timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
    // 'X-Auth-Token': '',
  }
})


export default {
  baseUrl: apiBaseUrl,
  saveWithSkillfolioUtm: (utm_source, send_data) => {
    console.log(`Saving '${utm_source}' skillfolio utm with completion data to Caseid API's SkillfolioUtmView`)
    return apiClient.post(`/skillfolioutms/${utm_source}/students/`, send_data)
  },
  saveToAsiApi: send_data => {
    console.log(`Saving completion data to Asi API through Caseid API's AsiView`)
    return apiClient.post('asi/', send_data)
  }
}
