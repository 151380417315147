console.log('©©© ©©© ©©© ©©© ©©© ©©© ©©© ©©© ©©©')
console.log('©©© config.js is loading...©©©')

const VUE_APP_NODE_ENV = (process.env.VUE_APP_NODE_ENV === 'undefined') ? null : process.env.VUE_APP_NODE_ENV
const VUE_APP_ENVIRONMENT = (process.env.VUE_APP_ENVIRONMENT === 'undefined') ? null : process.env.VUE_APP_ENVIRONMENT
// const PROJECT_NAME = (process.env.PROJECT_NAME === 'undefined') ? null : process.env.PROJECT_NAME
const VUE_APP_PROJECT_NAME = (process.env.VUE_APP_PROJECT_NAME === 'undefined') ? null : process.env.VUE_APP_PROJECT_NAME
// const API_HOST = (process.env.API_HOST === 'undefined') ? null : process.env.API_HOST
const VUE_APP_API_HOST = (process.env.VUE_APP_API_HOST === 'undefined') ? null : process.env.VUE_APP_API_HOST
const VUE_APP_TEST_SEQUENCE_API_HOST = (process.env.VUE_APP_TEST_SEQUENCE_API_HOST === 'undefined') ? null : process.env.VUE_APP_TEST_SEQUENCE_API_HOST
const VUE_APP_HELPERS_API_HOST = (process.env.VUE_APP_HELPERS_API_HOST === 'undefined') ? null : process.env.VUE_APP_HELPERS_API_HOST
const VUE_APP_STATIC_HOST = (process.env.VUE_APP_STATIC_HOST === 'undefined') ? null : process.env.VUE_APP_STATIC_HOST + '/'
const VUE_APP_PACKAGE_VERSION = (process.env.VUE_APP_PACKAGE_VERSION === 'undefined') ? null : process.env.VUE_APP_PACKAGE_VERSION

console.log('©©© VUE_APP_NODE_ENV:', VUE_APP_NODE_ENV)
console.log('©©© VUE_APP_ENVIRONMENT:', VUE_APP_ENVIRONMENT)
// console.log('©©© PROJECT_NAME:', PROJECT_NAME)
console.log('©©© VUE_APP_PROJECT_NAME:', VUE_APP_PROJECT_NAME)
// console.log('©©© API_HOST:', API_HOST)
console.log('©©© VUE_APP_API_HOST:', VUE_APP_API_HOST)
console.log('©©© VUE_APP_TEST_SEQUENCE_API_HOST :', VUE_APP_TEST_SEQUENCE_API_HOST )
console.log('©©© VUE_APP_HELPERS_API_HOST :', VUE_APP_HELPERS_API_HOST )
console.log('©©© VUE_APP_STATIC_HOST:', VUE_APP_STATIC_HOST)
console.log('©©© VUE_APP_PACKAGE_VERSION:', VUE_APP_PACKAGE_VERSION)

console.log('=========================')

export default {
  package_version: VUE_APP_PACKAGE_VERSION || '0.0.0',
  node_env: VUE_APP_NODE_ENV || 'production',
  environment: VUE_APP_ENVIRONMENT || 'development',
  static_host: VUE_APP_STATIC_HOST || 'https://cdn.skillfolio.ru/',
  // static_host: VUE_APP_STATIC_HOST ||
  //   (VUE_APP_ENVIRONMENT === 'stage') ?
  //   'http://test.stage.skillfolio.ru/'
  //   :
  //   'https://cdn.skillfolio.ru/',
  main_host: 'https://skillfolio.ru',

  // Services APIs
  skillfolio_api: 'https://api.skillfolio.ru',
  test_data_api: 'https://test-data.skillfolio.ru',
  // api_host: 'https://api.skillfolio.ru',
  caseid_api_host: VUE_APP_API_HOST || 'https://test-api.caseid.ru',
  test_sequence_api_host: VUE_APP_TEST_SEQUENCE_API_HOST || 'https://test-my.caseid.ru/api',
  helpers_api_host: VUE_APP_HELPERS_API_HOST || 'https://api.skillfolio.ru/helpers/',
  unti_api_host: 'https://bypass.2035.university/api/activity',
  yandex_dict_api: 'https://dictionary.yandex.net/api/v1/dicservice.json/lookup',

  project_name: VUE_APP_PROJECT_NAME || 'common',
  _default_appendix: {
    skillfolio: {
      // mode: (['asi',].indexOf(VUE_APP_PROJECT_NAME) > -1) ? 'kid' :  VUE_APP_PROJECT_NAME
      mode: VUE_APP_PROJECT_NAME
    }
  },
}
