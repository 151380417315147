<template>
  <div class="progressbarmini" :class="{done, innn}" />
</template>

<script>
export default {
  name: 'progressbarmini',
  props: {
    'done': Boolean,
    'last': Boolean,
    'borderMode': String
  },
  data () {
    return {
      inn: true,
    }
  },
  computed: {
    innn () {
      return this.last && this.inn
    }
  },
  mounted () {
    this.progress_timeout_m = setTimeout(() => this.inn = false, 200)
  }
}
</script>

<style lang="less" scoped>

  @import "../variables";

  .progressbarmini {
    border-radius: @bdrs_half;
    //border: 1px solid @blue;
    border: var(--progress-border);
    width: @unit;
    height: @unit;
    margin: 0 3px;
    transition: width 0.5s ease;
    background-color: var(--progress-background-color);
    &.innn {
      width: 100px;
    }
    &.done {
      //background-color: @blue;
      background-color: var(--progress-fill-color);
    }
  }
</style>
