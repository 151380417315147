import _ from 'lodash'

// MEMO: always remove '-out' from the end of project dir name -> same tests taken for in / out projects
function getCustomDir (project_name, borrowed_project_name, receivers_list) {
  // console.log('getCustomDir(' + project_name + ', ' + borrowed_project_name + ', [' + receivers_list + ']' + ') called')
  if (!project_name) return ''

  return receivers_list.reduce((accum, receiver) => {
    if (typeof receiver === 'string') {
      let asterisk_index = receiver.indexOf('*')
      // console.log('asterisk_index:', asterisk_index)
      if (asterisk_index > -1) {
        let regex_parts = [ receiver.slice(0, asterisk_index), receiver.slice(asterisk_index + 1) ]
        // console.log('regex_parts:', regex_parts)
        let regex_matches = project_name.match(new RegExp(`(^${regex_parts[0]})(.*)(${regex_parts[1]})`, 'i'))
        // console.log('regex_matches:', regex_matches)
        if (regex_matches) {
          accum = borrowed_project_name ||
                  regex_matches[1] + (
                    (regex_matches[3])
                      ? '-' + regex_matches[3].replace(/(-out)$/gi, '')
                      : ''
                  )
          accum = accum + '/'
        }
      } else {
        // console.log('borrowed_project_name:', borrowed_project_name)
        // console.log('project_name:', project_name)
        const used_project_name = borrowed_project_name || project_name
        // console.log('used_project_name:', used_project_name)
        accum = ( receiver === project_name )
          ? used_project_name.replace(/(-out)$/gi, '') + '/'
          : accum
      }
    } else if (receiver instanceof Array) {
      accum = getCustomDir(project_name, receiver[0], receiver[1]) || accum
    } else console.warn('Unknown receiver type:', typeof receiver)
    // console.log('accum:', accum)
    return accum
  }, '')
}

export default _.curry(getCustomDir)
