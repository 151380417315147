import Vue from 'vue'
import _ from 'lodash'
import TestDataApi from '@/services/TestDataApi'
import TestSequenceApi from '@/services/TestSequenceApi'
// import utils from '@/utils'
// const transformTest = utils.transformTest

import {
  ADD_PROJECT_AGE_GROUP,
  REMOVE_PROJECT_AGE_GROUP,
  REMOVE_PROJECT_AGE_GROUPS,
  // TODO: move pages & accessible pages to separate store module
  ADD_ACCESSIBLE_PAGES,
  REMOVE_ACCESSIBLE_PAGES,
  ADD_TESTS_SEQUENCE,
  REMOVE_TESTS_SEQUENCE,
  SET_APPENDED_DATA,
  CHANGE_APPENDED_MODE,
  ADD_SINGLE_TEST
} from '../mutation-types'
import * as A from '../action-types'

import config from '../../config'

import local_test_sequences from '../../views/local-test-sequences.js'
// TODO: move pages & accessible pages to separate store module
import local_accessible_pages from '../../views/local-accessible-pages.js'
import local_test_age_groups from '../../views/local-test-age-groups.js'

import { pages_config, tests_config } from '@/projects-config'
const custom_page_receivers = pages_config.custom_page_receivers
const custom_test_receivers = tests_config.custom_test_receivers
import getCustomDir from '@/functions/getCustomDir'


export const namespaced = true

export const state = {
  pages: {},
  tests: {},
  age_groups: {},
  _append: {
    skillfolio: {}
  },
}

export const getters = {
  get_project_pages: state => project_name => state.pages[project_name],
  get_project_age_group: state => project_name => state.age_groups[project_name],
  get_project_tests: state => project_name => state.tests[project_name],
  tests_completed: (state, getters, rootState, rootGetters) => project_name => _.every(getters.get_project_tests(project_name), test => rootGetters['results/user_results'].hasOwnProperty(test)), // uses results module getters
  project_pages: (state, getters, rootState) => {
    return state.pages[rootState.project_name]
  },
  project_pages_directories: (state, getters, rootState) => {
    console.log('rootState.project_name:', rootState.project_name)
    const defaultDir = 'default/'
    if (!rootState.project_name) {
      // return getters.project_pages.reduce((acc, p) => {
      return Object.entries(custom_page_receivers)
        .reduce((acc, pair) => {
          // acc[p] = defaultDir
          acc[pair[0]] = defaultDir
          return acc
        }, {})
    }

    try {
      const getCustomDirForCurrentProject = getCustomDir(rootState.project_name, null)
      console.log('getCustomDirForCurrentProject:', getCustomDirForCurrentProject)
      // return getters.project_pages.reduce((acc, p) => {
      return Object.entries(custom_page_receivers).reduce((acc, pair) => {
        // acc[p] = getCustomDirForCurrentProject(custom_page_receivers[p] || []) || defaultDir
        acc[pair[0]] = getCustomDirForCurrentProject(pair[1] || []) || defaultDir
        // console.log(`acc[${pair[0]}]:`, acc[pair[0]])
        return acc
      }, {})
    } catch (err) {
      console.warn('project_pages_directories errored:', err)
    }
  },
  project_age_group: (state, getters, rootState) => { // uses root module state
    return state.age_groups[rootState.project_name]
  },
  project_tests: (state, getters, rootState) => { // uses root module state
    return state.tests[rootState.project_name]
  },
  // project_tests_cleaned: (state, getters) => {
  //   return getters.project_tests.map(transformTest.cleanRoute)
  // },
  project_tests_directories: (state, getters, rootState) => {
    // console.log('rootState.project_name:', rootState.project_name)
    const defaultDir = 'default/'
    if (!rootState.project_name) {
      // return getters.project_tests_cleaned.reduce((acc, t) => {
      return Object.entries(custom_test_receivers)
        .reduce((acc, pair) => {
          // acc[t] = defaultDir
          acc[pair[0]] = defaultDir
          return acc
        }, {})
    }

    try {
      const getCustomDirForCurrentProject = getCustomDir(rootState.project_name, null)
      console.log('getCustomDirForCurrentProject:', getCustomDirForCurrentProject)
      // return getters.project_tests_cleaned.reduce((acc, t) => {
      return Object.entries(custom_test_receivers).reduce((acc, pair) => {
        // acc[t] = getCustomDirForCurrentProject(t) || []) || defaultDir
        acc[pair[0]] = getCustomDirForCurrentProject(pair[1] || []) || defaultDir
        // console.log(`acc[${pair[0]}]:`, acc[pair[0]])
        return acc
      }, {})
    } catch (err) {
      console.warn('project_tests_directories errored:', err)
    }
  },
  project_tests_completed: (state, getters, rootState, rootGetters) => _.every(
    getters.get_project_tests(rootState.project_name),
    test => { // uses root module state & results module getters
      const testFirstParamIndex = test.indexOf(':')
      // console.log('testFirstParamIndex:', testFirstParamIndex)
      const testGotParams = testFirstParamIndex > -1
      // console.log('testGotParams:', testGotParams)
      const testName = testGotParams ? test.slice(0, testFirstParamIndex) : test
      // console.log('testName:', testName)
      console.log('↺↺ project_tests_completed, name of test after param trimming:', testName)

      const gotTestResult = rootGetters['results/user_results'].hasOwnProperty(testName)
      console.log('gotTestResult:', gotTestResult)
      if (!gotTestResult) return false
      else if (!testGotParams) return true // subject

      const parameters = test.match(/:([^:]+)/gmi)
      console.log(`↺↺ project_tests_completed, checking test ${test} parameters: \n${parameters}`)
      if (!parameters) return true // subject:
      else if (parameters && parameters.length) {
        // console.log(`rootGetters['results/user_results'][testName]:`, rootGetters['results/user_results'][testName])
        const parametrisedTestCompleted = parameters.reduce((accObj, param) => {
          // console.log('accObj:', accObj)
          // console.log('param:', param)
          const keyValueDividerIndex = param.indexOf('=')
          // console.log('keyValueDividerIndex:', keyValueDividerIndex)
          const doResultsObjectPropsLookup = keyValueDividerIndex > -1
          // console.log('doResultsObjectPropsLookup:', doResultsObjectPropsLookup)
          if (doResultsObjectPropsLookup) { // subject:category=school
            // const paramType = param.slice(0, keyValueDividerIndex)  // category
            const paramName = param.slice(keyValueDividerIndex + 1) // school
            // console.log('paramName:', paramName)
            const newAcc = (accObj[paramName] != null) ? accObj[paramName] : null
            // console.log('newAcc:', newAcc)
            return newAcc
          } else return accObj // subject:age
        }, rootGetters['results/user_results'][testName])
        console.log('parametrisedTestCompleted:', parametrisedTestCompleted)
        return (parametrisedTestCompleted != null) ? true : false
      }
    }
  ),
  next_uncompleted_test: (state, getters, rootState, rootGetters) => { // uses users module state & results module state & getters
    if (getters.project_tests) {
      const next_route = getters.project_tests.reduce((next_test, test) => {
        if (next_test.name) return next_test // pass reduce cycle, if already got name

        console.log('↺↺ RECALCULATING next_uncompleted_test ↺↺')
        // console.log('↺↺ next_uncompleted_test, getters.project_tests.find(test =', test, ')')
        // console.log('↺↺ next_uncompleted_test, rootState.users.user_id', rootState.users.user_id)
        // console.log('↺↺ next_uncompleted_test, rootState.results.results[rootState.users.user_id]', rootState.results.results[rootState.users.user_id])
        // console.log('↺↺ next_uncompleted_test, rootGetters[\'results/user_results\']', rootGetters['results/user_results'])
        console.log('↺↺ next_uncompleted_test, given name of test:', test)

        const testFirstParamIndex = test.indexOf(':')
        // console.log('testFirstParamIndex:', testFirstParamIndex)
        const testGotParams = testFirstParamIndex > -1
        // console.log('testGotParams:', testGotParams)
        const testName = testGotParams ? test.slice(0, testFirstParamIndex) : test
        // console.log('testName:', testName)
        console.log('↺↺ next_uncompleted_test, name of test after param trimming:', testName)

        const gotTestResult = rootGetters['results/user_results'].hasOwnProperty(testName)
        console.log('gotTestResult:', gotTestResult)
        const parameters = test.match(/:([^:]+)/gmi)
        console.log(`↺↺ next_uncompleted_test, checking test ${test} parameters: \n${parameters}`)
        let parametrisedTestCompleted = true

        if (gotTestResult && !testGotParams) return next_test
        if (gotTestResult && !parameters) return next_test
        else if (parameters && parameters.length) {
          const user_test_results = rootGetters['results/user_results'][testName]
          // console.log(`rootGetters['results/user_results'][testName]:`, user_test_results)
          if (!user_test_results) parametrisedTestCompleted = false
          else {
            parametrisedTestCompleted = parameters.reduce((accObj, param) => {
              // console.log('accObj:', accObj)
              // console.log('param:', param)
              const keyValueDividerIndex = param.indexOf('=')
              // console.log('keyValueDividerIndex:', keyValueDividerIndex)
              const doResultsObjectPropsLookup = keyValueDividerIndex > -1
              // console.log('doResultsObjectPropsLookup:', doResultsObjectPropsLookup)
              if (doResultsObjectPropsLookup) { // subject:category=school
                // const paramType = param.slice(0, keyValueDividerIndex)  // category
                const paramName = param.slice(keyValueDividerIndex + 1) // school
                // console.log('paramName:', paramName)
                const newAcc = (accObj[paramName] != null) ? accObj[paramName] : null
                // console.log('newAcc:', newAcc)
                return newAcc
              } else return accObj // subject:age
            }, rootGetters['results/user_results'][testName])
          }
          console.log('parametrisedTestCompleted:', parametrisedTestCompleted)
        }

        // const is_next_route = !rootGetters['results/user_results'].hasOwnProperty(test)
        const is_next_route = !gotTestResult || (gotTestResult && (parametrisedTestCompleted === null || parametrisedTestCompleted === undefined))
        // console.log('↺↺ next_uncompleted_test, rootState.results.results[rootState.users.user_id]:', rootState.results.results[rootState.users.user_id])
        // console.log('↺↺ next_uncompleted_test, rootGetters[\'results/user_results\']:', rootGetters['results/user_results'])
        console.log('↺↺ next_uncompleted_test, is_next_route:', is_next_route)

        if (is_next_route) {
          next_test.name = testName
          // console.log('next_test.name =', test)
          next_test.params = {}
          if (parameters && parameters.length) {
            parameters.forEach((param) => {
              // console.log('(before replace) param:', param)
              param = param.replace(':', '')
              // console.log('(after replace) param:', param)
              const pairDivider = param.indexOf('=')
              const valueInUsersModule = rootState.users[param]
              // console.log('valueInUsersModule:', valueInUsersModule)
              if (pairDivider === -1 && (valueInUsersModule || valueInUsersModule === null) ) {
                console.log('using static route :key param')
                next_test.params[param] = valueInUsersModule
              } else {
                console.log('using custom route :key=pair param')
                next_test.params[param.slice(0, pairDivider)] = param.slice(pairDivider + 1)
              }
            })
          }
        }

        return next_test
      }, {})
      console.log('next_route is:', next_route)

      return next_route
    } else return null
  },
  is_custom_test: (state, getters) => test_name => getters.project_tests_directories[test_name] !== 'default/',
  is_next_uncompleted_test_custom: (state, getters) => getters.is_custom_test(getters.next_uncompleted_test.name),
}

// hasn't got access to rootState, rootGetter or anything, should be the pure fn
// if such behaviour is needed — better to move it out to the corresponding action
export const mutations = {
  [ADD_PROJECT_AGE_GROUP] (state, [ age_group, project_name ]) {
    age_group = age_group || local_test_age_groups[project_name] || local_test_age_groups['common'] // closure fallback
    state.age_groups[project_name] = age_group
  },
  [REMOVE_PROJECT_AGE_GROUP] (state, project_name) {
    if (state.age_groups[project_name]) Vue.delete(state.tests, project_name)
  },
  [REMOVE_PROJECT_AGE_GROUPS] (state) {
    state.age_groups = {}
  },
  // TODO: move pages & accessible pages to separate store module
  [ADD_ACCESSIBLE_PAGES] (state, [ accessible_pages, project_name ]) {
    accessible_pages = accessible_pages || local_accessible_pages[project_name] || local_accessible_pages['common'] // closure fallback
    if (!state.pages[project_name]) state.pages[project_name] = []
    // state.pages[project_name] = accessible_pages
    state.pages = { ...state.pages, [project_name]: accessible_pages }
  },
  [REMOVE_ACCESSIBLE_PAGES] (state, project_name) {
    if (state.pages[project_name]) Vue.delete(state.pages, project_name)
  },
  [ADD_TESTS_SEQUENCE] (state, [ tests_sequence, project_name ]) {
    tests_sequence = tests_sequence || local_test_sequences[project_name] || local_test_sequences['common'] // closure fallback
    if (!state.tests[project_name]) state.tests[project_name] = []
    // state.tests[project_name] = tests_sequence
    state.tests = { ...state.tests, [project_name]: tests_sequence }
  },
  [REMOVE_TESTS_SEQUENCE] (state, project_name) {
    if (state.tests[project_name]) Vue.delete(state.tests, project_name)
  },
  // TODO: add switching test sequence logic
  [SET_APPENDED_DATA] (state, appended_data = config._default_appendix) { // closure fallback
    state._append = appended_data
  },
  [CHANGE_APPENDED_MODE] (state, mode = config._default_appendix.skillfolio.mode) { // closure fallback
    if (state._append.skillfolio) {
      state._append.skillfolio.mode = mode
      // state._append = {
      //   ...state.append,
      //   skillfolio: {
      //     ...state.append.skillfolio,
      //     mode: mode
      //   }
      // }
      console.log('µ MUTATION: state._append:', state._append)
    } else console.log('µ MUTATION: unexpected ._append - no .skillfolio property!!!')
  },
  // TODO: add corresponding action and logic
  [ADD_SINGLE_TEST] (state, [ test, project_name ]) {
    if (!state.tests[project_name]) state.tests[project_name] = []
    state.tests[project_name].push(test)
    // state.tests = {...tests}
  },
}

export const actions = {
  [A.ADD_PROJECT_AGE_GROUP] ({ commit, rootState }, age_group) {
    if (!age_group) console.log('▲ ACTION: using DEFAULT age group')
    commit('ADD_PROJECT_AGE_GROUP', [ age_group, rootState.project_name ])
  },
  [A.REMOVE_PROJECT_AGE_GROUP] ({ commit, rootState }, project_name) {
    commit('REMOVE_PROJECT_AGE_GROUP', project_name || rootState.project_name)
  },
  [A.REMOVE_PROJECT_AGE_GROUPS] ({ commit }) {
    commit('REMOVE_PROJECT_AGE_GROUPS')
  },
  // TODO: move pages & accessible pages to separate store module
  [A.ADD_ACCESSIBLE_PAGES] ({ commit, rootState }, accessible_pages) {
    if (!accessible_pages) console.log('▲ ACTION: using DEFAULT accessible_pages')
    commit('ADD_ACCESSIBLE_PAGES', [ accessible_pages, rootState.project_name ])
  },
  [A.REMOVE_ACCESSIBLE_PAGES] ({ commit, rootState }, project_name) {
    commit('REMOVE_ACCESSIBLE_PAGES', project_name || rootState.project_name)
  },
  [A.ADD_TESTS_SEQUENCE] ({ commit, rootState }, tests_sequence) {
    if (!tests_sequence) console.log('▲ ACTION: using DEFAULT tests_sequence')
    commit('ADD_TESTS_SEQUENCE', [ tests_sequence, rootState.project_name ])
  },
  [A.REMOVE_TESTS_SEQUENCE] ({ commit, rootState }, project_name) {
    commit('REMOVE_TESTS_SEQUENCE', project_name || rootState.project_name)
  },
  [A.SET_APPENDED_DATA] ({ commit }, appended_data) {
    commit('SET_APPENDED_DATA', appended_data)
  },
  [A.CHANGE_APPENDED_MODE] ({ commit }, new_mode) {
    // let mode_switch = ['asi'].indexOf(new_mode) > -1
    // if (mode_switch) {
    //   let switch_to = 'kid'
    //   console.log('appended mode was changed implicitly from:', new_mode, 'to:', switch_to)
    //   new_mode = switch_to
    // }
    commit('CHANGE_APPENDED_MODE', new_mode)
  },
  // TODO: add switch between test-sequence-api and test-data api
  [A.FETCH_TESTS_SEQUENCE] ({ commit, rootState, rootGetters, dispatch }) {
    console.log('▲ ACTION (sync): dispatching fetch_tests_sequence')
    // const API_HOST_URL = `${config.test_sequence_api_host}/?t=${rootState.project_name}`
    // console.log('▲ ACTION (sync): API_HOST_URL:', API_HOST_URL)
    dispatch('show_loader', null, { root: true })

    console.log(' +++ ', rootGetters['projects/current_project_data'])
    dispatch('projects/remove_current_project_data', null, { root: true })
    console.log('▲ ACTION (sync): Trying to use new test-data api project constructor')
    return TestDataApi.getProjectConfig(rootState.project_name)
      .then(saveDefaultDataToStore)
      .then(saveConstructorDataToStore)
      .catch((e) => {
        console.warn('▲ new API error: ' + e)
        console.log('▲ ACTION: No test in constructor, try to use default test-sequence-api ')
        return TestSequenceApi.getProjectConfig(rootState.project_name)
          .then(saveDefaultDataToStore)
          .catch(useLocalDataOnError)
      })
      .finally(finalActions)


    function saveDefaultDataToStore (response) {
      console.log('=========================')
      console.log('▲ ACTION (async): API_HOST response:', response)
      if (response.data) {
        const appended_data = response.data._append
        console.log('▲ RECEIVED appended_data:', appended_data)
        commit('SET_APPENDED_DATA', appended_data)

        const appropriate_age_group = response.data.appropriate_age_group
        console.log('▲ RECEIVED appropriate age:', appropriate_age_group)
        if (appropriate_age_group && appropriate_age_group !== 'all') {
          dispatch('users/set_user_age_group', appropriate_age_group, { root: true })
          dispatch('add_project_age_group', appropriate_age_group)
        } else {
          dispatch('users/reset_user_age_group', null, { root: true })
          dispatch('remove_project_age_group')
        }

        // TODO: move pages & accessible pages to separate store module
        const accessible_pages = response.data.accessible_pages
        console.log('▲ RECEIVED accessible_pages:', accessible_pages)
        dispatch('add_accessible_pages', accessible_pages)

        const tests_sequence = response.data.tests_sequence
        console.log('▲ RECEIVED tests_sequence:', tests_sequence)
        dispatch('add_tests_sequence', tests_sequence)
        // commit('ADD_TESTS_SEQUENCE', [tests_sequence, rootState.project_name])

        return response.data
      } else {
        console.log('▲ Error in API response, using local test sequence and appropriate age')
        commit('SET_APPENDED_DATA')
        dispatch('add_project_age_group')
        dispatch('add_accessible_pages')
        dispatch('add_tests_sequence')
      }
    }

    function saveConstructorDataToStore (constructorData) {
      console.log('constructor data is:', constructorData)
      dispatch('projects/set_current_project_data', constructorData, { root: true })
      constructorData.allow_multiple_completions
        ? dispatch(A.ALLOW_MULTIPLE_COMPLETIONS, null, { root: true })
        : dispatch(A.FORBID_MULTIPLE_COMPLETIONS, null, { root: true })
    }

    function useLocalDataOnError (error) {
      console.log('=========================')
      console.warn('▲ API error: ' + error)
      console.log('▲ API unavailable, using local appendix, age, and tests sequence for: ' + this.tests_sequence)
      commit('SET_APPENDED_DATA')
      dispatch('add_project_age_group')
      dispatch('add_accessible_pages')
      dispatch('add_tests_sequence')
    }

    function finalActions () {
      console.log('at fetch_tests_sequence finalization');
      dispatch('hide_loader', null, { root: true })
    }
  }

}

