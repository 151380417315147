/* global router */

import _ from 'lodash'
import Vue from 'vue'
import config from '../../config'
import utils from '../../utils'
import { show_languages_on, project_languages_masks } from '@/projects-config'

import {
  ADD_COMPLETION,
  REMOVE_COMPLETION,
  REMOVE_USER_COMPLETION,
  UPDATE_COMPLETION_RESULTS_CALCULATED,
  UPDATE_COMPLETION_REQUEST_ROOT,
  RESET_COMPLETIONS,
  ADD_SKILLFOLIO_ID,
  REMOVE_SKILLFOLIO_ID,
  ADD_USER_COMPLETIONS_HASHES,
  ADD_HASH_TO_USER_COMPLETIONS_HASHES,
  CLEAR_USER_COMPLETIONS_HASHES,
  RESET_USER_COMPLETIONS_HASHES,
  ADD_SUITABLE_COMPLETION_HASHES_PAIR,
  RESET_SUITABLE_COMPLETION_HASHES
} from '../mutation-types'

import * as A from '../action-types'


export const namespaced = true

export const state = {
  completions: { null: { results: {}, info: {}, results_calculated: {}, request_root: {}}},
  skillfolio_id: null,
  user_completions_hashes: {
    common: {
      'test-user-123': [],
    }
  },
  suitable_completion_hashes: {},
}

export const getters = {
  // skillfolio test done user results  // FIXIT: change hash to skillfolio_id, which used here
  user_completion: state => hash => state.completions[hash],
  user_completion_results: state => hash => state.completions[hash] ? state.completions[hash].results : false,
  user_completion_results_calculated: state => hash => state.completions[hash] ? state.completions[hash].results_calculated : false,
  user_completion_info: state => hash => state.completions[hash] ? state.completions[hash].info : false,
  user_completion_request_root: state => hash => state.completions[hash] ? state.completions[hash].request_root : false,
  get_suitable_completion_hash: state => outer_hash => state.suitable_completion_hashes[outer_hash],

  // FIXME: check that next 3 lines not overkill, maybe can refactor them
  user_completion_creation_date: (state, getters) => hash => getters.user_completion(hash) ? getters.user_completion(hash).request_root._created : false,
  user_completion_user_id: (state, getters) => hash => getters.user_completion_info(hash) ? getters.user_completion_info(hash).user_id : false,
  user_completion_project_name: (state, getters) => hash => getters.user_completion_results(hash) ? getters.user_completion_results(hash).mode : false,

  // TODO: can add checking active user results not only by rootState.users.user_id, but also by rootState.results.u_info.email
  active_user_completion: (state, getters, rootState) => state.completions[_.findKey(state.completions, (o) => o.info.user_id === rootState.users.user_id)], // uses users module state
  active_user_completion_results: (state, getters) => getters.active_user_completion ? getters.active_user_completion.results : false,
  active_user_completion_results_calculated: (state, getters) => getters.active_user_completion ? getters.active_user_completion.results_calculated : false,
  active_user_completion_info: (state, getters) => getters.active_user_completion ? getters.active_user_completion.info : false,
  active_user_completion_request_root: (state, getters) => getters.active_user_completion ? getters.active_user_completion.request_root : false,

  active_user_completion_hash: (state, getters) => getters.active_user_completion ? getters.active_user_completion.request_root._id : false,
  active_user_completion_creation_date: (state, getters) => getters.active_user_completion ? getters.active_user_completion.request_root._created : false,
  active_user_completion_user_id: (state, getters) => getters.active_user_completion_info ? getters.active_user_completion_info.user_id : false,
  active_user_completion_project_name: (state, getters) => getters.active_user_completion_results ? getters.active_user_completion_results.mode : false,

  active_user_completions_hashes: (state, getters, rootState, rootGetters) => state.user_completions_hashes[rootState.project_name] ? state.user_completions_hashes[rootState.project_name][rootGetters['users/user_id']] : false,

  user_completion_results_list (state, getters) {
    return (hash) => Object.keys(getters.user_completion_results(hash))
  },
  user_completion_results_calculated_list (state, getters) {
    return (hash) => Object.keys(getters.user_completion_results_calculated(hash))
  },
  user_completion_info_list (state, getters) {
    return (hash) => Object.keys(getters.user_completion_info(hash))
  },

  completion_show_languages: (state, getters, rootState, rootGetters) => hash => {
    const completion_project_name = getters.user_completion_project_name(hash)
    return rootGetters['projects/should_use_constructor_for'](completion_project_name)
      ? rootGetters['projects/get_project_data'](completion_project_name)
        ? rootGetters['projects/get_project_data'](completion_project_name).show_languages
        : ''
      : show_languages_on['common'].indexOf(completion_project_name) > -1
  },
  completion_available_languages: (state, getters, rootState, rootGetters) => hash => {
    const completion_project_name = getters.user_completion_project_name(hash)
    const mask = (rootGetters['projects/should_use_constructor_for'](completion_project_name)
      ? rootGetters['projects/get_project_data'](completion_project_name)
        ? rootGetters['projects/get_project_data'](completion_project_name).langs
        : ''
      : project_languages_masks[completion_project_name]
    ) || project_languages_masks['common']
    return mask.split('').map(i => rootState.languages[parseInt(i, 10)])
  },
}

// hasn't got access to rootState, rootGetter or anything, should be the pure fn
// if such behaviour is needed — better to move it out to the corresponding action
export const mutations = {
  [ADD_COMPLETION] (state, [ hash, results, results_calculated, info, request_root ]) { // FIXIT: change hash to SkillfolioID
    console.log(`µ Committing addition of test results with skillfolio id in vuex store: state.completions[${hash}] = { info: ${info}, results: ${results}, results_calculated: ${results_calculated}, request_root: ${request_root} }`)
    Vue.set(state.completions, hash, {
      results: { ...results },
      results_calculated: { ...results_calculated },
      info: { ...info },
      request_root: { ...request_root },
    })
  },
  [REMOVE_COMPLETION] (state, hash) { // FIXIT: change hash to SkillfolioID
    console.log(`µ Committing removing of done test results with skillfolio id in vuex store: state.completions[${hash}] = { results: {}, info: {}, results_calculated: {} }`)
    state.completions[hash] = { results: {}, info: {}, results_calculated: {}, request_root: {}}
  },
  [REMOVE_USER_COMPLETION] (state, user_id) { // -- uses users module state
    console.log(`µ Committing removing of done test results with skillfolio id in vuex store: state.completions[${user_id}] = { results: {}, info: {}, results_calculated: {} }`)
    // state.completions[user_id] = { results: {}, info: {}, results_calculated: {} }
    if (user_id === null) {
      console.warn('not clearing skillfolio done results for default user (they are not usable!!!)')
      return
    }
    // state.completions[_.findKey(state.completions, (o) => o.info.user_id === state.user_id)] = { results: {}, info: {}, results_calculated: {} }
    delete state.completions[_.findKey(state.completions, (o) => o.info.user_id === user_id)]
  },
  [UPDATE_COMPLETION_RESULTS_CALCULATED] (state, [ hash, results_calculated ]) { // FIXIT: change hash to SkillfolioID
    console.log(`µ 1 state.completions[${hash}].results_calculated: ${state.completions[hash].results_calculated}`)
    console.log(`µ Committing updating of done test results results_calculated with skillfolio id in vuex store: state.completions[${hash}] and results_calculated: ${results_calculated}`)
    state.completions[hash] = {
      ...state.completions[hash],
      results_calculated: {
        ...state.completions[hash].results_calculated,
        ...results_calculated
      }
    },
    console.log(`µ 2 state.completions[${hash}].results_calculated: ${state.completions[hash].results_calculated}`)
  },
  [UPDATE_COMPLETION_REQUEST_ROOT] (state, [ hash, request_root ]) { // FIXIT: change hash to SkillfolioID
    if (state.completions[hash]) console.log(`µ 1 state.completions[${hash}].request_root: ${state.completions[hash].request_root}`)
    console.log(`µ Committing updating of done test results request_root with skillfolio id in vuex store: state.completions[${hash}] and results_calculated: ${request_root}`)
    state.completions[hash] = {
      ...state.completions[hash],
      request_root: {
        ...(state.completions[hash] ? state.completions[hash].request_root : {}),
        ...request_root
      }
    },
    console.log(`µ 2 state.completions[${hash}].request_root: ${state.completions[hash].request_root}`)
  },
  [RESET_COMPLETIONS] (state, hash) {
    if (hash) {
      console.log(`µ Committing resetting of "${hash}" test completion in vuex store: state.completions[${hash}]`)
      delete state.completions[hash]
    } else {
      console.log(`µ Committing resetting of all test done results in vuex store: state.completions = {null: { results: {}, info: {}, results_calculated: {} }}`)
      state.completions = { null: { results: {}, info: {}, results_calculated: {}, request_root: {}}}
    }
  },
  [ADD_SKILLFOLIO_ID] (state, id) {
    console.log('µ Results id is committing to vuex state.completions.skillfolio_id = ' + id)
    utils.setCookie('skillfolio_id', id)
    state.skillfolio_id = id
  },
  [REMOVE_SKILLFOLIO_ID] (state) {
    console.log('µ Resetting vuex state.skillfolio_id to null')
    utils.deleteCookie('skillfolio_id')
    state.skillfolio_id = null
  },

  [ADD_USER_COMPLETIONS_HASHES] (state, [ project_name, user_id, completions_hashes, email ]) {
    if (!state.user_completions_hashes[project_name])
      Vue.set(state.user_completions_hashes, project_name, {})
    if (!state.user_completions_hashes[project_name][user_id])
      Vue.set(state.user_completions_hashes[project_name], user_id, completions_hashes)
  },
  [ADD_HASH_TO_USER_COMPLETIONS_HASHES] (state, [ project_name, user_id, completion_hash, email ]) {
    if (state.user_completions_hashes[project_name] && state.user_completions_hashes[project_name][user_id])
      // TODO: check existence of completion_hash in user completions_hashes
      state.user_completions_hashes[project_name][user_id].push(completion_hash)
    else console.warn(`Create array in user_completions_hashes for [${project_name}][${user_id}] first!`)
  },
  [CLEAR_USER_COMPLETIONS_HASHES] (state, [ project_name, user_id ]) {
    if (project_name && user_id && state.user_completion_hashes[project_name]) state.user_completions_hashes[project_name][user_id] = []
    else if (project_name && !user_id && state.user_completion_hashes[project_name]) state.user_completions_hashes[project_name] = {}
  },
  [RESET_USER_COMPLETIONS_HASHES] (state) {
    state.user_completions_hashes = {
      common: {
        'test-user-123': [],
      }
    }
  },

  [ADD_SUITABLE_COMPLETION_HASHES_PAIR] (state, pair) {
    console.log('µ MUTATION: ADD_SUITABLE_COMPLETION_HASHES_PAIR')
    state.suitable_completion_hashes[pair[0]] = pair[1]
  },
  [RESET_SUITABLE_COMPLETION_HASHES] (state, hash) {
    if (hash) delete state.suitable_completion_hashes[hash]
    else state.suitable_completion_hashes = {}
  },
}

export const actions = {
  [A.SAVE_COMPLETION] ({ getters, dispatch }, { hash, completion }) {
    console.log(`▲ ACTION (sync): dispatching save_completion with hash: ${hash} and completion: ${completion}`)
    const { skillfolio: test_results, results_calculated, info: test_info, ...request_root } = completion

    // dispatch('results/set_info', test_info, {root: true})

    console.log('test_results:', test_results)
    console.log('results_calculated:', results_calculated)
    console.log('test_info:', test_info)
    console.log('request_root:', request_root)

    // if this is outer testing completion -- instantly downloading suitable
    // inner testing completion
    if (test_results && test_results.mode) {
      // (!) ACHTUNG: next line dispatching action, which can also dispatch this action... Kind'a loop here.
      dispatch('get_suitable_inner_completion', { skillfolio_id: hash, project_name: test_results.mode, info: test_info })
    }

    return dispatch('add_completion', [
      // this action returns Promise interface
      hash,
      test_results,
      results_calculated,
      test_info,
      request_root
    ])
      .then(hash => getters.user_completion(hash))
  },

  [A.GET_SUITABLE_INNER_COMPLETION] (
    { getters, dispatch, rootGetters },
    { skillfolio_id = '', info = null, project_name = '', user_id = '', user_email = '' } = {}
  ) {
    console.log(`
      ▲ ACTION (sync): dispatching get_suitable_inner_completion
      with skillfolio_id: ${skillfolio_id}, info: ${info}, project_name: ${project_name},
      user_id: ${user_id}, user_email: ${user_email}
    `)

    // check suitable completion hash in cash
    if (skillfolio_id) {
      const inner_completion_hash = getters.get_suitable_completion_hash(skillfolio_id)
      if (inner_completion_hash) {
        console.log('Get inner completion hash from cash')
        const localCompletion = getters.user_completion(inner_completion_hash)
        console.log('Using local completion:', localCompletion)
        // (!) ACHTUNG: next line dispatching action, which can also dispatch this action... Kind'a loop here.
        if (!localCompletion) {
          return dispatch('apis/SKILLFOLIO_get_and_save_completion', inner_completion_hash, { root: true })
        } else {
          return Promise.resolve(localCompletion)
        }
      } else console.warn('No suitable inner completion hash')
    } else console.warn('No inner completion hash in cash')

    if (!project_name) {
      console.warn('No project_name given, operation failed')
      return Promise.resolve(false)
    }

    // check if suitable completion may be, get it hash from API, and save it
    if (rootGetters.is_outer_testing) {
      const inner_testing_project_name = project_name.replace(/(-out)$/gi, '')
      if (info) {
        user_id = info.user_id
        user_email = info.email
      }
      console.log('Looking for suitable inner completion...')
      dispatch('show_loader', null, { root: true })
      return dispatch('apis/SKILLFOLIO_get_custom_completion_hash', {
        project_name: inner_testing_project_name,
        user_id,
        user_email,
      }, { root: true })
        .then(hash => {
          console.log(`
            Inner completion hash received for project_name:
            ${inner_testing_project_name},
            user_id: ${user_id},
            user_email: ${user_email}
          `)
          const localCompletion = getters.user_completion(hash)
          console.log('Using local completion:', localCompletion)
          // (!) ACHTUNG: next line dispatching action, which can also dispatch this action... Kind'a loop here.
          if (!localCompletion) {
            // save completion hash to cash
            if (skillfolio_id) {
              dispatch('add_suitable_completion_hashes_pair', [ skillfolio_id, hash ])
            }
            return dispatch('apis/SKILLFOLIO_get_and_save_completion', hash, { root: true })
          } else return Promise.resolve(localCompletion)
        })
        .catch(err => {
          console.warn('Problem while searching suitable inner completion occured.', err)
          throw new Error(err)
        })
        .finally(() => dispatch('hide_loader', null, { root: true }))
    } else {
      console.warn('Not outer testing, no need in suitable completion')
      return Promise.resolve(false)
    }
  },

  [A.ADD_COMPLETION] ({ state, commit, rootState, rootGetters }, [
    // uses results module state & getters,  proxying mutation
    hash,
    // MEMO: default params
    results = rootGetters['results/user_results_extended'].skillfolio,
    results_calculated = rootGetters['results/user_results_calculated'],
    info = rootState.results.u_info,
    request_root = rootState.results.req_root,
  ]) {
    console.log(`▲ ACTION: dispatching add_completion with hash: ${hash}, info: ${info}, results: ${results}, results_calculated: ${results_calculated}, and root: ${request_root}`)
    // MEMO: injecting age_group into results, before Results view even start rendering...
    if (results.mode_sub.age) results.age_group = results.mode_sub.age
    commit('ADD_COMPLETION', [ hash, results, results_calculated, info, request_root ])
    // MEMO: adding new hash to user_completion_hashes
    if (results.mode && info.user_id) {
      const project_completions = state.user_completions_hashes[results.mode]
      const project_user_completions = project_completions ? project_completions[info.user_id] : false
      const existent_completion = project_user_completions ? project_user_completions.find(arr => arr[0] === hash) : false
      if (existent_completion) {
        console.warn('Completion already exists, not updating user completions hashes list')
      } else {
        commit('ADD_HASH_TO_USER_COMPLETIONS_HASHES', [ results.mode, info.user_id, [ hash, request_root._created ]])
      }
    }
    return Promise.resolve(hash)
  },

  [A.REMOVE_COMPLETION] ({ commit }, hash) {
    console.log(`▲ ACTION: dispatching remove_completion with hash: state.completions[${hash}] = { results: {}, info: {}, results_calculated: {} }`)
    commit('REMOVE_COMPLETION', hash)
  },

  [A.REMOVE_USER_COMPLETION] ({ commit, rootState }) {
    console.log(`▲ ACTION: dispatching remove_user_completion with skillfolio id in vuex store: state.completions[${rootState.users.user_id}] = { results: {}, info: {}, results_calculated: {} }`)
    // state.completions[rootState.users.user_id] = { results: {}, info: {}, results_calculated: {} }
    if (rootState.users.user_id === null) {
      console.warn('not clearing skillfolio done results for default user (they are not usable!!!)')
      return
    }
    commit('REMOVE_USER_COMPLETION', rootState.users.user_id)
  },

  [A.UPDATE_COMPLETION_RESULTS_CALCULATED] ({ commit }, [ hash, results_calculated ]) { // proxying mutation
    commit('UPDATE_COMPLETION_RESULTS_CALCULATED', [ hash, results_calculated ])
  },

  [A.UPDATE_COMPLETION_REQUEST_ROOT] ({ commit }, [ hash, request_root ]) { // proxying mutation
    commit('UPDATE_COMPLETION_REQUEST_ROOT', [ hash, request_root ])
  },

  [A.RESET_COMPLETIONS] ({ commit }, hash) {
    commit('RESET_COMPLETIONS', hash)
  },

  [A.ADD_SKILLFOLIO_ID] ({ commit }, id) { // proxying mutation
    commit('ADD_SKILLFOLIO_ID', id)
  },

  [A.REMOVE_SKILLFOLIO_ID] ({ commit }) { // proxying mutation
    commit('REMOVE_SKILLFOLIO_ID')
  },

  [A.ADD_USER_COMPLETIONS_HASHES] ({ commit }, [ project_name, user_id, completions_hashes ]) {
    if (project_name && user_id) commit(ADD_USER_COMPLETIONS_HASHES, [ project_name, user_id, completions_hashes ])
    else console.warn(`Completions hashes for project ${project_name} and user ${user_id} could not be added!`)
  },

  [A.ADD_ACTIVE_USER_COMPLETIONS_HASHES] ({ commit, rootState }, completions_hashes) {
    if (rootState.project_name && rootState.users.user_id)
      commit(ADD_USER_COMPLETIONS_HASHES, [ rootState.project_name, rootState.users.user_id, completions_hashes ])
    else console.warn(`Completions hashes for project ${rootState.project_name} and user ${rootState.users.user_id} could not be added!`)
  },

  [A.ADD_HASH_TO_USER_COMPLETIONS_HASHES] ({ commit }, [ project_name, user_id, completion_hash ]) {
    if (project_name && user_id)
      commit(ADD_HASH_TO_USER_COMPLETIONS_HASHES, [ project_name, user_id, completion_hash ])
    else
      console.warn(`Completions hashes for project ${project_name} and user ${user_id} could not be added!`)
  },

  [A.ADD_HASH_TO_ACTIVE_USER_COMPLETIONS_HASHES] ({ commit, rootState }, completion_hash) {
    if (rootState.project_name && rootState.users.user_id)
      commit(ADD_HASH_TO_USER_COMPLETIONS_HASHES, [ rootState.project_name, rootState.users.user_id, completion_hash ])
    else console.warn(`Completions hash for project ${rootState.project_name} and user ${rootState.users.user_id} could not be added!`)
  },

  [A.CLEAR_USER_COMPLETIONS_HASHES] ({ commit }, [ project_name, user_id ]) {
    if (project_name && user_id)
      commit(CLEAR_USER_COMPLETIONS_HASHES, [ project_name, user_id ])
    else
      console.warn(`Cannot clear hashes for project ${project_name} and user ${user_id}`)
  },

  [A.CLEAR_ACTIVE_USER_COMPLETIONS_HASHES] ({ commit, rootState }) {
    if (rootState.project_name && rootState.users.user_id)
      commit(CLEAR_USER_COMPLETIONS_HASHES, [ rootState.project_name, rootState.users.user_id ])
    else console.warn(`Cannot clear hashes for project ${rootState.project_name} and user ${rootState.users.user_id}`)
  },

  [A.RESET_USER_COMPLETIONS_HASHES] ({ commit }) {
    commit(RESET_USER_COMPLETIONS_HASHES)
  },

  [A.ADD_SUITABLE_COMPLETION_HASHES_PAIR] ({ commit }, pair) {
    commit('ADD_SUITABLE_COMPLETION_HASHES_PAIR', pair)
  },

  [A.RESET_SUITABLE_COMPLETION_HASHES] ({ commit }, hash) {
    commit('RESET_SUITABLE_COMPLETION_HASHES', hash)
  },

  [A.COMPLETE_TESTS] ({ dispatch, rootState }, { skillfolio_id, next_route }) { // used in api module actions, uses results module actions, uses root module state
    dispatch('add_skillfolio_id', skillfolio_id)

    // loading suitable inner completion, if have one
    if (rootState.project_name) {
      dispatch('get_suitable_inner_completion', {
        skillfolio_id,
        project_name: rootState.project_name,
        info: rootState.results.u_info
      })
    }

    // Adds local results to local  completion with skilfolio_id
    dispatch('add_completion', [ skillfolio_id ])
    // uses this default values:
    // hash = skillfolio_id
    // results = rootGetters['results/user_results_extended'].skillfolio,
    // results_calculated = rootGetters['results/user_results_calculated'],
    // info = rootState.results.u_info,
    // request_root = state.req_root,

    if (next_route) {

      const show_old_results = [
        'aa',
        'asi2',
        'region',
        'skillfolio',
        'test'
      ].indexOf(rootState.project_name) > -1

      const show_in_another_window = [
        'aa',
        'asi2',
        'region',
        'skillfolio',
        'test'
      ].indexOf(rootState.project_name) > -1

      const need_confirmation = [

      ].indexOf(rootState.project_name) > -1

      dispatch('show_results', {
        blank: show_in_another_window,
        show_old: show_old_results,
        next_route: next_route
      })

      if (show_in_another_window) {
        dispatch('results/reset_test', { confirmation: need_confirmation }, { root: true }) // was always false here
      } else {
        dispatch('results/reset_all_user_results', null, { root: true })
      }
    }
  },

  [A.SHOW_RESULTS] ({ state }, { blank, show_old, next_route }) {
    if (!show_old) {
      console.log('Showing new results!')
      // const new_results_route = { name: 'results', params: {skillfolio_id: this.skillfolio_id} }
      blank
        ? window.open(router.resolve(next_route).href, '_blank')
        : router.push(next_route)
    } else {
      console.log('Showing old results!')
      blank
        ? window.open(`${config.main_host}/9${state.skillfolio_id}`, '_blank')
        : window.location.href = `${config.main_host}/9${state.skillfolio_id}`
    }
  },

}

