import axios from 'axios'
import store from '@/store/store'
import config from '@/config'
import utils from '@/utils'

const nodeEnv = config.node_env
console.log('nodeEnv:', nodeEnv)
const apiBaseUrl = nodeEnv === 'production' ? config.test_data_api : 'https://test-data-stage.x.skillfolio.ru'
console.log('apiBaseUrl:', apiBaseUrl)
const apiV1 = '/api/v1'
const endpoints = {
  auth: {
    obtainToken: apiBaseUrl + apiV1 + '/auth/token-obtain',
    // USAGE: Endpoint for obtain tokens, auth by username=email
    //    http post localhost/api/v1/auth/token-obtain/ username=<user@email.com> password=<...>
    // RETURNS access token, which should be added to all requests headers:
    //    http localhost/api/v1/hello/ 'Authorization: Token <token>'
    obtainJWT: apiBaseUrl + apiV1 + '/auth/jwt-obtain',
    // USAGE: Endpoint for obtain JWT tokens, auth by email=email :
    //     http post localhost/api/v1/auth/jwt-obtain/ email=<user@email.com> password=<...>
    // RETURNS ACCESS and REFRESH tokens, the ACCESS one should be added to all requests headers:
    //    http localhost/api/v1/hello/ 'Authorization: Bearer <access_token_header.access_token_payload.access_token_signature>'
    // ACCESS token can be used for the next 5 minutes, after that you need to get a new one with REFRESH token
    refreshJWT: apiBaseUrl + apiV1 + '/auth/jwt-refresh',
    // USAGE: Endpoint for refresh JWT access token with refresh token :
    //     http post localhost/api/v1/auth/jwt-refresh/ refresh=<refresh_token_header.refresh_token_payload.refresh_token_signature>
    // RETURNS new ACCESS token, wich expired in another 5 mins
    // REFRESH token is valid for next 24 hours, after its expiration user should perform full auth process from the beginning.
  },
  constructor: {
    projects: apiBaseUrl + apiV1 + '/constructor/projects/',
    projectResults: apiBaseUrl + apiV1 + '/constructor/projects-results-only/',
    completions: apiBaseUrl + apiV1 + '/constructor/completions/',
    checkPromoCode: apiBaseUrl + apiV1 + '/constructor/promo-codes/check-promo/',
    completePromoUser: apiBaseUrl + apiV1 + '/constructor/promo-users/complete-test/',
  }
  // getProjectConfig: apiBaseUrl + apiV1 + '/projects/get-config'
}
const getAuthHeader = () => {
  console.log('getAuthHeader()');
  const apiAuthToken = JSON.parse(localStorage.getItem('api_auth_token'))
  // const apiAuthToken = store.state.users.auth.token
  const apiJWT = JSON.parse(localStorage.getItem('api_jwt'))
  // const apiJWT = store.state.users.auth.jwt
  console.log('apiAuthToken:', apiAuthToken)
  console.log('apiJWT:', apiJWT)
  // MEMO: decided to return only token String, not Object
  return apiAuthToken ||
        ((apiJWT && apiJWT.access)
          ? `Bearer ${apiJWT.access}`
          : ''
        )
  // const authToken = {}
  // // auth with default auth token: for integration clients
  // if (apiAuthToken) authToken['Authorization'] = apiAuthToken
  // // auth with JWT token: for real users
  // else if (apiJWT) authToken['Authorization'] = `Bearer ${apiJWT.access}`
  // return authToken // MEMO: previously returned Object
}
const generateAuthHeaderObj = () => {
  const authHeaderValue = getAuthHeader()
  return authHeaderValue
    ? { 'Authorization': authHeaderValue }
    : {}
}
// const getCsrfTokenHeader = () => {
//   const csrfHeader = {}
//   const csrfToken = utils.getCookie('csrftoken')
//   if (csrfToken) csrfHeader['X-CSRFToken'] = csrfToken
//   return csrfHeader
// }
const apiClient = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  // timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    // 'Authorization': getAuthHeader(),
    ...generateAuthHeaderObj(),
    // ...getCsrfTokenHeader(),
    // 'X-Auth-Token': '',
  }
})


export default {
  // client: apiClient,

  // Info:
  baseUrl: apiBaseUrl,
  apiVersion: 'v1',

  // Utils:
  getAuthHeader,
  generateAuthHeaderObj,
  renewAuthHeaders: () => {
    console.log('renewAuthHeaders()')
    apiClient.defaults.headers.common['Authorization'] = getAuthHeader()
  },
  // getCsrfTokenHeader,

  // Service methods:
  /**
   * Preferable way to authenticate Integration partners, not individual users
   * @param {Object} user_data - integration partner service user object with email & password fields
   * @returns {Promise} - Promise object with auth token string
   */
  obtainAuthToken: user_data => {
    console.log(`Trying to obtain integration service user '${user_data.email}' auth token from Test-data API`)
    return apiClient
      .post(endpoints.auth.obtainToken, {
        username: user_data.email,
        password: user_data.password
      })
      .then(response => {
        console.log('Obtain auth token response was:', response)
        return response.data.token
      })
      // .then(token => {
      //   localStorage.setItem('api_auth_token', token)
      //   return token
      // })
      .catch(err => console.warn('Token issueing service responsed with wrong data:', err))
  },
  /**
   * Preferable way to authenticate individual users
   * @param {Object} user_data - user object with email & password fields
   * @returns {Promise} - Promise object with JWT pair object
   */
  obtainJWT: user_data => {
    console.log(`Trying to obtain user '${user_data.email}' JWT from Test-data API`)
    return apiClient
      .post(endpoints.auth.obtainJWT, {
        email: user_data.email,
        password: user_data.password
      })
      .then(response => {
        console.log('Obtain JWT response was:', response)
        return response.data
      })
      .then(jwt_pair => {
        localStorage.setItem('api_jwt', jwt_pair)
        return jwt_pair
      })
      .catch(err => console.warn('JWT issueing service responsed with wrong data while obtain token:', err))
  },
  /**
   * Refreshing JWT access token
   * @param {string} - user JWT refresh token
   * @returns {Promise} - Promise object with new JWT access token string
   */
  refreshJWT: refresh_token => {
    return apiClient
      .post(endpoints.auth.refreshJWT, { refresh: refresh_token })
      .then(response => {
        console.log('Refresh JWT response was:', response)
        return response.data.access
      })
      .then(jwt_access => {
        localStorage.setItem('api_jwt', { ...localStorage.getItem('api_jwt'), access: jwt_access })
        return jwt_access
      })
      .catch(err => console.warn('JWT issueing service responsed with wrong data while refresh token:', err))
      // TODO: add refresh token expiration case logic
  },
  /**
   * Loading project config from Test-data API
   * @param {string} - some project name (usually it's <project_name>.skillfolio.ru subdomain)
   * @returns {Promise} - Promise object with config object
   */
  getProjectConfig: project_name => {
    console.log(`Trying to load '${project_name}' config from Test-data API`)
    return apiClient
      // .get(endpoints.getProjectConfig, {
      .get(endpoints.constructor.projects + `${project_name}/`)
      // .then(response => {
      //   console.log('test-data constructor response:', response)
      //   return response.data
      // })
      // .then(constructorData => {
      //   // TODO: save data to vuex
      // })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.fallback) {
          console.log('Got project fallback from constructor')
          return { data: err.response.data.fallback }
        } else {
          console.warn('Test-data API getProjectConfig() error was:', err)
        }
      })
  },
  /**
   * Loading project results config from Test-data API
   * @param {string} - some project name (usually it's <project_name>.skillfolio.ru subdomain)
   * @returns {Promise} - Promise object with results config object
   */
  getProjectResultsConfig: project_name => {
    console.log(`Trying to load '${project_name}' results-only config from Test-data API`)
    return apiClient
      // .get(endpoints.getProjectResultsConfig, {
      .get(endpoints.constructor.projectResults + `${project_name}/`)
      // .then(response => {
      //   console.log('test-data constructor response:', response)
      //   return response.data
      // })
      // .then(constructorData => {
      //   // TODO: save data to vuex
      // })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.fallback) {
          console.log('Got project results fallback from constructor')
          return { data: err.response.data.fallback }
        } else {
          console.warn('Test-data API getProjectResultsConfig() error was:', err)
        }
      })
  },
  /**
   * Saving project completion to Test-data API
   * @param {Object} completion_data - object, containing diagnostics completion hash
   *        generated by Skillfolio API, project_name, and optionally - UID of
   *        concrete user and user email
   * @return {Promise} - promise object, with server response or `false`, if error
   */
  saveCompletion: completion_data => {
    return apiClient
      .post(endpoints.constructor.completions, {
        hash: completion_data.hash,
        project: completion_data.project_name,
        uid: completion_data.uid,
        email: completion_data.email,
      })
      .catch(err => {
        console.warn('Test-data API saveCompletion() error was:', err)
        return false
      })
  },
  /**
   * Checking promo code access throught Test-data API
   * @param {Object} access_data - object, containing code, project_name and
   *        optionally - UID of concrete user and user email
   * @return {Promise} - promise object, with server response object containing
   *         authorized, user_created boolean fields, completion_hash String,
   *         token Object with JWT pair
   */
  checkPromoAccess: access_data => {
    return apiClient
      .post(endpoints.constructor.checkPromoCode, {
        code: access_data.promo_code,
        project_mode: access_data.project_name,
        uid: access_data.uid,
        email: access_data.email,
      })
      .catch(err => {
        console.warn('Test-data API checkPromoAccess() error was:', err)
        return { authorized: false, user_created: false, completion_hash: '', token: {}}
      })
  },
  /**
   * Diagnostics completion of promo user, saving to Test-data API
   * @param {Object} user_data - object, containing code, project_name and
   *        optionally - UID of concrete user and user email
   * @return {Promise} - promise object, with server response object containing
   *         completed boolean field
   */
  completePromoUser: user_data => {
    return apiClient
      .post(endpoints.constructor.completePromoUser, {
        code: user_data.code,
        project_mode: user_data.project_mode,
        email: user_data.email,
        completion_hash: user_data.completion_hash
      })
      .catch(err => {
        console.warn('Test-data API completePromoUser() error was:', err)
        return { completed: false }
      })
  },

}
