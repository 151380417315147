console.log('≡≡ local-test-sequences.js loaded! ≡≡');

export default {
  // Route Name Format: name:param | name:param=value | name:param1:param2 | name:param1=value1:param2=value2
  test: [
    // 'user-access',
    // 'combo:type=critical-in-digital',
    // 'visual-soft-skills',
    // 'visual-education-profiles-1',
    // 'visual-disc',
    // 'info',
    'hello',
    'eq-driver-adaptability',
    'eq-driver-selfesteem',
    'eq-drivers:type=adaptability',
    'eq-drivers:type=selfesteem',
    'selfrating', // kid / adult


    // 'birthday',
    // 'anonymous:user_age_group', // kid / adult

    // 'pages',
    // 'subject:category=school:subject=social-science',
    // 'subject:category=school:subject=biology',

    'landolt', // kid / adult
    'bennett', // kid / adult
    'gear', // kid / adult
    'digital', // kid / adult
    'creative', // kid / adult
    'critical', // kid / adult
    'design', // kid / adult
    'naviki',
    'faces', // kid
    'eqtest',
    'interests',

    'meta',
    'anxiety',
    'involvement',
    'child-development',
    'burnout',
    'locus-of-control',
    'ikigai',
    'education-profiles',
    'academic-performance',
    'tiji',

    'instruments',
    'confirm',
    'completed',
  ],
  'all-tests': [
    'info',
    'hello',

    'birthday',
    'anonymous:user_age_group', // kid / adult
    'selfrating', // kid / adult

    'landolt', // kid / adult
    'bennett', // kid / adult
    'gear', // kid / adult
    'digital', // kid / adult
    'creative', // kid / adult
    'critical', // kid / adult
    'design', // kid / adult
    'naviki',
    'faces', // kid
    'eqtest',
    'interests',

    'meta',
    'anxiety',
    'involvement',
    'child-development',
    'burnout',
    'locus-of-control',
    'ikigai',
    'education-profiles',
    'tiji',
    'academic-performance',

    'instruments',
    'confirm',
    'completed',
  ],
  common: [
    'birthday', // kid / adult
    'anonymous:user_age_group', // kid / adult
    'selfrating', // kid / adult
    'landolt', // kid / adult
    'eqtest', // kid / adult
    'faces', // kid
    'gear', // kid / adult
    'bennett', // kid / adult
    'digital', // kid / adult
    'design', // kid / adult
    'creative', // kid / adult
  ],
  a: [
    'selfrating',
    'naviki',
    'faces',
    'landolt',
    'gear',
    'meta',
    'education-profiles'
  ],
  asi: [
    // 'selfrating',
    'faces',
    'gear',
    'landolt',
    'naviki',
    'interests'
  ],
  ad1: [
    'hello',
    'visual-soft-skills',
    'visual-education-profiles-1',
    'visual-disc',
  ],
  ad2: [
    'hello',
    'visual-soft-skills',
    'visual-education-profiles-1',
    'visual-disc',
  ],
  ad3: [
    'hello',
    'visual-soft-skills',
    'visual-education-profiles-1',
    'visual-disc',
  ],
  'leader-1-4': [
    'meta-mult',
  ],
  rosel: [
    'selfrating',
    'naviki',
    'faces',
    'gear',
    'landolt',
    'interests',
  ],
  mcko: [
    'info',
    'selfrating',
    'faces',
    'gear',
    'landolt',
    'naviki',
    'meta',
    'interests',
    'confirm',
    'completed'
  ],
  'mcko-teacher': [
    'info',
    'birthday',
    'anonymous:user_age_group',

    'selfrating',
    'landolt',
    'eqtest',
    'faces',
    'gear',
    'bennett',
    'meta',
    'digital',
    'design',
    'creative',
    'confirm',
    'completed'
  ],
  future: [
    'hello',
    'selfrating',
    'naviki',
    'faces',
    'gear',
    'landolt',
    'interests'
  ],
  lib: [
    'hello',
    'selfrating',
    'faces',
    'gear',
    'landolt',
    'naviki',
    'interests'
  ],
  camp: [
    'hello',
    'naviki',
    'interests'
  ],
  big: [
    'faces',
    'gear',
    'landolt',
    'naviki',
    'interests'
  ],

  ey: [
    'selfrating',
    'gear',
    'landolt',
    'eqtest',
    'naviki',
    'meta'
  ],
  fa: [
    'hello',
    'birthday',
    'selfrating',
    'landolt',
    'eqtest',
    'faces',
    'gear',
    'bennett',
    'digital',
    'design',
    'creative',
    'confirm'
  ],
  '4k': [
    'hello',
    'info',
    'ikigai'
  ],
  ikigai: [
    'ikigai'
  ],
  worldskillz: [
    'ikigai'
  ],

  skillfolio: [
    'birthday', // kid / adult
    'anonymous:user_age_group', // kid / adult
    'selfrating', // kid / adult
    'landolt', // kid / adult
    'eqtest', // kid / adult
    'faces', // kid
    'gear', // kid / adult
    'bennett', // kid / adult
    'digital', // kid / adult
    'design', // kid / adult
    'creative', // kid / adult
  ],
  'caseid-skillfolio': [],
}
