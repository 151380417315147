import config from '@/config'
import axios from 'axios'

const apiBaseUrl = config.test_sequence_api_host
const apiClient = axios.create({
  baseURL: apiBaseUrl,
  // timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
    // 'X-Auth-Token': '',
  }
})


export default {
  baseUrl: apiBaseUrl,
  getProjectConfig: project_name => {
    console.log(`Trying to load '${project_name}' config from test-sequence-api`)
    return apiClient.get('/', {
      params: { t: project_name } // ?t=${project_name}
    })
  }
}
