// root
export const UPDATE_VERSION = 'update_version'
export const SET_PROJECT_NAME = 'set_project_name'
export const SET_JUST_PROJECT_NAME = 'set_just_project_name'
export const RESET_PROJECT = 'reset_project'
export const SHOW_LOADER = 'show_loader'
export const HIDE_LOADER = 'hide_loader'
export const CLEAR_LOADER = 'clear_loader'
export const DEFAULT_STORE_PREPARATION = 'default_store_preparation' // uses tests module state, getters, mutations and actions, users module actions
export const ENABLE_DEV_MODE = 'enable_dev_mode'
export const DISABLE_DEV_MODE = 'disable_dev_mode'
export const TOGGLE_DEV_PANEL = 'toggle_dev_panel'
export const SET_LANGUAGE = 'set_language'
export const ON_IDLE = 'on_idle'
export const ALLOW_MULTIPLE_COMPLETIONS = 'allow_multiple_completions'
export const FORBID_MULTIPLE_COMPLETIONS = 'forbid_multiple_completions'

// projects
export const SET_CURRENT_PROJECT_DATA = 'set_current_project_data'
export const RESET_CURRENT_PROJECT_DATA = 'reset_current_project_data'
export const SET_PROJECT_DATA = 'set_project_data'
export const RESET_PROJECT_DATA = 'reset_project_data'
export const REMOVE_PROJECT_DATA = 'remove_project_data'
export const REMOVE_CURRENT_PROJECT_DATA = 'remove_current_project_data'
// users
export const SET_AUTH_TOKEN = 'set_auth_token'
export const REMOVE_AUTH_TOKEN = 'remove_auth_token'
export const SET_AUTH_JWT = 'set_auth_jwt'
export const REFRESH_AUTH_JWT = 'refresh_auth_jwt'
export const REMOVE_AUTH_JWT = 'remove_auth_jwt'
export const SET_AUTH_USER = 'set_auth_user'
export const REMOVE_AUTH_USER = 'remove_auth_user'
export const AUTHENTICATE_USER = 'authenticate_user'
export const DEAUTHENTICATE_USER = 'deauthenticate_user'

export const SET_USER_ID = 'set_user_id' // uses results module
export const SET_ASI_UID = 'set_asi_uid' // legacy  // uses results module
export const RESET_USER_ID = 'reset_user_id' // uses results module
export const RESET_ASI_UID = 'reset_asi_uid' // legacy  // uses results module
export const SET_USER_AGE = 'set_user_age'
export const RESET_USER_AGE = 'reset_user_age'
export const SET_USER_AGE_GROUP = 'set_user_age_group'
export const RESET_USER_AGE_GROUP = 'reset_user_age_group'
export const SET_SHORT_FIO = 'set_short_fio'
export const REMOVE_SHORT_FIO = 'remove_short_fio'
export const SET_PARALLEL = 'set_parallel'
export const REMOVE_PARALLEL = 'remove_parallel'
export const SET_MOSCOW_STUDENT = 'set_moscow_student'
export const REMOVE_MOSCOW_STUDENT = 'remove_moscow_student'
export const SET_USER = 'set_user' // uses results module
export const RESET_USER = 'reset_user'
export const RESET_USER_DETAILS = 'reset_user_details'

// tests
export const ADD_PROJECT_AGE_GROUP = 'add_project_age_group' // uses root state and action
export const REMOVE_PROJECT_AGE_GROUP = 'remove_project_age_group'
export const REMOVE_PROJECT_AGE_GROUPS = 'remove_project_age_groups'
export const ADD_ACCESSIBLE_PAGES = 'add_accessible_pages'
export const REMOVE_ACCESSIBLE_PAGES = 'remove_accessible_pages'
export const ADD_TESTS_SEQUENCE = 'add_tests_sequence' // uses root state and action
export const REMOVE_TESTS_SEQUENCE = 'remove_tests_sequence'
export const SET_APPENDED_DATA = 'set_appended_data'
export const CHANGE_APPENDED_MODE = 'change_appended_mode'
export const FETCH_TESTS_SEQUENCE = 'fetch_tests_sequence' // uses root mutations, API call, used in root action

// results
export const SAVE_RESULT = 'save_result' // proxying mutation
export const SAVE_RESULTS = 'save_results' // proxying mutation
export const REMOVE_RESULT = 'remove_result' // proxying mutation
export const UPDATE_RESULT = 'update_result' // proxying mutation
export const SET_USER_BIRTHDAY_RESULT = 'set_user_birthday_result' // promise with proxying mutation
export const SET_ANONYMOUS_GENDER_RESULT = 'set_anonymous_gender_result' // promise with proxying mutation
export const SAVE_RESULTS_CALCULATED = 'save_results_calculated' // promise with proxying mutation
export const RESET_RESULTS = 'reset_results' // uses users module mutations & completions module mutation (!)
export const RESET_RESULTS_CALCULATED = 'reset_results_calculated'
export const RESET_ALL_RESULTS = 'reset_all_results' // uses user module mutations & completions module mutation (!)
export const RESET_USER_RESULTS = 'reset_user_results' // uses user module mutation & completions module mutation (!)
export const RESET_USER_RESULTS_CALCULATED = 'reset_user_results_calculated' // proxying mutation
export const RESET_ALL_USER_RESULTS = 'reset_all_user_results'
export const COUNT_RESULTS_CALCULATED = 'count_results_calculated' // uses users module getters
export const REMOVE_LAST_RESULT = 'remove_last_result' // uses tests module getters

export const SAVE_TEMP_RESULTS = 'save_temp_results'
export const UPDATE_TEMP_RESULTS = 'update_temp_results'
export const REMOVE_TEMP_RESULTS = 'remove_temp_results'

export const SAVE_TEMP_RESULTS_ETAG = 'save_temp_results_etag'
export const REMOVE_TEMP_RESULTS_ETAG = 'remove_temp_results_etag'

export const RESET_TEMP_RESULTS = 'reset_temp_results'

export const SET_INFO = 'set_info' // proxying mutation
export const RESET_INFO = 'reset_info' // proxying mutation
export const ADD_TO_INFO = 'add_to_info' // proxying mutation
export const REMOVE_FROM_INFO = 'remove_from_info' // proxying mutation
export const ADD_TO_ROOT = 'add_to_root' // proxying mutation
export const REMOVE_FROM_ROOT = 'remove_from_root' // proxying mutation
export const RESET_TEST = 'reset_test' // uses RESET_ALL_RESULTS actions wich has external dependencies
export const ADD_NEW_USER_RESULTS_OBJ = 'add_new_user_results_obj'
export const OBSERVE_EXISTING_USER_RESULTS_OBJ = 'observe_existing_user_results_obj'
export const ADD_NEW_USER_RAW_RESULTS_OBJ = 'add_new_user_raw_results_obj'
export const OBSERVE_EXISTING_USER_RAW_RESULTS_OBJ = 'observe_existing_user_raw_results_obj'
export const ADD_NEW_USER_RESULTS_CALCULATED_OBJ = 'add_new_user_results_calculated_obj'
export const OBSERVE_EXISTING_USER_RESULTS_CALCULATED_OBJ = 'observe_existing_user_results_calculated_obj'

// completions
export const SAVE_COMPLETION = 'save_completion' // uses root mutations
export const GET_SUITABLE_INNER_COMPLETION = 'get_suitable_inner_completion'
export const ADD_COMPLETION = 'add_completion' // uses results module state & getters
export const REMOVE_COMPLETION = 'remove_completion'
export const REMOVE_USER_COMPLETION = 'remove_user_completion'
export const UPDATE_COMPLETION_RESULTS_CALCULATED = 'update_completion_results_calculated' // proxying mutation
export const UPDATE_COMPLETION_REQUEST_ROOT = 'update_completion_request_root' // proxying mutation
export const RESET_COMPLETIONS = 'reset_completions' // proxying mutation
export const ADD_SKILLFOLIO_ID = 'add_skillfolio_id' // proxying mutation
export const REMOVE_SKILLFOLIO_ID = 'remove_skillfolio_id' // proxying mutation

export const ADD_USER_COMPLETIONS_HASHES = 'add_user_completions_hashes'
export const ADD_ACTIVE_USER_COMPLETIONS_HASHES = 'add_active_user_completions_hashes'
export const ADD_HASH_TO_USER_COMPLETIONS_HASHES = 'add_hash_to_user_completions_hashes'
export const ADD_HASH_TO_ACTIVE_USER_COMPLETIONS_HASHES = 'add_hash_to_active_user_completions_hashes'
export const CLEAR_USER_COMPLETIONS_HASHES = 'clear_user_completions_hashes'
export const RESET_USER_COMPLETIONS_HASHES = 'reset_user_completions_hashes'
export const CLEAR_ACTIVE_USER_COMPLETIONS_HASHES = 'clear_active_user_completions_hashes'

export const ADD_SUITABLE_COMPLETION_HASHES_PAIR = 'add_suitable_completion_hashes_pair'
export const RESET_SUITABLE_COMPLETION_HASHES = 'reset_suitable_completion_hashes'
export const COMPLETE_TESTS = 'complete_tests' // used in api module actions, uses results module actions, uses root module state
export const SHOW_RESULTS = 'show_results'

// api
export const SET_CALLBACK_URL = 'set_callback_url'
export const REMOVE_CALLBACK_URL = 'remove_callback_url'
export const SET_CALLBACK_XHR_REQ = 'set_callback_xhr_req'
export const SET_TOKEN = 'set_token'
export const REMOVE_TOKEN = 'remove_token'
export const RESET_ALL_TOKENS = 'reset_all_tokens'
export const SET_INTEGRATION = 'set_integration'
export const REMOVE_INTEGRATION = 'remove_integration'
export const SET_ACTIVITY_UUID = 'set_activity_uuid'
export const REMOVE_ACTIVITY_UUID = 'remove_activity_uuid'
export const START_UNTI_TESTING = 'start_unti_testing'
export const END_UNTI_TESTING = 'end_unti_testing'
export const SAVE_UNTI_TESTING = 'save_unti_testing'
export const SET_TOKEN_PAIR = 'set_token_pair'
export const REMOVE_TOKEN_PAIR = 'remove_token_pair'
export const SET_RAN_TOKEN = 'set_ran_token'
export const REMOVE_RAN_TOKEN = 'remove_ran_token'
// export const SET_UNTI_TOKEN = 'set_unti_token'
// export const REMOVE_UNTI_TOKEN = 'remove_unti_token'
export const SET_CODE = 'set_code'
export const REMOVE_CODE = 'remove_code'
export const SET_REDIRECT_URL = 'set_redirect_url'
export const REMOVE_REDIRECT_URL = 'remove_redirect_url'
export const RESET_INTEGRATION_PARAMS = 'reset_integration_params'

export const SKILLFOLIO_CHECK_USER_ACCESS = 'SKILLFOLIO_check_user_access' // uses root state and getter
export const SKILLFOLIO_GET_PROFESSIONS_LIST = 'SKILLFOLIO_get_professions_list'
export const SKILLFOLIO_GET_STAT_BY_USER_AND_TEST = 'SKILLFOLIO_get_stat_by_user_and_test'
export const SKILLFOLIO_GET_PROGRAM_BY_EDUCATION_PROFILE = 'SKILLFOLIO_get_program_by_education_profile'
export const SKILLFOLIO_GET_PROGRAM_BY_SOFT_SKILLS = 'SKILLFOLIO_get_program_by_soft_skills'
export const SKILLFOLIO_GET_PROGRAMS_BY_SKILLS_OR_PROFILE = 'SKILLFOLIO_get_programs_by_skills_or_profile'
export const SKILLFOLIO_GET_ALL_PROGRAMS_BY_AGE = 'SKILLFOLIO_get_all_programs_by_age'

export const SKILLFOLIO_GET_TEMP_RESULTS = 'SKILLFOLIO_get_temp_results'
export const SKILLFOLIO_SAVE_TEMP_RESULTS = 'SKILLFOLIO_save_temp_results'
export const SKILLFOLIO_UPDATE_TEMP_RESULTS = 'SKILLFOLIO_update_temp_results'
export const SKILLFOLIO_REWRITE_TEMP_RESULTS = 'SKILLFOLIO_rewrite_temp_results'
export const SKILLFOLIO_DELETE_TEMP_RESULTS = 'SKILLFOLIO_delete_temp_results'

export const RETRIEVE_PROGRESS_FROM_TEMP = 'retrieve_progress_from_temp'

export const INTEGRATION_POST_MESSAGE = 'INTEGRATION_post_message'
export const SAVE_TO_API = 'SAVE_TO_API' // uses root state, & results module actions & users module actions, state, and may use getters
export const SKILLFOLIO_SAVE_RESULT = 'SKILLFOLIO_save_result' // uses results module state and getters, users module state
export const SKILLFOLIO_ON_SUCCESS = 'SKILLFOLIO_on_success' // uses users module state, uses results module getters and actions, uses completions module actions
export const SKILLFOLIO_ON_ERROR = 'SKILLFOLIO_on_error'

// export const SKILLFOLIO_GET_RESULT = 'SKILLFOLIO_get_result'  // uses root mutations
export const SKILLFOLIO_GET_COMPLETION = 'SKILLFOLIO_get_completion' // uses root mutations
export const SKILLFOLIO_GET_AND_SAVE_COMPLETION = 'SKILLFOLIO_get_and_save_completion' // uses completions action

export const SKILLFOLIO_CHECK_EMAIL_IN_DB = 'SKILLFOLIO_check_email_in_db'
export const SKILLFOLIO_CHECK_USER_ID_EXISTENCE = 'SKILLFOLIO_check_user_id_existence' // uses root mutations

// export const SKILLFOLIO_GET_USER_RESULTS = 'SKILLFOLIO_get_user_results'  // uses root state, uses users module state, may use root mutations
export const SKILLFOLIO_GET_USER_COMPLETION_HASHES = 'SKILLFOLIO_get_user_completion_hashes' // uses root state, uses users module state, may use root mutations
// export const SKILLFOLIO_GET_USER_LAST_RESULT = 'SKILLFOLIO_get_user_last_result'  // may use root mutations
export const SKILLFOLIO_GET_USER_LAST_COMPLETION_HASH = 'SKILLFOLIO_get_user_last_completion_hash' // may use root mutations
// export const SKILLFOLIO_GET_ANY_RESULT = 'SKILLFOLIO_get_any_result'
export const SKILLFOLIO_GET_CUSTOM_COMPLETION_HASH = 'SKILLFOLIO_get_custom_completion_hash'

export const SKILLFOLIO_DECRYPT_TRIPLEDES_CODE = 'SKILLFOLIO_decrypt_tripleDes_code' // uses root state

export const CASEID_SAVE_UTM_SOURCE = 'CASEID_save_utm_source' // uses results module getters, uses users module state
export const CASEID_SAVE_ASI_UID = 'CASEID_save_asi_uid' // uses users module state, uses results module getters

export const UPDATE_LAST_TR_CHECK_TIME = 'update_last_tr_check_time'

