<template>
  <button
    class="hamburger"
    :class="[
      type || 'hamburger--arrowalt',
      {'is-active': isActive}
    ]"
    type="button"
    :title="title || 'открыть меню разработчика'"
    aria-label="Menu"
    aria-controls="navigation"
    @click="onToggle"
  >
    <span class="hamburger-box">
      <span class="hamburger-inner" />
    </span>
  </button>
</template>

<script>
export default {
  name: 'hamburger',
  props: {
    'isActive': Boolean,
    'type': String,
    'title': String,
  },
  data () {
    return {
      // isActive: true
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    onToggle () {
      //  this.isActive = !this.isActive
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="less" scoped>
  @import "../variables";

  button.hamburger {
    font: inherit;
    overflow: visible;
    display: inline-block;
    padding: @unit @unitX2 9px;
    margin: 0;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    text-transform: none;
    color: inherit;
    background-color: transparent;

    &:hover,
    &:focus {
      opacity: 0.7;
      background-color: transparent;
    }

    &.is-active {
      // background-color: transparent;
    }

    &.is-active:hover {
      opacity: 0.7;
    }
  }

  .hamburger--thin {
    padding: 9px @unitX2 0;

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      height: 3px;
    }
  }

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: @blue;
  }

  .hamburger--light.is-active .hamburger-inner,
  .hamburger--light.is-active .hamburger-inner::before,
  .hamburger--light.is-active .hamburger-inner::after {
    background-color: @white;
  }

  .hamburger-box {
    width: 40px;
    height: @unitX2;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: @blue;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    .hamburger--light & {
      background-color: @white;
    }
  }

  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }

  /*
   * Arrow Alt
   */
  // @media screen and (min-width: @smartphone-max-width + 1) {
    .hamburger--arrowalt .hamburger-inner::before {
      transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .hamburger--arrowalt .hamburger-inner::after {
      transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .hamburger--arrowalt.is-active .hamburger-inner::before {
      top: 0;
      transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
      transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }

    .hamburger--arrowalt.is-active .hamburger-inner::after {
      bottom: 0;
      transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
      transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }
  // }

  /*
    * Emphatic
    */
  .hamburger--emphatic {
    overflow: hidden;
  }

  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in;
  }

    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
    }

    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
    }

  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
  }

    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -80px;
      top: -80px;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -80px;
      top: -80px;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

  // /*
  //  * Show Slider instead of Arrow Alt on landscape and lower widths
  //  */
  // .responsiveTo(@smartphone-max-width, {
  //   .hamburger--arrowalt .hamburger-inner {
  //     top: 2px;

  //     &::before {
  //       top: 10px;
  //       transition-property: transform, opacity;
  //       transition-timing-function: ease;
  //       transition-duration: 0.15s;
  //     }

  //     &::after {
  //       top: 20px;
  //     }
  //   }

  //   .hamburger--arrowalt.is-active .hamburger-inner {
  //     transform: translate3d(0, 10px, 0) rotate(45deg);

  //     &::before {
  //       transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  //       opacity: 0;
  //     }

  //     &::after {
  //       transform: translate3d(0, -20px, 0) rotate(-90deg);
  //     }
  //   }
  // });

  /*
   * Slider
   */
  .hamburger--slider .hamburger-inner {
    top: 2px;

    &::before {
      top: 10px;
      transition-property: transform, opacity;
      transition-timing-function: ease;
      transition-duration: 0.15s;
    }

    &::after {
      top: 20px;
    }
  }

  .hamburger--slider.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(45deg);

    &::before {
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      opacity: 0;
    }

    &::after {
      transform: translate3d(0, -20px, 0) rotate(-90deg);
    }
  }
</style>
