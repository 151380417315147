console.log('≡≡ local-test-age-groups.js loaded! ≡≡');

export default {
  common: 'all',
  a: 'all',
  asi: 'kid',
  'all-tests': 'all',
  test: 'all',
  rosel: 'all',
  mcko: 'kid',
  'mcko-teacher': 'adult',
  future: 'all',
  lib: 'all',
  camp: 'kid',
  big: 'all',
  ey: 'adult',
  fa: 'all',
  '4k': 'all',
  ikigai: 'all',
  worldskillz: 'all',
  skillfolio: 'all',
  'kids-test': 'adult',
  ad1: 'kid',
  ad2: 'kid',
  ad3: 'kid',
  'leader-1-4': 'kid',
}
